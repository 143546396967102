// src/TestimonialsSlider.js
import React, { useState } from "react";

const testimonials = [
  {
    usericon: "",
    username: "Ajay Pal",
    userdetail:
      "I was very impressed with the tax preparation service provided by HTTaxSolutions. Mine was a complicated return, as a first-time tax filer with my family never being to United States. They correctly filed my Joint return with first year election for my wife as resident and guided me on paper filing my federal and state return.",
  },
  {
    usericon: "",
    username: "Rahul Mehta",
    userdetail:
      "HTTaxSolutions made the tax filing process very smooth for me. They handled all my documents with precision and offered great support throughout the process.",
  },
  // Add more testimonials as needed
];

const TestimonialsSlider = () => {
  const [current, setCurrent] = useState(0);

  const nextTestimonial = () => {
    setCurrent((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrent(
      (prev) => (prev - 1 + testimonials.length) % testimonials.length,
    );
  };

  return (
    <div className="py-8 px-4 bg-gray-50 mt-1">
      <div className="container mx-auto">
        <h2 className="text-2xl font-bold text-center mb-8">
          CLIENT TESTIMONIALS
        </h2>
        <div className="relative flex items-center justify-center">
          <div className="w-[600px] md:w-[450px] lg:w-[600px] bg-white p-6 rounded-lg shadow-md transition-transform duration-500 transform">
            <div className="flex items-center mb-4">
              <div className="w-16 h-16 bg-gray-300 rounded-full overflow-hidden mr-4">
                <img
                  src={
                    testimonials[current].usericon ||
                    "https://via.placeholder.com/150"
                  }
                  alt="User icon"
                  className="w-full h-full object-cover"
                />
              </div>
              <div>
                <h3 className="text-lg font-semibold ml-1">
                  {testimonials[current].username}
                </h3>
              </div>
            </div>
            <p className="text-gray-600">{testimonials[current].userdetail}</p>
            <div className="flex justify-center mt-6 space-x-4">
              <button
                onClick={prevTestimonial}
                className="bg-gray-700 text-white px-4 py-2 rounded-full"
              >
                &#8592;
              </button>
              <button
                onClick={nextTestimonial}
                className="bg-gray-700 text-white px-4 py-2 rounded-full"
              >
                &#8594;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSlider;
