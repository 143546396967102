import React from "react";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ManagerDetails = ({ data }: any) => {
  return (
    <div className="flex flex-col space-y-4 bg-white rounded-lg shadow-md px-6 py-8">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-gray-800">
          {data.displayName}
        </h2>
        <img
          src={data.imageUrl}
          alt="Employee profile picture"
          className="w-20 h-20 rounded-full border border-gray-200 object-cover"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Email:</p>
          <p className="text-lg text-gray-700">{data.email}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">ID:</p>
          <p className="text-lg text-gray-700">{data.employeeId}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Designation:</p>
          <p className="text-lg text-gray-700">{data.designation}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Department:</p>
          <p className="text-lg text-gray-700">{data.department}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Manager Name:</p>
          <p className="text-lg text-gray-700">{data.managerName}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Remaining Leave:</p>
          <p className="text-lg text-gray-700">{data.remainingLeave}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Mobile Number:</p>
          <p className="text-lg text-gray-700">{data.mobileNumber}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Join Date:</p>
          <p className="text-lg text-gray-700">{data.doj.slice(0, 10)}</p>
        </div>
      </div>

    </div>
  );
};

export default ManagerDetails;
