import React, { useState, useEffect } from 'react';
import apiService from 'services/apiService';

export const PricingForm = ({ pricing, onSave }:any) => {
  const [formData, setFormData] = useState({
    features: '',
    individual: '',
    business: '',
    priority: '',
    status: 0,
  });

  useEffect(() => {
    if (pricing) {
      setFormData(pricing);
    }
  }, [pricing]);

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    try {
      if (pricing) {
        const response = await apiService.put(`pricing/${pricing.id}`, formData);
        onSave(response.data);
      } else {
        const response = await apiService.post('pricing', formData);
        onSave(response.data);
      }
    } catch (error) {
      console.error('Error saving pricing:', error);
    }
  };

  return (
    <div className="p-4">
    <form onSubmit={handleSubmit}  className="space-y-4">
      <div className="form-group">
                  <label className="block text-sm font-medium text-gray-700">Features</label>
        <input
        className='mt-1 block w-full border rounded-md p-2'
          type="text"
          name="features"
          value={formData.features}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
                  <label className="block text-sm font-medium text-gray-700">Individual Price</label>
        <input
        className='mt-1 block w-full border rounded-md p-2'
          type="number"
          name="individual"
          value={formData.individual}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
                  <label className="block text-sm font-medium text-gray-700">Business Price</label>
        <input
        className='mt-1 block w-full border rounded-md p-2'
          type="number"
          name="business"
          value={formData.business}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
                  <label className="block text-sm font-medium text-gray-700">Priority</label>
        <input
        className='mt-1 block w-full border rounded-md p-2'
          type="number"
          name="priority"
          value={formData.priority}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
                  <label className="block text-sm font-medium text-gray-700">Status</label>
        <select
          name="status"
            className='mt-1 block w-full border rounded-md p-2'
          value={formData.status}
          onChange={handleChange}
          required
        >
          <option value={0}>Active</option>
          <option value={1}>Deactive</option>
        </select>
      </div>
      <button type="submit"  className="px-4 py-2 bg-blue-600 text-white rounded-md">
        {pricing ? 'Update' : 'Add'} Pricing
      </button>
    </form>
    </div>
  );

}
 
