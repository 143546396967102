import MomentUtil from "./Moment";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const monthNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const monthFullName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dateFormate = (dateval: string | number | Date) => {
  const date = new Date(dateval);
  // const d = date.getDate();
  // const m = monthNames[date.getMonth()];
  // const y = date.getFullYear();
  // return "" + (d <= 9 ? "0" + d : d) + "/" + date.getMonth() + "/" + y;

  return MomentUtil.formatWithLocale(date, "l");
};

export const convert = (originalDateStr: any) => {
  const originalDate = new Date(originalDateStr);
  // Extract year, month, and day
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0"); // January is 0
  const day = originalDate.getDate().toString().padStart(2, "0");

  // Format the date as "yyyy-MM-dd"
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const getYear = () => new Date().getFullYear();

export const getMonthByDate = (val: any) =>
  monthFullName[new Date(val).getMonth()];

// function capitalize(word) {
//   return word.charAt(0).toUpperCase() + word.slice(1);
// }

export const capitalize = (word: string) => {
  if (word && word.length) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase();
  }
  return "";
};

  // Convert last_login_time from timestamp to a human-readable format with month name
  export const formatTimeStamp = (timestamp: number) => {
    // console.log(timestamp,isNaN (timestamp))
    let date=null;
    if (isNaN (timestamp)){
       date=  new Date(timestamp);       
    }else{
     date = new Date(timestamp * 1000); 
    }
      // Extract month, day, and year
      const month = monthNames[date.getUTCMonth()]; // Months are zero-based
      const day = String(date.getUTCDate()).padStart(2, '0');
      const year = date.getUTCFullYear();

      // Extract hours, minutes, and seconds
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      // Determine AM or PM suffix
      const ampm = hours >= 12 ? 'PM' : 'AM';

      // Convert hours from 24-hour to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12; // Handle the midnight hour (0 should be 12)

      // Format hours to be two digits
      const formattedHours = String(hours).padStart(2, '0');

      // Return the formatted date and time
      return `${month}/${day}/${year}, ${formattedHours}:${minutes} ${ampm}`;
  
  };
  export enum UserType {
    Client = 1,
    Operator = 2,
    Admin = 3,
    Manager = 4,
    SuperAdmin = 5,
  }