// Routers.tsx
import AdminLayout from "layouts/AdminLayout";
import DefaultLayout from "layouts/DefaultLayout";
import UploadCsv from "pages/uploadcsv";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "../components/SideBar";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import PrivateRoute from "./PrivateRoute";
import EmployeeList from "pages/client/EmployeeList";
import ErrorBoundary from "./ErrorBoundry";
import Loader from "components/Loader";
import Profile from "pages/profile";
import Home from "pages/default/Home";
import About from "pages/default/About";

import Pricing from "pages/default/Pricing";
import Service from "pages/default/Service";
import HomeTestimonial from "pages/default/HomeTestimonial";
import Register from "pages/default/Signup";
import TaxProfileForm from "pages/taxprofile/TaxProfilePage";
import AdminLogin from "pages/AdminLogin";
import UserProfile from "pages/client/Index";
import UserLayout from "layouts/UserLayout";
import CreateProfile from "pages/client/CreateProfile";
import UpdateTaxProfile from "pages/client/UpdateProfile";
import UploadDocuments from "pages/client/UploadDocuments";
import ManagerList from "pages/manager/ManagerList";
import OperatorList from "pages/operators/OperatorList";
import { UserRole } from "../types/role"; // Import UserRole enum
import UpdateProfile from "pages/operators/UpdateProfile";
import OperatorLogin from "pages/OperatorLogin";
import AssignedUsers from "pages/operators/AssignedUsers";
import EmployeeListUnassigned from "pages/client/EmployeeListUnassigned";
import EmployeeListAssigned from "pages/client/EmployeeListAssigned";
import EnquiryList from "pages/enquiry/EnquiryList";
import TestimonialList from "pages/testimonials/TestimonialList";
import EmailTemplateList from "pages/emailTemplate/EmailTemplateList";
import EmailVerification from "pages/default/EmailVerification";
import VerificationPage from "pages/default/VerificationPage";
import UserListForMail from "pages/client/UsersListForMail";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ResetPassword";
import TaxServices from "pages/default/TaxServices";
import ITINAssistance from "pages/default/ITINAssistance";
import TaxRepresentation from "pages/default/TaxRepresentation";
import AmendmentFiling from "pages/default/AmendmentFiling";
import ForeignAccountsReporting from "pages/default/ForeignAccountsReporting";
import OtherServices from "pages/default/OtherServices";
import LLCService from "pages/default/LLCService";
import CorporateReturns from "pages/default/CorporateReturns";
import AccountingServices from "pages/default/AccountingServices";
import ContactUs from "pages/default/Contactus";
import SessionExpired from "pages/default/SessionExpired";
import NotificationsPage from "pages/notifications/NotificationsPage";
import PricingManagement from "pages/pricing/PricingManagement";

export default function Routers() {
  const userRole = UserRole.Admin; // Replace with logic to get the actual user role

  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Router>
          <Routes>
            <Route element={<DefaultLayout />}>
              <Route index element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/adminlogin" element={<AdminLogin />} />
              <Route path="/operatorlogin" element={<OperatorLogin />} />

              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/services" element={<Service />} />
              <Route path="/individual_services/tax_services" element={<TaxServices />} />
              <Route path="/individual_services/itin_assistance" element={<ITINAssistance />} />
              <Route path="/individual_services/tax_representation"element={<TaxRepresentation/>}/>
              <Route path="/individual_services/amendment_filing"element={<AmendmentFiling/>}/>
              <Route path="/individual_services/foreign_accounts_and_assets_reporting"element={<ForeignAccountsReporting/>}/>
              <Route path="/individual_services/other_services"element={<OtherServices/>}/>  
              <Route path="/corporate_services/limited_liability_company" element={<LLCService/>}/>
              <Route path="/corporate_services/corporate_returns" element={<CorporateReturns/>}/>
              <Route path="/corporate_services/accounting_services" element={<AccountingServices/>}/>            
              <Route path="/testimonials" element={<HomeTestimonial />} />
              <Route path="/signup" element={<Register />} />
              <Route path="/taxprofile" element={<TaxProfileForm />} />
              <Route path="/verify/:verificationCode" element={<EmailVerification />} />
              <Route path="/verified" element={<VerificationPage />} />     
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />    
              <Route path="/session-expired" element={<SessionExpired />} />
     
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/user" element={<PrivateRoute Layout={UserLayout} userRole={UserRole.Client} />}>
              <Route index element={<UserProfile />} />
              <Route path="profile/:profilestatus" element={<UserProfile />} />
              <Route path="updateprofile/:tax_profile_id" element={<UpdateTaxProfile />} />
              <Route path="uploaddocument" element={<UploadDocuments />} />
              <Route path="profile" element={<CreateProfile />} />
              <Route path="notifications" element={<NotificationsPage role={UserRole.Client} />} />
            </Route>
            <Route path="/admin" element={<PrivateRoute Layout={AdminLayout} userRole={UserRole.Admin} />}>
              <Route path="" element={<Profile />} />
              <Route path="profile" element={<Profile />} />
              <Route path="upload" element={<UploadCsv />} />
              <Route path="users" element={<EmployeeList />} />
              <Route path="managers" element={<ManagerList />} />
              <Route path="operators" element={<OperatorList />} />
              <Route path="pricing" element={<PricingManagement />} />
              <Route path="new-users" element={<EmployeeListUnassigned />} />
              <Route path="assigned-users" element={<EmployeeListAssigned />} />
              <Route path="enquiries" element={<EnquiryList/>}/>
              <Route path="testimonials" element={<TestimonialList/>}/>
              <Route path="email_emplates" element={<EmailTemplateList/>}/>
              <Route path="sendemails" element={<UserListForMail/>}/>
              <Route path="*" element={<NotFound />} />
            </Route>

            <Route path="/operators" element={<PrivateRoute Layout={AdminLayout} userRole={UserRole.Operator} />}>
              <Route path="" element={<UpdateProfile />} />
              <Route path="profile" element={<UpdateProfile />} />
              <Route path="assigned-users"  element={ <AssignedUsers />  } />
              {/* <Route path="users" element={<EmployeeList />} /> */}
              <Route path="documents" element={<ManagerList />} />
              <Route path="notifications" element={<NotificationsPage role={UserRole.Operator} />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </ErrorBoundary>
    </Suspense>
  );
}
