import React, { useEffect, useState } from 'react';
import apiService from 'services/apiService';
import { storageService } from 'services/storageService';

interface User {
  id: number;
  first_name: string;
  last_name: string;
  reg_id: number;
}

interface SendNotificationProps {
  userId: any;
  role: 'Client' | 'Operator';
}

const SendNotification: React.FC<SendNotificationProps> = ({ userId, role }) => {
  const [message, setMessage] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [selectedRegId, setSelectedRegId] = useState<number | null>(null);

  // Fetch users when component mounts if role is Operator
  useEffect(() => {
    if (role === 'Operator') {
      const fetchUsers = async () => {
        try {
          const res = await apiService.get('registrations');
          console.log('res.data' ,res.data)
          console.log('userId',userId)
          setUsers([...res.data.filter((dat:any)=>dat.operator_id===userId)]);
        } catch (error) {
          console.error('Failed to fetch users:', error);
        }
      };

      fetchUsers();
    }
  }, [role,userId]);

  const handleSend = async () => {
    try {
      let userdetails:any=storageService.getItem('userdetails')
      await apiService.post('notification/send', {
        reg_id: role === 'Operator' ? selectedRegId : userId,
        sent_by: role === "Operator" ? 0 : 1, // 0=Operator, 1=User
        send_notification: message,
        operator_id: role === 'Operator' ? userId : JSON.parse(userdetails).user.operator_id,
        sent_date: Math.floor(Date.now() / 1000),
      });
      setMessage('');
      setSelectedRegId(null);
      alert('Notification sent!');
    } catch (error) {
      console.error('Failed to send notification:', error);
    }
  };

  return (
    <div className="send-notification-container">
      

      {/* Header Row */}
      <div className="header-row">
        <h3>Send a Notification</h3>
      </div>

      {/* Dropdown and Message Input Row */}
      <div className="input-row">
        {/* Dropdown for selecting user if role is Operator */}
        {role === 'Operator' && (
          <div className="select-container">
            <label htmlFor="userSelect">Select User:</label>
            <select
              id="userSelect"
              value={selectedRegId ?? ''}
              onChange={(e) => setSelectedRegId(Number(e.target.value))}
            >
              <option value="">Select a user</option>
              {users.map((user) => (
                <option key={user.reg_id} value={user.reg_id}>
                  {`${user.first_name} ${user.last_name}`}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Textarea for message */}
        <div className="textarea-container">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter your message here"
          ></textarea>
        </div>

        {/* Send Button */}
        <div className="button-container text-center rounded-lg">
          <button onClick={handleSend} disabled={role === 'Operator' && !selectedRegId}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendNotification;
