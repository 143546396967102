// src/components/EnquiryDetails.tsx
import React from 'react';

export interface Enquiry {
    id: number;
    name: string;
    email: string;
    phone_no: string;
    comment_message: string;
    reg_date: string;
    reg_time: string;
    ip_address: string;
  }
  
interface EnquiryDetailsProps {
  data: Enquiry;
}

const EnquiryDetails: React.FC<EnquiryDetailsProps> = ({ data }) => {
  return (
    <div className="p-4">
      <div className="mb-2">
        <strong>ID:</strong> {data.id}
      </div>
      <div className="mb-2">
        <strong>Name:</strong> {data.name}
      </div>
      <div className="mb-2">
        <strong>Email:</strong> {data.email}
      </div>
      <div className="mb-2">
        <strong>Phone Number:</strong> {data.phone_no}
      </div>
      <div className="mb-2">
        <strong>Comment:</strong>
        <p className="mt-1">{data.comment_message}</p>
      </div>
      <div className="mb-2">
        <strong>Registration Date:</strong> {data.reg_date}
      </div>
      <div className="mb-2">
        <strong>Registration Time:</strong> {data.reg_time}
      </div>
      <div className="mb-2">
        <strong>IP Address:</strong> {data.ip_address}
      </div>
      {/* Add more fields if necessary */}
    </div>
  );
};

export default EnquiryDetails;
