import ThemeSwitcher from "components/ThemeSwitcher";
import React, { useEffect, useState, useRef } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function UserLayout() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [userDetails,setUserDetails]=useState<any>()

   // Close dropdown if clicked outside
   const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(()=>{
    let userData=JSON.parse(localStorage.getItem('userdetails')||"");
    console.log('userdetails ',userData.user)
    setUserDetails(userData.user)
  },[])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);
  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <Link to="/user" className="flex ms-2 md:me-24">
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                  {t("header")}
                </span>
              </Link>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ms-3">
                {/* <ThemeSwitcher /> */}
                <div>
                  <button
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                    aria-expanded={isDropdownOpen}
                    onClick={() => {
                      console.log('User Menu')
                      setIsDropdownOpen(!isDropdownOpen);
                    }}              data-dropdown-toggle="dropdown-user"
                  >
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="w-8 h-8 rounded-full"
                      src="https://admin-asset-management.s3.amazonaws.com/Vijay_a19461a2779545389938e6588c4e8a2c_52_image"
                      alt="user photo"
                    />
                  </button>
                </div>
                {isDropdownOpen && (
                  <div
                    ref={dropdownRef}
                    style={{ position: 'absolute', top: '35px', right: '5px' }}
                  className="z-50  my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                  id="dropdown-user"
                >
                  <div className="px-4 py-3" role="none">
                    <p
                      className="text-sm text-gray-700 dark:text-white"
                      role="none"
                    >
                      {`${userDetails?.first_name} ${userDetails?.last_name}`}
                    </p>
                    <p
                      className="text-sm font-medium text-gray-700 truncate dark:text-gray-300"
                      role="none"
                    >
                      {userDetails?.email}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
        
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem"
                        onClick={() => {
                          window.localStorage.removeItem("accessToken");
                          window.localStorage.removeItem("userdetails");
                          navigate("/");
                        }}
                      >
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>)}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="flex  h-[92vh] bg-gray-50" style={{    marginTop: '56px'}}>
      {/* Menu Section */}
      <div className="w-1/6 bg-white border-r-2 border-gray-200 dark:border-gray-700 p-4">
        {/* Menu items go here */}
        <nav className="space-y-2">     
        <a href="/user" className="block py-2 px-4 rounded hover:bg-gray-100">Home</a>    
          {/* <a href="/user/profile" className="block py-2 px-4 rounded hover:bg-gray-100">Profile</a> */}
          <a href="/user/notifications" className="block py-2 px-4 rounded hover:bg-gray-100">Notifications</a>
          <span  style={{cursor:'pointer'}}  onClick={() => {
                          window.localStorage.removeItem("accessToken");
                          window.localStorage.removeItem("userdetails");
                          navigate("/login");
                        }} className="block py-2 px-4 rounded hover:bg-gray-100">Logout</span>
        </nav>
      </div>

      {/* Main Content Section */}
      <div className="flex-1 p-2">
        <div className="bg-white border-2 border-gray-200 rounded-lg dark:border-gray-700 p-4">
          <Outlet />
        </div>
      </div>
    </div>
    </>
  );
}
