import React from "react";

export default function Service() {
  return (
    <div className="container">
      <h4 className="text-2xl font-bold mb-2 mt-3 text-left">Tax services</h4>
      <p>
        HTTaxSolutions is a dedicated &amp; registered Electronic Return
        Originator (ERO) with the IRS and governed by Circular 230 of IRS Rules.
        We assure 100% accuracy in the tax preparation as all the tax returns
        are prepared only by the qualified professionals; all the tax returns
        are filed after conducting multiple quality reviews and clients review
        and confirmation. HTTaxSolutions provides Individuals, Small Business
        &amp; Corporate Tax Services. HTTaxSolutions provides the Tax
        service/Suggestions throughout the year, An experience Tax Analyst will
        be available to help or suggest the taxpayer on the below querys.
      </p>
      <ul className="list-none lg:list-disc ml-4">
        <li>Applying ITIN</li>
        <li>Estimated Tax Filing</li>
        <li>W-4 Assistance</li>
        <li>Accounting Service</li>
        <li>Services offered with respect to Tax Filing</li>
        <li>Tax planning to reduce the tax liability</li>
        <li>Preparation of Federal and State Tax Returns</li>
        <li>E-filing/Paper Filing</li>
        <li>Filing of Amendment Tax Returns/ Claim for refund</li>
        <li>Passport Certification Services</li>
        <li>Extension of Tax Returns</li>
        <li>Individual Retirement arrangement(IRA)</li>
        <li>Auditing previous year tax return</li>
        <li>Amending the Tax Returns</li>
        <li>Filing FBAR(Foreign Bank Account reporting)</li>
        <li>Filing FATCA(Foreign Account Tax Compliance Act)</li>
      </ul>
      <p>
        <br />
      </p>
    </div>
  );
}
