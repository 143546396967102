// AdminLayout.tsx
import React, { useEffect } from "react";
import Sidebar from "components/SideBar";
import { UserRole } from "../types/role"; // Ensure this import is correct
import { useNavigate } from "react-router-dom";
import { storageService } from "services/storageService";
import apiService from "services/apiService";
import { UserType } from "util/Util";

interface AdminLayoutProps {
  userRole: UserRole; // Accept userRole prop
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ userRole }) => {
  const navigate = useNavigate();
  const checkUserLoggedIn = () => {
    const token = storageService.getItem("accessToken");
    if (token) {
      apiService
      .get("auth/validate-token")
      .then((res:any) => {
        console.log("check Admin LoggedIn res :>> ", res);
        if(res?.data?.user_type===UserType.Admin){
          navigate("/admin");
        }else  if(res?.data?.user_type===UserType.Operator){
          navigate("/operators");
        } else {
          let userData=JSON.parse(storageService.getItem('userdetails')||"");
              if(userData){
                navigate(parseInt(userData.user.user_type)===UserType.Admin?"/adminlogin":parseInt(userData.user.user_type)===UserType.Operator?"/operatorlogin":"/login")
              }
          storageService.removeItem("accessToken");
          storageService.removeItem("userdetails");          
         
        }
      })
      .catch((err) => {
        // console.log("err", err.response.status);
        if(err?.response?.status===401){
          navigate("/login");
        }
      })
     
    } 
  };

  useEffect(() => {
    checkUserLoggedIn();
  }, []);
  return (
    <>
      <Sidebar role={userRole} />
      {/* Other layout content goes here */}
    </>
  );
};

export default AdminLayout;
