import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import Input from "components/Input";
import apiService from "services/apiService"; // Assume apiService is an axios instance

interface FormData {
  first_name: string;
  middle_name?: string;
  last_name: string;
  email: string;
  password: string;
  phone_no: string;
  image?: File;
  wife_name?: string;
  no_of_children?: number;
  mathCaptchaAnswer: string;
}

// Regex patterns
const nameRegex = /^[a-zA-Z\s'-]{1,35}$/;
const phoneRegex = /^(\+?1[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?(\d{3}[-.\s]?\d{4})$|^(\+?91[-.\s]?)?([6-9]\d{9})$/;
const strongPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const validationSchema = yup.object({
  first_name: yup.string() .min(3, "First name must be at least 3 characters long").matches(nameRegex, "Invalid name format").required("First name is required"),
  // middle_name: yup.string().matches(nameRegex, "Invalid name format"),
  last_name: yup.string().matches(nameRegex, "Invalid name format").required("Last name is required"),
  email: yup.string().matches(emailRegex,"Invalid email address").required("Email is required"),
  password: yup
    .string()
    .matches(strongPasswordRegex, "Password must be at least 8 characters, include a number, a letter, and a special character")
    .required("Password is required"),
    phone_no: yup
    .string()
    .matches(phoneRegex, "Invalid phone number for US/India format")
    .required("Phone number is required"),  wife_name: yup.string().matches(nameRegex, "Invalid name format"),
  no_of_children: yup.number().integer().positive(),
  mathCaptchaAnswer: yup.string().required("Math CAPTCHA is required"),
});

export default function Register() {
  const navigate = useNavigate();
  const methods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  const [num1] = useState(Math.floor(Math.random() * 10));
  const [num2] = useState(Math.floor(Math.random() * 10));
  const [correctAnswer] = useState(num1 + num2);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (data: FormData) => {
    setErrorMessage(null);  // Reset error message before submission
    if (parseInt(data.mathCaptchaAnswer) !== correctAnswer) {
      methods.setError("mathCaptchaAnswer", {
        type: "manual",
        message: "Incorrect answer to the math problem",
      });
      return;
    }

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, (data as any)[key]);
    });

    setIsSubmitting(true);

    apiService
      .post("registrations", data)
      .then((res) => {
        setSuccessMessage(
          "Registration Success! The activation link for your registration has been successfully sent to your email address. Please verify it, otherwise after 24 hours, your account will be removed if inactive. Thank you."
        );
      })
      .catch((error) => {
        console.error("Register error:", error?.response);
        setErrorMessage(
          error?.response?.data?.error 
            ? `${error.response.data.error} Please try again.` 
            : 'Error during registration. Please try again.'
        );
       })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 min-h-screen flex items-center justify-center">
      <div className="max-w-xl w-full bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6">
          {successMessage ? (
            <div className="text-center">
              <h2 className="flex flex-col items-center justify-center text-xl leading-tight tracking-tight text-green-600 md:text-2xl dark:text-green-400">
                <span className="font-bold text-green-500">Registration Successful!</span>
                <span className="text-lg text-left my-4 text-green-600">
                  The activation link has been sent to your email address. Please verify your account within 24 hours. If not verified, your inactive account will be removed.
                </span>
                <span className="font-bold text-green-500">Thank you.</span>
              </h2>
            </div>
          ) : (
            <>
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Register your account
              </h1>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4 md:space-y-6">
                  <Input type="text" name="first_name" label="First Name" placeholder="First Name" />
                  <Input type="text" name="middle_name" label="Middle Name" placeholder="Middle Name" />
                  <Input type="text" name="last_name" label="Last Name" placeholder="Last Name" />
                  <Input name="email" label="Email" type="email" placeholder="example@example.com" />
                  <Input name="password" label="Password" type="password" placeholder="******" />
                  <Input type="text" name="phone_no" label="Phone Number" placeholder="123-456-7890" />
                  <div className="flex items-center space-x-2">
                    <label className="text-gray-700">What is {num1} + {num2}?</label>
                    <Input label="I am not a robot" type="text" name="mathCaptchaAnswer" placeholder="Your Answer" />
                  </div>
                  {errorMessage && (
                    <p className="text-red-500 text-sm">{errorMessage}</p>
                  )}
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Sending..." : "Register"}
                  </Button>
                </form>
              </FormProvider>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
