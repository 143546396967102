import React, { createContext, useMemo, useState } from "react";

interface AppContextProps {
  isLoggedIn: boolean;
  login: () => void;
  logout: () => void;
}
export const AppContextProvider = createContext({} as AppContextProps);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppContext = ({ children }: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsLoggedIn(false);
  };

  const appContextValue = useMemo(
    () => ({
      login,
      logout,
      isLoggedIn,
    }),
    [isLoggedIn]
  );
  return <AppContextProvider.Provider value={appContextValue}>{children}</AppContextProvider.Provider>;
};
