import React, { useEffect, useState } from "react";
import DataTable, { Row } from "components/DataTable";
import apiService from "services/apiService";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa"; // Import the trash icon
import Modal from "components/Modal";
import EmployeeDetails from "./EmployeeDetails";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import DeletePopup from "components/DeletePopup/deletePopup";

export default function EmployeeListAssigned() {
  const [data, setData] = useState<Row[]>([]);
  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [viewData, setViewData] = useState();
  const [addEmployee, setAddEmployee] = useState(false);
  const [editEmployee, setEditEmployee] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null); // State for selected row
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    apiService
      .get("registrations")
      .then((res) => {
        console.log("registrations res :>> ", res);
        setData(res.data.filter((user:any) => user.operator_id!==0));
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });

    apiService
      .get("operators")
      .then((res) => {
        setOperators(res.data);
      })
      .catch((err) => {
        console.error("Failed to fetch operators:", err);
      });
  }, []);

  const handleDelete = async (row: any) => {
    try {
      await apiService.delete(`registrations/${row.reg_id}`); // Adjust the endpoint
      setData((prevData) => prevData.filter((item) => item.reg_id !== row.reg_id)); // Update state after deletion
      setOpenDeletePopup(false); // Close the popup
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };

  const handleOperatorChange = async (
    row: any,
    operator_id: string,
    data: any
  ) => {
    try {
      const url = `registrations/${data.reg_id}/operator/${operator_id}`; // Include operator_id in the URL
      console.log("Update Operator URL:", url,data);
      await apiService.put(url); // No need to send operator_id in the body
      setData((prevData) =>
        prevData.map((item) =>
          item.reg_id === data.reg_id ? { ...item, operator_id } : item
        )
      );
    } catch (error) {
      console.error("Error updating operator:", error);
    }
  };

  const tableColumns = [
    { key: "id", label: `Id`, bold: true, style: { width: '80px' } },
    { key: "first_name", label: `${t("employees.name")}`, bold: true },
    { key: "email", label: `${t("employees.email")}` },
    {
      key: "phone_no",
      label: `Contact`,
      bold: true,
    },
    {
      key: "operator_id",
      label: `Assigned Operator`,
      render: (row: any, data: any) => (
        <select
          defaultValue={row + '' || "Unassigned"}
          onChange={(e) => handleOperatorChange(row, e.target.value, data)}
        >
          <option value="Unassigned">Unassigned</option>
          {operators.map((operator) => (
            <option key={operator.operator_id} value={operator.operator_id}>
              {operator.operator_name}
            </option>
          ))}
        </select>
      ),
    },
    {
      key: "status",
      label: `Status`,
      render: (row: any) => {
        const isActive = row === 0; // Adjust this according to your data structure
        return (
          <div className="flex justify-center items-center h-full">
            <span
              className={`inline-block rounded-full w-4 h-4 ${isActive ? "bg-green-500" : "bg-red-500"}`}
            ></span>
          </div>
        );
      },
      style: { width: '80px' }
    },
    {
      key: "actions",
      label: `${t("employees.actions")}`,
      excludeExport: true,
      actions: (row: object) => (
        <div className="flex flex-start">
          {/* <span
            onClick={() => handleEdit(row)}
            className="btn-primary px-2 py-2 mr-2 rounded-lg"
          >
            <FaEdit className="text-white" />
          </span> */}
          <span
            onClick={() => handleView(row)}
            className="btn-primary px-2 py-2 mr-2 rounded-lg"
          >
            <FaEye className="text-white" />
          </span>
          <span
            onClick={() =>handleDeleteData(row) }
            className="btn-primary px-2 py-2 rounded-lg"
          >
            <FaTrash className="text-white" />
          </span>
        </div>
      ),
    },
  ];

  const handleView = (row: any): void => {
    setViewData(row);
    setOpenModal(true);
  };

  const handleEdit = (row: any): void => {
    setViewData(row);
    setEditEmployee(true);
  };
  const handleDeleteData = (row: any): void => {
    console.log('Delete Row : ',row)
    setSelectedRow(row);
    setOpenDeletePopup(true);
  };
  const handleAddfunction = () => {
    setAddEmployee(!addEmployee);
  };

  const BreadCrumbList = [
    { name: "Users", link: "#" },
    { name: "list", link: "#" },
  ];

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <DataTable
        data={data}
        columns={tableColumns}
        loading={loading}
        onAddBtnClick={handleAddfunction}
      />
      {openModal && (
        <Modal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          header="View Employee Details"
        >
          {viewData && <EmployeeDetails data={viewData} />}
        </Modal>
      )}
      {addEmployee && (
        <Modal
          isOpen={addEmployee}
          onClose={() => setAddEmployee(false)}
          header="Add Employee"
        >
          <AddEmployee onClose={() => setAddEmployee(false)} />
        </Modal>
      )}
      {editEmployee && (
        <Modal
          isOpen={editEmployee}
          onClose={() => setEditEmployee(false)}
          header="Edit Employee"
        >
          <EditEmployee onClose={() => setEditEmployee(false)} data={viewData} />
        </Modal>
      )}
      {openDeletePopup && (
        <Modal
          isOpen={openDeletePopup}
          onClose={() => setOpenDeletePopup(false)}
          header="Delete Employee"
        >
          <DeletePopup
            handleDelete={handleDelete}
            onClose={() => setOpenDeletePopup(false)}
            selectedRow={selectedRow}
          />
        </Modal>
      )}
    </>
  );
}
