import React, { useEffect, useState } from "react";
import apiService from "../../services/apiService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DocumentModal from "./DocumentModal"; // Assuming this component is for document viewing
import DisplayTaxProfile from "./DisplayTaxProfile"; // Component for displaying detailed tax profile
import { useNavigate } from "react-router-dom";

interface User {
  reg_id: number;
  email: string;
  full_name: string;
  phone_no: string;
  document_status: string;
  status: string;
  user_status: string;
  tax_profile_id:number;
}

const UserStatus: any = {
  0: "Pending",
  1: "SubmittedForReview",
  2: "ManagerReview",
  3: "Completed",
  4: "PaymentComplete",
};

const AssignedUsers: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]); // State for filtered users
  const [selectedStatus, setSelectedStatus] = useState<string>("ALL"); // State for selected filter
  const [selectedUserRegId, setSelectedUserRegId] = useState<number | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [editStatusIndex, setEditStatusIndex] = useState<number | null>(null);
  const [updatedStatus, setUpdatedStatus] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAssignedUsers = async () => {
      try {
        const response = await apiService.get("operators/assigned-users");
        setUsers(response.data);
        setFilteredUsers(response.data); // Set initial filtered users
        console.log("User : ", response.data);
      } catch (error) {
        console.error("Failed to fetch assigned users:", error);
      }
    };
    fetchAssignedUsers();
  }, []);

  // Update filtered users when the selected status changes
  useEffect(() => {
    if (selectedStatus === "ALL") {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        users.filter((user) => user.user_status+"" === selectedStatus)
      );
    }
  }, [selectedStatus, users]);

  const handleStatusChange = async (userId: number, newStatus: string) => {
    try {
      await apiService.put(
        `/registrations/update-status/${userId}/${newStatus}`
      );
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.reg_id === userId ? { ...user, user_status: newStatus } : user
        )
      );
      toast.success("Status updated successfully");
    } catch (err: any) {
      if (err.response?.status === 401) {
        navigate("/session-expired");
      } else {
        toast.error("Failed to update status");
      }
    }
  };

  const handleViewDocuments = (reg_id: number) => {
    setSelectedUserRegId(reg_id);
    setIsModalOpen(true);
  };

  const handleViewProfile = (reg_id: number) => {
    console.log("Profile ID : ", reg_id);
    setSelectedUserRegId(reg_id);
    setIsProfileModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUserRegId(null);
    setIsModalOpen(false);
    setIsProfileModalOpen(false);
  };

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Assigned Users</h1>
        <div className="my-4">
          <label htmlFor="filterbystatus">Filter By User Status</label>
          <select
            id="filterbystatus"
            className="ml-4"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)} // Update filter value
          >
            <option value="ALL">All</option>
            <option value="0">Pending</option>
            <option value="1">SubmittedForReview</option>
            <option value="2">ManagerReview</option>
            <option value="3">Completed</option>
            <option value="4">PaymentComplete</option>
          </select>
        </div>
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Reg ID</th>
              <th className="py-2 px-4 border-b">Email</th>
              <th>Phone No.</th>
              <th className="py-2 px-4 border-b">Full Name</th>
              <th className="py-2 px-4 border-b">User Status</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.reg_id}>
                <td className="py-2 px-4 border-b">{user.reg_id}</td>
                <td className="py-2 px-4 border-b">{user.email}</td>
                <td className="py-2 px-4 border-b">{user.phone_no}</td>
                <td className="py-2 px-4 border-b">{user.full_name}</td>
                <td className="py-2 px-4 border-b">
                  {editStatusIndex === user.reg_id ? (
                    <select
                      value={updatedStatus}
                      onBlur={() => setEditStatusIndex(null)}
                      onChange={(e) => {
                        const newStatus = e.target.value;
                        setUpdatedStatus(newStatus);
                        handleStatusChange(user.reg_id, newStatus);
                        setEditStatusIndex(null);
                      }}
                    >
                      <option value="0">Pending</option>                     
                      <option value="2">ManagerReview</option>                      
                    </select>
                  ) : (
                    <span
                      onClick={() => {
                        setEditStatusIndex(user.reg_id);
                        setUpdatedStatus(user.user_status);
                      }}
                    >
                      {UserStatus[user.user_status]}
                    </span>
                  )}
                </td>
                <td className="py-2 px-4 border-b">
                  <button
                    className="bg-blue-500 text-white px-3 py-1 rounded mr-2"
                    onClick={() => handleViewDocuments(user.reg_id)}
                  >
                    Documents
                  </button>
                  <button
                    className="bg-blue-500 text-white px-3 py-1 rounded"
                    onClick={() => handleViewProfile(user.tax_profile_id)}
                  >
                    Profile
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Document Modal */}
        {isModalOpen && selectedUserRegId && (
          <DocumentModal regId={selectedUserRegId} onClose={closeModal} />
        )}

        {/* Tax Profile Modal */}
        {isProfileModalOpen && (
          <DisplayTaxProfile regId={selectedUserRegId} onClose={closeModal} />
        )}
      </div>
    </>
  );
};

export default AssignedUsers;

