/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosResponse } from "axios";
import appConfig from "./apiConfig";

class ApiService {
  private axiosClient: AxiosInstance;

  constructor() {
    this.axiosClient = axios.create({
      baseURL: appConfig.getBaseUrl(),
    });

    // Add an interceptor to add the authorization token to each request
    this.axiosClient.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("accessToken");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  // GET request
  public async get(url: string): Promise<AxiosResponse> {
    console.log("url :>> ", url);
    const response = await this.axiosClient.get(url);
    return response;
  }

  // POST request
  public async post<T>(
    url: string, 
    data?: unknown, 
    config?: Record<string, unknown> // Add config parameter here
  ): Promise<AxiosResponse<T>> {
    return await this.axiosClient.post<T>(url, data, config); // Pass config to axios post
  }
  
  // PUT request handling normal data and FormData
  public async put<T>(
    url: string, 
    data?: unknown, 
    config?: Record<string, unknown> // Add config parameter here
  ): Promise<AxiosResponse> {
    return await this.axiosClient.put<T>(url, data, config); // Pass config to axios post
  }

  private convertToFormData(data: any, parentKey = ''): FormData {
    const formData = new FormData();
  
    const appendFormData = (formData: FormData, key: string, value: any) => {
      if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else if (typeof value === 'object' && !(value instanceof File)) {
        // Recursively handle nested objects or arrays
        Object.keys(value).forEach((subKey) => {
          const newKey = parentKey ? `${parentKey}[${key}][${subKey}]` : `${key}[${subKey}]`;
          appendFormData(formData, newKey, value[subKey]);
        });
      } else {
        formData.append(key, value);
      }
    };
  
    Object.keys(data).forEach((key) => {
      appendFormData(formData, key, data[key]);
    });
  
    // Debugging: View the FormData contents
    Array.from(formData.entries()).forEach(([key, value]) => {
      console.log('FormData Entry - Key:', key, 'Value:', value);
    });
  
    return formData;
  }
  

  // PATCH request
  public async patch(url: string, data: any): Promise<AxiosResponse> {
    const response = await this.axiosClient.patch(url, data);
    return response;
  }

  // DELETE request
  public async delete(url: string, data?: any): Promise<AxiosResponse> {
    const response = await this.axiosClient.delete(url, { data });
    return response;
  }
}

const apiService = new ApiService();

export default apiService;
