// src/components/TestimonialList.tsx
import { BreadCrumb } from 'components/BreadCrumb';
import DataTable, { Row } from 'components/DataTable';
import React, { useEffect, useState } from 'react';
import { FaEye, FaTrash, FaEdit } from 'react-icons/fa';
import Modal from "components/Modal"; // Ensure Modal component is available
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TestimonialDetails from './TestimonialDetails';
import apiService from 'services/apiService';
import DeletePopup from 'components/DeletePopup/deletePopup';
import { useNavigate } from 'react-router-dom';
// src/types/Testimonial.ts
export interface Testimonial {
    id: number;
    name: string;
    designation: string;
    content: string;
    image: string;
    is_status: boolean;
    is_delete: boolean;
    add_date: string;
  }
  
const TestimonialList: React.FC = () => {
  const [data, setData] = useState<Row[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [viewData, setViewData] = useState<Testimonial | null>(null);
  const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Testimonial | null>(null);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const navigate = useNavigate(); 

  const BreadCrumbList = [
    { name: 'Testimonials', link: '/testimonials' },
    { name: 'List', link: '/testimonials' },
  ];

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    setLoading(true);
    try {
      const res = await apiService.get('/admin/testimonials');
      setData(res.data);
    } catch (err:any) {
      if(err.response?.status===401){
        navigate("/session-expired");
      }
      toast.error('Failed to fetch testimonials.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!selectedRow) return;

    try {
      await apiService.delete(`/admin/testimonials/${selectedRow.id}`);
      setData((prevData) => prevData.filter((item) => item.id !== selectedRow.id));
      setOpenDeletePopup(false);
      toast.success('Testimonial deleted successfully.');
    } catch (error) {
      console.error('Error deleting testimonial:', error);
      toast.error('Failed to delete testimonial.');
    }
  };

  const handleView = (row: Testimonial) => {
    setViewData(row);
    setOpenModal(true);
  };

  const handleDeleteData = (row: Testimonial) => {
    setSelectedRow(row);
    setOpenDeletePopup(true);
  };

  const handleEditData = (row: Testimonial) => {
    setViewData(row);
    setOpenEditModal(true);
  };

//   const handleUpdate = (updatedTestimonial: Testimonial) => {
//     setData((prevData) =>
//       prevData.map((item) => (item.id === updatedTestimonial.id ? updatedTestimonial : item))
//     );
//     toast.success('Testimonial updated successfully.');
//   };

  const tableColumns = [
    { key: 'name', label: 'Name', bold: true },
    { key: 'designation', label: 'Designation', bold: true },
    { key: 'content', label: 'Content' },
    {
      key: 'is_status',
      label: 'Status',
      render: (value: any) => (
        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${value ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
          {value ? 'Active' : 'Inactive'}
        </span>
      ),
    },   
    {
        key: "actions",
        label: "Actions",
        excludeExport: true,
        actions: (row:any) => (
          <div className="flex space-x-2">
            <button
              onClick={() => handleView(row)}
              className="text-blue-500 hover:text-blue-700"
              title="View Details"
            >
              <FaEye />
            </button>
            <button
              onClick={() => handleDeleteData(row)}
              className="text-red-500 hover:text-red-700"
              title="Delete Enquiry"
            >
              <FaTrash />
            </button>
          </div>
        ),
      },
  ];

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Testimonials List</h1>
        <DataTable
          data={data}
          columns={tableColumns}
          loading={loading}
          // If you have an add button, you can include it here
        />
      </div>

      {/* View Testimonial Modal */}
      {openModal && viewData && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} header="Testimonial Details">
          <TestimonialDetails data={viewData} />
        </Modal>
      )}


      {/* {openEditModal && viewData && (
        <Modal isOpen={openEditModal} onClose={() => setOpenEditModal(false)} header="Edit Testimonial">
          <EditTestimonial data={viewData} onClose={() => setOpenEditModal(false)} onUpdate={handleUpdate} />
        </Modal>
      )} */}

      {/* Delete Confirmation Popup */}
      {openDeletePopup && selectedRow && (
        <Modal isOpen={openDeletePopup} onClose={() => setOpenDeletePopup(false)} header="Confirm Deletion">
          <DeletePopup
            handleDelete={handleDelete}
            onClose={() => setOpenDeletePopup(false)}
            selectedRow={selectedRow}         
          />
        </Modal>
      )}

      {/* Toast Container for Notifications */}
      <ToastContainer />
    </>
  );
};

export default TestimonialList;
