import React from "react";

export default function About() {
  return (
    <div className="container">
      <h4 className="text-2xl font-bold mb-2 mt-3 text-left">What We Are</h4>
      <p>
        HTTaxSolutions is a team of experienced professionals dedicated to make
        your tax filing process simple, safe and secure. Our procedures are
        designed to make Tax Return preparation easy and painless while
        providing most economical services to our clients. HTTaxSolutions online
        process takes away the time and cost of visiting tax firms to file your
        returns. We are committed to providing close, personal attention to our
        clients. While we handle all tax related issues, individual,
        self-employed individuals, sole propritary business and
        resident/non-resident alien tax preparations are our specialty.
      </p>
      <h4 className="text-2xl font-bold mb-2 mt-3 text-left">
        Quality Assurance
      </h4>
      <p>
        During execution of our services, we stringently adhere to industry set
        norms and regulations. We offer services in a prompt and efficient
        manner. We ensure that the services offered are as per the latest legal
        codes and norms. We GAURENTEE accuracy of tax retruns prepared.&nbsp;We
        provide FREE Audit Support and Representation to the IRS for all tax
        returns prepared by us.
      </p>
      <h4 className="text-2xl font-bold mb-2 mt-3 text-left">
        Client Satisfaction
      </h4>
      <p>
        With the support and assistance of a trained crew of team members, we
        have been able to meet the clients requirements and offer them cost
        effective solutions HTTaxSolutions adheres to highest standards of
        ethical policies. Our professionals maintain transparency and
        objectivity while every step along the way of providing services.
      </p>
      <h4 className="text-2xl font-bold mb-2 mt-3 text-left">Security</h4>
      <p>
        HTTaxSolutions offers services in a highly secured manner. We designed
        and implemented critical protocols in ensuring safety and security of
        confidential client information.
      </p>
    </div>
  );
}
