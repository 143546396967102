// src/components/TestimonialDetails.tsx
import React from 'react';

export interface Testimonial {
    id: number;
    name: string;
    designation: string;
    content: string;
    image: string;
    is_status: boolean;
    is_delete: boolean;
    add_date: string;
  }
  
interface TestimonialDetailsProps {
  data: Testimonial;
}

const TestimonialDetails: React.FC<TestimonialDetailsProps> = ({ data }) => {
  return (
    <div className="p-4">
      <div className="mb-4 flex flex-col items-center">
        <img
          src={`${process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000'}/images/testimonials/${data.image}`}
          alt={data.name}
          className="w-24 h-24 rounded-full object-cover mb-2"
        />
        <h3 className="text-xl font-semibold">{data.name}</h3>
        <p className="text-gray-600">{data.designation}</p>
      </div>
      <div className="mb-2">
        <strong>Content:</strong>
        <p className="mt-1">{data.content}</p>
      </div>
      <div className="mb-2">
        <strong>Status:</strong> {data.is_status ? 'Active' : 'Inactive'}
      </div>
      <div className="mb-2">
        <strong>Added Date:</strong> {new Date(data.add_date).toLocaleDateString()}
      </div>
    </div>
  );
};

export default TestimonialDetails;
