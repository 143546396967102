class AppConfig {
  constructor() {
    console.log("App config ENV Value :  --->",process.env.NODE_ENV);
  }

  getBaseUrl(): string {
    const baseUrl = process.env.NODE_ENV === 'production' 
      ? process.env.REACT_APP_BASE_URL_PROD || "https://www.httaxsolutions.com/api"
      : process.env.REACT_APP_BASE_URL_DEV || "http://localhost:3000/api";
      console.log("App config --->",baseUrl);
    return baseUrl;
  }

  getLocalURL(): string {
    return process.env.NODE_ENV === 'production' 
      ? process.env.REACT_APP_BASE_URL_PROD || "https://www.httaxsolutions.com/api"
      : process.env.REACT_APP_BASE_URL_DEV || "http://localhost:3000/api";
  }

  googleLogin(): string {
    return `${this.getBaseUrl()}oauth2/authorization/google?redirect_uri=${this.getLocalURL()}`;
  }
}

const appConfig = new AppConfig();

export default appConfig;
