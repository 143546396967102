import React, { useEffect, useState } from "react";
import DataTable, { Row } from "components/DataTable";
import apiService from "services/apiService";
import { FaDochub, FaEdit, FaEye, FaTrash, FaUser } from "react-icons/fa"; // Import the trash icon
import Modal from "components/Modal";
import EmployeeDetails from "./EmployeeDetails";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import DeletePopup from "components/DeletePopup/deletePopup";
import { capitalize, formatTimeStamp } from "util/Util";
import { toast,ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DocumentModal from "pages/operators/DocumentModal";
import DisplayTaxProfile from "pages/operators/DisplayTaxProfile";
const UserStatus:any={
  0: "Pending",
  1: "SubmittedForReview",
  2: "ManagerReview",
  3: "Completed",
  4: "PaymentComplete"
}
export default function EmployeeList() {
  const [data, setData] = useState<Row[]>([]);
  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [viewData, setViewData] = useState();
  const [addEmployee, setAddEmployee] = useState(false);
  const [editEmployee, setEditEmployee] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null); // State for selected row
  const { t } = useTranslation();
  const [editStatusIndex, setEditStatusIndex] = useState<number | null>(null);
  const [editOperatorIndex, setEditOperatorIndex] = useState<number | null>(null);
  const [updatedStatus, setUpdatedStatus] = useState<string>("");
  const [updatedOperator, setUpdatedOperator] = useState<string>("");
    const [selectedUserRegId, setSelectedUserRegId] = useState<number | null>(
      null
    );
    const [isModalOpen, setIsModalOpen] = useState(false);
      const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    
  const [filteredData, setFilteredData] = useState<any>([]); // Filtered data
  const [selectedStatus, setSelectedStatus] = useState<string>("ALL"); // Selected filter value
 
  const navigate = useNavigate(); 

  useEffect(() => {
    setLoading(true);
    apiService
      .get("registrations")
      .then((res) => {
        const sortedData = res.data.sort((a: any, b: any) => {
          return b.last_login_time-a.last_login_time
        });
        setData(sortedData);
        setFilteredData(sortedData);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });

    apiService
      .get("operators")
      .then((res) => {
        const operatorsMap = res.data.reduce((acc: any, operator: any) => {
          acc[operator.operator_id] = operator.operator_name;
          return acc;
        }, {});
    
        setOperators(operatorsMap);  // Store the transformed data
      })
      .catch((err) => {
        console.error("Failed to fetch operators:", err);
      });
  }, []);
  useEffect(() => {
    console.log(selectedStatus);
    if (selectedStatus === "ALL") {
      let sortData=data.sort((a: any, b: any) => {       
        return b.last_login_time-a.last_login_time
      });
      console.log('Sort data ',sortData)
      setFilteredData(sortData);  // No filtering, show all data
    } else {
      setFilteredData(data.filter((user:any) => user.user_status.toString() === selectedStatus).sort((a: any, b: any) => {
        return b.last_login_time-a.last_login_time

      }));
    }
  }, [selectedStatus, data]);

  const handleDelete = async (row: any) => {
    try {
      await apiService.delete(`registrations/${row.reg_id}`); // Adjust the endpoint
      setData((prevData) => prevData.filter((item) => item.reg_id !== row.reg_id)); // Update state after deletion
      setOpenDeletePopup(false); // Close the popup
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };

  const handleOperatorChange = async (
    row: any,
    operator_id: string,
    data: any
  ) => {
    try {
      const url = `registrations/${data.reg_id}/operator/${operator_id}`; // Include operator_id in the URL
      console.log("Update Operator URL:", url,data);
      await apiService.put(url); // No need to send operator_id in the body
      setData((prevData) =>
        prevData.map((item) =>
          item.reg_id === data.reg_id ? { ...item, operator_id } : item
        )
      );
      toast.success(`Assigned operator ${operators[operator_id]} successfully`);
    } catch (error) {
      console.error("Error updating operator:", error);
    }
  };
  const handleStatusChange = async (userId: number, newStatus: string) => {
    try {
      await apiService.put(`/registrations/update-status/${userId}/${newStatus}`);
      setData((prevData) =>
        prevData.map((item) =>
          item.reg_id === userId ? { ...item, user_status: newStatus } : item
        )
      );
      toast.success("Status updated successfully");
    } catch (err:any) {
      if(err.response?.status===401){
        navigate("/session-expired");
      }else
      toast.error("Failed to update status");
      console.error("Error updating status:", err);
    }
  };
  const handleViewDocuments = (reg_id: any) => {
    setSelectedUserRegId(reg_id.reg_id);
    setIsModalOpen(true);
  };

  const handleViewProfile = (reg_id: any) => {
    console.log("Profile ID : ", reg_id.tax_profile_id);
    setSelectedUserRegId(reg_id.tax_profile_id);
    setIsProfileModalOpen(true);
  };
  const tableColumns = [
    {
      key: "id",
      label: `Id`,
      bold: true,
      style: { width: '80px' },
    },
    {
      key: "first_name",
      label: `Full Name`,
      bold: true,
      render: (row:any,data:any) => `${data.first_name} ${data.last_name}`.replace(/\b\w/g, (c) => c.toUpperCase()), // Capitalize full name
    },
    {
      key: "last_login_time",
      label: `Last Login`,
      render: (row:any,data:any) => <span>{formatTimeStamp(row)}</span>,
    },
    {
      key: "email",
      label: `Email ID`,
      render: (row:any,data:any) => <span>{data.email}</span>,
    }, 
    {
      key: "operator_id",
      label: `Assigned Operator`,
      style: { cursor: 'pointer' },
      render: (row:any, data:any) => (
        editOperatorIndex === data?.reg_id
        ? (
          <select
            defaultValue={row}
            onBlur={() => setEditOperatorIndex(null)}
            onChange={(e) => {
              const newOperator = e.target.value;
              console.log('updatedOperator ',newOperator)
              setUpdatedOperator(newOperator);
              handleOperatorChange(row, newOperator, data);
              setEditOperatorIndex(null); // Return to text view
            }}
          >
            <option value="0">Unassigned</option>
            {Object.keys(operators).map((operator) => (
              <option key={operator} value={operator}>
                {operators[operator]}
              </option>
            ))}
          </select>
        ) : (
          <span title="Assign operator" onClick={() => setEditOperatorIndex(data?.reg_id)}>
            {/* {row} */}
          {operators[row] || "Unassigned"}
        </span>
        )
      ),
    },
    {
      key: "user_status",
      label: `User Status`,
      style: { cursor: 'pointer' },
      render: (row: any, data: any) => (
        editStatusIndex === data.reg_id ? (
          <select
            defaultValue={row} // Use controlled component
            onBlur={() => setEditStatusIndex(null)} // Return to text view on blur
            onChange={(e) => {
              const newStatus = e.target.value;
              setUpdatedStatus(newStatus);
              handleStatusChange(data.reg_id, newStatus); // Make sure to update the state
              setEditStatusIndex(null); // Close dropdown and return to text view
            }}
            style={ { width: '160px',fontSize:'15px' }}
          >
            <option value="0">Pending</option>
            <option value="1">SubmittedForReview</option>
            <option value="2">ManagerReview</option>
            <option value="3">Completed</option>
            <option value="4">PaymentComplete</option>
          </select>
        ) : (
          <span title="Change status" onClick={() => {
            setEditStatusIndex(data.reg_id);
            setUpdatedStatus(data.user_status); // Ensure the correct status is displayed when editing
          }}>
            {UserStatus[row]} {/* Use the user_status index to display the correct status */}
          </span>
        )
      ),
    },    
    {
      key: "actions",
      label: `Actions`,
      excludeExport: true,
      style: { width: '180px' },
      actions: (row:any) => (
        <div className="flex">
           <span title="View Uploaded Documents List"
                    className="btn-primary px-2 py-2 mr-2 rounded-lg"
                    onClick={() => handleViewDocuments(row)}
                  >
                                <FaDochub className="text-white" />

                  </span>
                  <span title="Display Client Profile"
                    className="btn-primary px-2 py-2 mr-2 rounded-lg"
                    onClick={() => handleViewProfile(row)}
                  >
                                <FaUser className="text-white" />

                  </span>
          <span title="Display Client Details" onClick={() => handleView(row)} className="btn-primary px-2 py-2 mr-2 rounded-lg">
            <FaEye className="text-white" />
          </span>
          <span title="Delete Client" onClick={() => handleDeleteData(row)} className="btn-primary px-2 py-2 rounded-lg">
            <FaTrash className="text-white" />
          </span>
         
        </div>
      ),
    },
  ];

  const handleView = (row: any): void => {
    setViewData(row);
    setOpenModal(true);
  };

  const handleEdit = (row: any): void => {
    setViewData(row);
    setEditEmployee(true);
  };
  const handleDeleteData = (row: any): void => {
    console.log('Delete Row : ',row)
    setSelectedRow(row);
    setOpenDeletePopup(true);
  };
  const handleAddfunction = () => {
    setAddEmployee(!addEmployee);
  };

  const BreadCrumbList = [
    { name: "Users", link: "#" },
    { name: "list", link: "#" },
  ];
  const closeModal = () => {
    setSelectedUserRegId(null);
    setIsModalOpen(false);
    setIsProfileModalOpen(false);
  };
  return (
    <>
     <ToastContainer autoClose={1000} />
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <div className=" pt-4 pl-4 my-4">
        <label htmlFor="filterbystatus">Filter By User Status</label>
      <select         
          id="filterbystatus"
          className="ml-4"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="0">Pending</option>
            <option value="1">SubmittedForReview</option>
            <option value="2">ManagerReview</option>
            <option value="3">Completed</option>
            <option value="4">PaymentComplete</option>
            <option value="ALL">All</option>
          </select>
        </div>
      <DataTable
        data={filteredData}
        columns={tableColumns}
        loading={loading}
        onAddBtnClick={handleAddfunction}
      />
      {openModal && (
        <Modal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          header="View Employee Details"
        >
          {viewData && <EmployeeDetails data={viewData} />}
        </Modal>
      )}
      {addEmployee && (
        <Modal
          isOpen={addEmployee}
          onClose={() => setAddEmployee(false)}
          header="Add Employee"
        >
          <AddEmployee onClose={() => setAddEmployee(false)} />
        </Modal>
      )}
      {editEmployee && (
        <Modal
          isOpen={editEmployee}
          onClose={() => setEditEmployee(false)}
          header="Edit Employee"
        >
          <EditEmployee onClose={() => setEditEmployee(false)} data={viewData} />
        </Modal>
      )}
      {openDeletePopup && (
        <Modal
          isOpen={openDeletePopup}
          onClose={() => setOpenDeletePopup(false)}
          header="Delete Employee"
        >
          <DeletePopup
            handleDelete={handleDelete}
            onClose={() => setOpenDeletePopup(false)}
            selectedRow={selectedRow}
          />
        </Modal>
      )}
       {/* Document Modal */}
       {isModalOpen && selectedUserRegId && (
          <DocumentModal regId={selectedUserRegId} onClose={closeModal} />
        )}

        {/* Tax Profile Modal */}
        {isProfileModalOpen && (
          <DisplayTaxProfile regId={selectedUserRegId} onClose={closeModal} />
        )}
    </>
  );
}
