import React from "react";

export default function ForeignAccountsReporting() {
  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-4 text-left">
        Foreign Accounts and Assets Reporting
      </h2>
      <p className="text-lg font-semibold mb-2">FATCA - Foreign Account Tax Compliance Act</p>
      <p className="text-justify mb-4">
        If you had more than $50,000 in your foreign country in your bank accounts, securities, deposits, or assets, you are required to file a FATCA report using Form 8938 with the IRS along with your income tax return before April 15.
      </p>
      <p className="text-justify">
        All U.S. citizens and residents who have a financial interest in or authority over any foreign financial account, and if the aggregate value of these accounts exceeds $10,000 at any time during the calendar year, must file an FBAR with the IRS.
      </p>
    </div>
  );
}
