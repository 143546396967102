// src/components/EditEmailTemplate.tsx
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import apiService from 'services/apiService';
import { useNavigate } from 'react-router-dom';

export interface EmailTemplate {
  id: number;
  purpose: string;
  subject: string;
  message: string;
  message1: string;
  status: number;
}

interface EditEmailTemplateProps {
  data: EmailTemplate;
  onClose: () => void;
  onUpdate: () => void;
}

// Define modules and formats as separate constants
const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['clean'],
  ],
};

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'link',
  'image',
];

const EditEmailTemplate: React.FC<EditEmailTemplateProps> = ({
  data,
  onClose,
  onUpdate,
}) => {
  const [purpose, setPurpose] = useState<string>(data.purpose);
  const [subject, setSubject] = useState<string>(data.subject);
  const [message, setMessage] = useState<string>(data.message);
  const [message1, setMessage1] = useState<string>(data.message1);
  const [status, setStatus] = useState<number>(data.status);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate(); 

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
    'purpose': purpose,
    'subject': subject,
    'message': message,
    'message1': "",
    'status': status.toString(),}
   console.log('Data : ',purpose,subject,message,status,formData) 

    try {
      await apiService.put(`/admin/email_templates/${data.id}`, formData);

      
      onUpdate();
      toast.success('Email template updated successfully.');
      onClose();
    } catch (err:any) {
      if(err.response?.status===401){
        navigate("/session-expired");
      }else
      toast.error('Failed to update email template.');
    } finally {
      setLoading(false);
    }
  };



  return (
    <form onSubmit={handleSubmit} >
      {/* Purpose Field */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Purpose</label>
        <input
          type="text"
          value={purpose}
          onChange={(e) => setPurpose(e.target.value)}
          required
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      {/* Subject Field */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Subject</label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      {/* Message Field with React Quill */}
      <div >
        <label className="block text-sm font-medium text-gray-700">Message</label>
        <ReactQuill
          theme="snow"
          value={message}
          onChange={setMessage}
          className="mt-1  w-full editors"
          modules={quillModules}
          formats={quillFormats}
        />
      </div>

      {/* Additional Message Field with React Quill */}
      {/* <div>
        <label className="block text-sm font-medium text-gray-700">Additional Message</label>
        <ReactQuill
          theme="snow"
          value={message1}
          onChange={setMessage1}
          className="mt-1 block w-full"
          modules={quillModules}
          formats={quillFormats}
        />
      </div> */}

      {/* Status Field */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Status</label>
        <select
          value={status ? 'active' : 'inactive'}
          onChange={(e) => setStatus(parseInt(e.target.value))}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
      </div>

      {/* Image Upload Field */}
      {/* <div>
        <label className="block text-sm font-medium text-gray-700">Image</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-blue-50 file:text-blue-700
          hover:file:bg-blue-100"
        />
      </div> */}

      {/* Action Buttons */}
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          {loading ? 'Updating...' : 'Update'}
        </button>
      </div>
    </form>
  );
};

export default EditEmailTemplate;
