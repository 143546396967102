// src/pages/EmailVerification.tsx
import React, { useEffect, useState } from "react";
import apiService from "services/apiService"; // Assume apiService is an axios instance
import { useParams, useNavigate } from "react-router-dom";

const EmailVerification = () => {
  const { verificationCode } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        await apiService.post(`/registrations/verify/${verificationCode}`);
        navigate('/verified')
        window.location.reload();
      } catch (error) {
        console.error("Verification error:", error);
        setSuccessMessage("Your email verification link already used!");

      }
    };

    verifyEmail();
  }, [verificationCode]);



  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
    <h2 className="text-xl font-bold text-green-600">
        {successMessage}
    </h2>
  </div>
  );
};

export default EmailVerification;
