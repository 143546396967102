import React, { ReactNode } from "react";

interface GenericModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept?: () => void;
  onDecline?: () => void;
  children: ReactNode;
  acceptText?: string;
  cancelText?: string;
  header?: string;
  customCss?: React.CSSProperties;
}

const GenericModal = ({
  isOpen,
  onClose,
  onAccept,
  onDecline,
  children,
  acceptText,
  cancelText,
  header,
  customCss,
}: GenericModalProps) => {
  if (!isOpen) {
    return null;
  }
  const customStyles = {
    backgroundColor: "rgba(107, 114, 128, 0.4)",
  };
  const customStyles1 = { ...customCss };

  return (
    <div
      className="fixed inset-0 overflow-y-auto overflow-x-hidden z-50 flex justify-center items-center"
      style={customStyles}
    >
      <div className={`relative p-4  ${customCss ? "" : "w-full max-w-2xl"}`} style={customStyles1}>
        <div className="relative bg-white rounded-lg shadow">
          {/* Modal header */}
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-xl font-semibold text-gray-900">{header}</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Modal body */}
          <div className="p-4 md:p-5 space-y-4">{children}</div>
          {/* Modal footer */}
          {(onDecline || onAccept) && (
            <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b">
              {onDecline && (
                <button
                  type="button"
                  className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
                  onClick={onDecline}
                >
                  {cancelText}
                </button>
              )}
              {onAccept && (
                <button
                  type="button"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 ms-3"
                  onClick={onAccept}
                >
                  {acceptText}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenericModal;
