import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
export default function DefaultLayout() {
  useEffect(()=>{
    console.log('Reload') 
  },[]);
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow container mx-auto px-8 py-4 ">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}
