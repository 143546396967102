// import React, { useEffect, useState } from "react";
// import DataTable, { Row } from "components/DataTable";
// import apiService from "services/apiService";
// import { FaEdit, FaEye } from "react-icons/fa";
// import Modal from "components/Modal";
// import { BreadCrumb } from "components/BreadCrumb";
// import { useTranslation } from "react-i18next";
// import OperatorDetails from "./OperatorDetails";
// import AddOperator from "./AddOperator";
// import EditOperator from "./EditOperator";
// import { useNavigate } from "react-router-dom";

// export default function OperatorList() {
//   const [data, setData] = useState<Row[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [openModal, setOpenModal] = useState(false);
//   const [viewData, setViewData] = useState<Row | null>(null); // Type set to Row | null for better type safety
//   const [addOperator, setAddOperator] = useState(false);
//   const [editOperator, setEditOperator] = useState(false);
//   const { t } = useTranslation();
//   const navigate = useNavigate(); 

//   useEffect(() => {
//     const fetchOperators = async () => {
//       setLoading(true);
//       try {
//         const res = await apiService.get("operators");
//         console.log("operator res :>> ", res);
//         setData(res.data);
//       } catch (err:any) {
//         if(err.response?.status===401){
//           navigate("/session-expired");
//         }
//         console.error("Error fetching operators:", err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchOperators();
//   }, []);

//   const tableColumns = [
//     { key: "id", label: `Id`, bold: true, style: { width: '80px'}},
//     { key: "operator_name", label: `${t("Operators.name")}`, bold: true },
//     { key: "operator_email", label: `${t("Operators.email")}` },
//     {
//       key: "status",
//       label: `Status`,
//       style: { width: '80px'},
//       render: (row: any) => {
//         const isActive = row === 0; // Adjust this according to your data structure
//         return (
//           <div className="flex justify-center items-center h-full">
//             <span
//               className={`inline-block rounded-full w-4 h-4 ${
//                 isActive ? "bg-green-500" : "bg-red-500"
//               }`}
//             ></span>
//           </div>
//         );
//       }
//     },
//     {
//       key: "actions",
//       label: `${t("Operators.actions")}`,
//       excludeExport: true,
//       actions: (row: Row) => (
//         <div className="flex justify-start items-center h-full">
//         <span
//             onClick={() => handleEdit(row)}
//             className="btn-primary px-2 py-2 mr-2 ml-3 rounded-lg cursor-pointer"
//           >
//             <FaEdit className="text-white" />
//           </span>
//           <span
//             onClick={() => handleView(row)}
//             className="btn-primary px-2 py-2 mr-2 rounded-lg cursor-pointer"
//           >
//             <FaEye className="text-white" />
//           </span>
//         </div>
//       ),
//     },
//   ];

//   const handleView = (row: Row): void => {
//     console.log("row", row);
//     setViewData(row);
//     setOpenModal(true);
//   };

//   const handleEdit = (row: Row): void => {
//     setViewData(row);
//     setEditOperator(true);
//   };

//   const handleAddFunction = () => {
//     setAddOperator((prev) => !prev); // Use functional state update
//   };

//   const BreadCrumbList = [
//     { name: "Users", link: "#" },
//     { name: "list", link: "#" },
//   ];

//   return (
//     <>
//       <BreadCrumb BreadCrumbList={BreadCrumbList} />
//       <DataTable
//         data={data}
//         columns={tableColumns}
//         loading={loading}
//         onAddBtnClick={handleAddFunction}
//       />
//       {openModal && (
//         <Modal
//           isOpen={openModal}
//           onClose={() => setOpenModal(false)}
//           header="View Operator Details"
//         >
//           {viewData && <OperatorDetails data={viewData} />}
//         </Modal>
//       )}
//       {addOperator && (
//         <Modal
//           isOpen={addOperator}
//           onClose={() => setAddOperator(false)}
//           header="Add Operator"
//         >
//           <AddOperator onClose={() => setAddOperator(false)} />
//         </Modal>
//       )}
//       {editOperator && (
//         <Modal
//           isOpen={editOperator}
//           onClose={() => setEditOperator(false)}
//           header="Edit Operator"
//         >
//           {viewData && (
//             <EditOperator
//               onClose={() => setEditOperator(false)}
//               data={viewData}
//             />
//           )}
//         </Modal>
//       )}
//     </>
//   );
// }
import React, { useEffect, useState } from "react";
import DataTable, { Row } from "components/DataTable";
import apiService from "services/apiService";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import Modal from "components/Modal";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import OperatorDetails from "./OperatorDetails";
import AddOperator from "./AddOperator";
import EditOperator from "./EditOperator";
import { useNavigate } from "react-router-dom";

export default function OperatorList() {
  const [data, setData] = useState<Row[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [viewData, setViewData] = useState<Row | null>(null);
  const [addOperator, setAddOperator] = useState(false);
  const [editOperator, setEditOperator] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteRow, setDeleteRow] = useState<Row | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Fetch operators
  const fetchOperators = async () => {
    setLoading(true);
    try {
      const res = await apiService.get("operators");
      setData(res.data);
    } catch (err: any) {
      if (err.response?.status === 401) {
        navigate("/session-expired");
      }
      console.error("Error fetching operators:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOperators();
  }, []);

  const handleView = (row: Row): void => {
    setViewData(row);
    setOpenModal(true);
  };

  const handleEdit = (row: Row): void => {
    setViewData(row);
    setEditOperator(true);
  };

  const handleAddFunction = () => {
    setAddOperator(true);
  };

  const handleDelete = (row: Row): void => {
    setDeleteRow(row);
    setDeleteConfirm(true);
  };

  const confirmDelete = async () => {
    if (!deleteRow) return;

    try {
      await apiService.delete(`operators/${deleteRow.operator_id}`);
      setDeleteConfirm(false);
      fetchOperators(); // Refresh the list
    } catch (error) {
      console.error("Error deleting operator:", error);
    }
  };

  const tableColumns = [
    { key: "id", label: "Id", bold: true, style: { width: "80px" } },
    { key: "operator_name", label: t("Operators.name"), bold: true },
    { key: "operator_email", label: t("Operators.email") },
    {
      key: "status",
      label: "Status",
      style: { width: "80px" },
      render: (row: any) => {
        const isActive = row === 0;
        return (
          <div className="flex justify-center items-center h-full">
            <span
              className={`inline-block rounded-full w-4 h-4 ${
                isActive ? "bg-green-500" : "bg-red-500"
              }`}
            ></span>
          </div>
        );
      },
    },
    {
      key: "actions",
      label: t("Operators.actions"),
      excludeExport: true,
      actions: (row: Row) => (
        <div className="flex justify-start items-center h-full">
          <span
            onClick={() => handleEdit(row)}
            className="btn-primary px-2 py-2 mr-2 ml-3 rounded-lg cursor-pointer"
          >
            <FaEdit className="text-white" />
          </span>
          <span
            onClick={() => handleView(row)}
            className="btn-primary px-2 py-2 mr-2 rounded-lg cursor-pointer"
          >
            <FaEye className="text-white" />
          </span>
          <span
            onClick={() => handleDelete(row)}
            className="btn-primary px-2 py-2 rounded-lg cursor-pointer"
          >
            <FaTrash className="text-white" />
          </span>
        </div>
      ),
    },
  ];

  const BreadCrumbList = [
    { name: "Users", link: "#" },
    { name: "list", link: "#" },
  ];

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <DataTable
        data={data}
        columns={tableColumns}
        loading={loading}
        onAddBtnClick={handleAddFunction}
      />
      {openModal && (
        <Modal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          header="View Operator Details"
        >
          {viewData && <OperatorDetails data={viewData} />}
        </Modal>
      )}
      {addOperator && (
        <Modal
          isOpen={addOperator}
          onClose={() => {
            setAddOperator(false);
            fetchOperators(); // Refresh the list
          }}
          header="Add Operator"
        >
          <AddOperator onClose={() => setAddOperator(false)} />
        </Modal>
      )}
      {editOperator && (
        <Modal
          isOpen={editOperator}
          onClose={() => {
            setEditOperator(false);
            fetchOperators(); // Refresh the list
          }}
          header="Edit Operator"
        >
          {viewData && (
            <EditOperator
              onClose={() => setEditOperator(false)}
              data={viewData}
            />
          )}
        </Modal>
      )}
      {deleteConfirm && (
        <Modal
          isOpen={deleteConfirm}
          onClose={() => setDeleteConfirm(false)}
          header="Confirm Deletion"
        >
          <p>Are you sure you want to delete this operator?</p>
          <div className="flex justify-end space-x-4 mt-4">
            <button
              className="btn-secondary"
              onClick={() => setDeleteConfirm(false)}
            >
              Cancel
            </button>
            <button className="btn-danger" onClick={confirmDelete}>
              Confirm
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
