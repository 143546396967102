import React, { useEffect, useState } from "react";
import apiService from "services/apiService";
import ProfileDetails from "./ProfileDetails";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import appConfig from "services/apiConfig";

interface User {
  name: string;
  email: string;
  password: string;
  image: string;
}

export default function Profile() {
  const [user, setUser] = useState<User | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isUpdating, setIsUpdating] = useState(false); // Add state to track the update process

  useEffect(() => {
    apiService
      .get("admin/profile")
      .then((res) => {
        const fullImageUrl = `${appConfig.getBaseUrl()}/profile/${res.data.image}`;
        setUser({ ...res.data, image: fullImageUrl });
      })
      .catch((err) => {
        console.log(`profile err: ${err}`);
      });
  }, []);

  const handleSubmit = async (updatedUser: User) => {
    setIsUpdating(true); // Set updating state to true when the process starts

    const formData = new FormData();
    formData.append("name", updatedUser.name);
    formData.append("email", updatedUser.email);
    formData.append("status", "active");
    if (selectedImage) {
      formData.append("image", selectedImage);
    }
    if (updatedUser.password) {
      formData.append("password", updatedUser.password);
    }

    try {
      const response = await apiService.put("/admin/profile", formData);
      const fullImageUrl = `${appConfig.getBaseUrl()}/profile/${response.data.image}`;
      setUser((prev) => ({ ...prev, ...updatedUser, image: fullImageUrl }));

      toast.success("Profile updated successfully!");
    } catch (error) {
      toast.error("Error updating profile");
      console.error("Error updating profile:", error);
    } finally {
      setIsUpdating(false); // Reset updating state when the process finishes
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  return (
    <>
      {user && (
        <ProfileDetails
          user={user}
          onUpdate={handleSubmit}
          onImageChange={handleImageChange}
          selectedImage={selectedImage}
          isUpdating={isUpdating} // Pass isUpdating to ProfileDetails
        />
      )}
      <ToastContainer />
    </>
  );
}
