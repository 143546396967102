// src/Home.js
import React from "react";
import HomeTestimonial from "./HomeTestimonial";

const Home = () => {
  return (
    <div className="container">
      <h1 className="text-2xl font-bold mb-8 text-center">
        WELCOME TO HTTAX SOLUTIONS
      </h1>

      <div className="bg-white p-6 rounded-lg shadow-md text-justify">
        <p>
          HTTaxSolutions is a team of experienced professionals dedicated to
          making your tax filing process simple, safe, and secure. Our
          procedures are designed to make Tax Return preparation easy and
          painless while providing the most economical services to our clients.
          HTTaxSolutions online process takes away the time and cost of visiting
          tax firms to file your returns. Once registered, clients are guided
          through the process for tax return filing in a time-efficient manner.
          We are committed to providing close, personal attention to our
          clients. While we handle all tax-related issues, individual,
          self-employed individuals, sole proprietary business, and
          resident/non-resident alien tax preparations are our specialty.
        </p>
        <p className="mt-4">
          Your return will be prepared by qualified professionals (Enrolled
          Agents and Registered Preparers). We GUARANTEE the accuracy of the tax
          returns prepared by providing&nbsp;FREE Audit Support and
          Representation to the IRS if ever required. We also offer IRS
          Certifying Agent services.
        </p>
        <p className="mt-4">
          <a
            href="https://www.httaxsolutions.com/about_us"
            rel="noopener noreferrer"
            target="_blank"
            className="text-blue-600 font-semibold underline"
          >
            <strong>Read More&nbsp;</strong>
          </a>
        </p>
      </div>
      {/* <div className="w-4/5 max-w-5xl mx-auto mb-8">
        <HomeTestimonial />
      </div> */}
    </div>
  );
};

export default Home;
