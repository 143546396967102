// src/pages/operators/DocumentModal.tsx

import React, { useEffect, useState } from "react";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import appConfig from "services/apiConfig";
import axios from "axios";

interface Document {
  title: string;
  document_name: string;
  file_type: string;
  operator_review_status: string;
  operator_review_date: string | null;
  upload_date: string;
  status: string;
  signedUrl:string;
  id:number;
}

interface DocumentModalProps {
  regId: number;
  onClose: () => void;
}

const DocumentModal: React.FC<DocumentModalProps> = ({ regId, onClose }) => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deletingDocId, setDeletingDocId] = useState<number | null>(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      setIsLoading(true);
      try {
        const response = await apiService.get(
          `/operators/assigned-users/${regId}/documents`
        );
        setDocuments(response.data);
      } catch (error) {
        console.error("Error fetching documents:", error);
        // toast.error("Failed to fetch documents.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchDocuments();
  }, [regId]);

  const handleDownload = (documentName: string) => {
    // Initiate download
    window.open(
      `${documentName}`,
      "_blank"
    );
  };
  const handleDeleteDocument = async (docId: number) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this document?");
    if (!confirmDelete) return;
console.log('Doc ID : ',docId)
    setDeletingDocId(docId); 
    try {
      await apiService.delete(`/operators/upload-documents/${docId}`);
      setDocuments((prevDocs) => prevDocs.filter((doc: any) => doc.id !== docId));
      toast.success("Document deleted successfully.");      
    } catch (error) {
      console.error("Error deleting document:", error);
      toast.error("Failed to delete document.");
    } finally {
      setDeletingDocId(null);
    }
  };
  // const handleDownload = async (documentName: string | number | boolean) => {
  //   const token = "your-jwt-token"; // Replace with your actual token
  //   try {
  //     const response = await axios.get(`/operators/documents/download/${encodeURIComponent(documentName)}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log(response.data);
  //   } catch (error:any) {
  //     console.error(error.response?.data || error.message);
  //   }
  // };

  const handleDownloadAll = () => {
    const token = localStorage.getItem("accessToken"); // Replace with your token retrieval logic
    if (!token) {
      console.error("No token found!");
      return;
    }
  
    const url = `${appConfig.getBaseUrl()}/operators/documents/download-all/${regId}`;
    
    // Create an options object for the fetch API
    const options = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    };
  
    // Use fetch to download the document
    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob(); // Convert response to a blob
      })
      .then((blob) => {
        // Create a link element for downloading the blob
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `user_${regId}_documents.zip`; // Name of the zip file
        link.click(); // Simulate click to download
      })
      .catch((error) => {
        console.error("Download failed:", error);
      });
  };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      {/* Modal Container */}
      <div
        className="bg-white w-3/4 min-h-[60%] rounded-lg shadow-lg p-6 overflow-y-auto relative"
        style={{ maxHeight: "60%" }}
      >
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 text-2xl"
          onClick={onClose}
          aria-label="Close Modal"
        >
          &times;
        </button>

        {/* Modal Title */}
        <h2 className="text-xl font-bold mb-4">Documents for User ID: {regId}</h2>

        {/* Loading State */}
        {isLoading ? (
          <p>Loading documents...</p>
        ) : (
          <>
            {/* Documents Table */}
            {documents.length > 0 ?
              <>
            <table className="min-w-full bg-white border mb-4">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">Title</th>
                  <th className="py-2 px-4 border-b">Document Name</th>
                  <th className="py-2 px-4 border-b">File Type</th>
                  <th className="py-2 px-4 border-b">Review Status</th>
                  <th className="py-2 px-4 border-b">Review Date</th>
                  <th className="py-2 px-4 border-b">Upload Date</th>
                  <th className="py-2 px-4 border-b">Status</th>
                  <th className="py-2 px-4 border-b">Actions</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((doc, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b">{doc.title}</td>
                    <td className="py-2 px-4 border-b">{doc.document_name}</td>
                    <td className="py-2 px-4 border-b">{doc.file_type}</td>
                    <td className="py-2 px-4 border-b">
                      {doc.operator_review_status}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {doc.operator_review_date || "N/A"}
                    </td>
                    <td className="py-2 px-4 border-b">{doc.upload_date}</td>
                    <td className="py-2 px-4 border-b">{doc.status}</td>
                    <td className="py-2 px-4 border-b flex justify-center">
                      <button
                        className="bg-green-500 text-white px-2 py-1 rounded mr-2 hover:bg-green-600"
                        onClick={() => handleDownload(doc.signedUrl)}
                      >
                        Download
                      </button>
                    
                          <button
                            onClick={() => handleDeleteDocument(doc.id)}
                            className="bg-red-500 text-white px-2 py-1 rounded mr-2 hover:bg-red-600"
                            disabled={deletingDocId === doc.id}
                          >
                            {deletingDocId === doc.id ? "Deleting..." : "Delete"}
                          </button>
                        </td>
                  </tr>
                ))}
              </tbody>
            </table>

         
         
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={handleDownloadAll}
              >
                Download All
              </button>
              </>:<> <table className="min-w-full bg-white border mb-4">
  <thead>
    <tr>
      <th className="py-4 px-6 border-b text-gray-600 text-center">
        No documents have been uploaded yet. Please upload documents to view them here.
      </th>
    </tr>
  </thead>
</table>

                  </>
            }
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentModal;
