// src/components/EnquiryList.js
import React, { useEffect, useState } from "react";
import DataTable, { Row } from "components/DataTable"; // Ensure DataTable is correctly implemented
import apiService from "services/apiService";
import { FaEye, FaTrash } from "react-icons/fa";
import Modal from "components/Modal"; // Ensure Modal component is available

import DeletePopup from "components/DeletePopup/deletePopup"; // To be created
import { BreadCrumb } from "components/BreadCrumb"; // Ensure BreadCrumb component exists
import EnquiryDetails from "./EnquiryDetails";

export default function EnquiryList() {
  const [data, setData] = useState<Row[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const BreadCrumbList = [
    { name: "Enquiries", link: "/enquiries" },
    { name: "List", link: "/enquiries" },
  ];

  useEffect(() => {
    fetchEnquiries();
  }, []);

  const fetchEnquiries = async () => {
    setLoading(true);
    try {
      const res = await apiService.get("/admin/enquiries");
      setData(res.data);
    } catch (err) {
      console.error("Error fetching enquiries:", err);
      // Optionally, display an error message to the user
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (row:any) => {
    try {
      await apiService.delete(`/admin/enquiries/${row.id}`);
      setData((prevData) => prevData.filter((item) => item.id !== row.id));
      setOpenDeletePopup(false);
    } catch (error) {
      console.error("Error deleting enquiry:", error);
      // Optionally, display an error message to the user
    }
  };

  const handleView = (row:any) => {
    setViewData(row);
    setOpenModal(true);
  };

  const handleDeleteData = (row:any) => {
    setSelectedRow(row);
    setOpenDeletePopup(true);
  };

  const tableColumns = [
    { key: "id", label: "ID", bold: true, style: { width: '80px' } },
    { key: "name", label: "Name", bold: true },
    { key: "email", label: "Email" },
    { key: "phone_no", label: "Contact", bold: true },
    { key: "comment_message", label: "Comment" },
    {
      key: "actions",
      label: "Actions",
      excludeExport: true,
      actions: (row:any) => (
        <div className="flex space-x-2">
          <button
            onClick={() => handleView(row)}
            className="text-blue-500 hover:text-blue-700"
            title="View Details"
          >
            <FaEye />
          </button>
          <button
            onClick={() => handleDeleteData(row)}
            className="text-red-500 hover:text-red-700"
            title="Delete Enquiry"
          >
            <FaTrash />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Enquiries List</h1>
        <DataTable
          data={data}
          columns={tableColumns}
          loading={loading}
          // If you have an add button, you can include it here
        />
      </div>

      {/* View Enquiry Modal */}
      {openModal && (
        <Modal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          header="Enquiry Details"
        >
          {viewData && <EnquiryDetails data={viewData} />}
        </Modal>
      )}

      {/* Delete Confirmation Popup */}
      {openDeletePopup && (
        <Modal
          isOpen={openDeletePopup}
          onClose={() => setOpenDeletePopup(false)}
          header="Confirm Deletion"
        >
          <DeletePopup
            handleDelete={() => handleDelete(selectedRow)}
            onClose={() => setOpenDeletePopup(false)}
            selectedRow={selectedRow}          />
        </Modal>
      )}
    </>
  );
}
