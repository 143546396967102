import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isIndividualOpen, setIsIndividualOpen] = useState(false);
  const [isCorporateOpen, setIsCorporateOpen] = useState(false);

  const individualRef = useRef(null);
  const corporateRef = useRef(null);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        individualRef.current &&
        !individualRef.current.contains(event.target)
      ) {
        setIsIndividualOpen(false);
      }
      if (
        corporateRef.current &&
        !corporateRef.current.contains(event.target)
      ) {
        setIsCorporateOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="bg-gray-800 text-white px-6">
      <div className="menubar container mx-auto flex justify-between items-center px-0">
        
<div className="menuicon">
          {/* Header Image */}
          <img
          src="logo192.png"
          alt="HTTax Solutions"
          className="py-2"
          style={{ height: "70px" }}
        />

        {/* Mobile Menu Toggle */}
        <button
  onClick={() => setIsMenuOpen(!isMenuOpen)}
  className="menu-toggle lg:hidden focus:outline-none"
>
  ☰
</button>
</div>
<div className={`container ${isMenuOpen ? "menu-open" : ""}`}>

        {/* Navigation */}
        <nav
          className={`${
            isMenuOpen ? "block" : "hidden"
          } w-full lg:w-auto lg:flex lg:items-center lg:space-x-4`}
        >
          <ul className="flex flex-col lg:flex-row lg:space-x-4 mt-4 lg:mt-0">
            <li>
              <Link to="/" className="hover:underline">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="hover:underline">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/pricing" className="hover:underline">
                Pricing
              </Link>
            </li>

            {/* Individual Services Dropdown */}
            <li
              className="relative"
              onClick={() => setIsIndividualOpen(!isIndividualOpen)}
              ref={individualRef}
            >
              <span className="hover:underline cursor-pointer">
                Individual Services
              </span>
              {isIndividualOpen && (
                <div className="submenu absolute left-0 w-full bg-white shadow-lg rounded-lg py-2 top-full">
                  <Link
                    to="/individual_services/tax_services"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Tax Services
                  </Link>
                  <Link
                    to="/individual_services/itin_assistance"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    ITIN Assistance
                  </Link>
                  <Link
                    to="/individual_services/tax_representation"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Tax Representation
                  </Link>
                  <Link
                    to="/individual_services/amendment_filing"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Amendment Filing
                  </Link>
                  <Link
                    to="/individual_services/foreign_accounts_and_assets_reporting"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Foreign Accounts and Assets Reporting
                  </Link>
                  <Link
                    to="/individual_services/other_services"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Other Services
                  </Link>
                </div>
              )}
            </li>

            {/* Corporate Services Dropdown */}
            <li
              className="relative"
              onClick={() => setIsCorporateOpen(!isCorporateOpen)}
              ref={corporateRef}
            >
              <span className="hover:underline cursor-pointer">
                Corporate Services
              </span>
              {isCorporateOpen && (
                <div className="submenu absolute left-0 w-full bg-white shadow-lg rounded-lg py-2 top-full">
                  <Link
                    to="/corporate_services/limited_liability_company"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Limited Liability Company (LLC)
                  </Link>
                  <Link
                    to="/corporate_services/corporate_returns"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Corporate Returns - 1120 & 1120 S
                  </Link>
                  <Link
                    to="/corporate_services/accounting_services"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Accounting Services
                  </Link>
                </div>
              )}
            </li>

            {/* <li>
              <Link to="/testimonials" className="hover:underline">
                Testimonials
              </Link>
            </li> */}
            <li>
              <Link to="/contact" className="hover:underline">
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/login" className="hover:underline">
                Login
              </Link>
            </li>
          </ul>
        </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
