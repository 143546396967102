// PrivateRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { UserRole } from "types/role";

interface PrivateRouteProps {
  Layout: React.ElementType;
  userRole: UserRole; // Add userRole prop
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ Layout, userRole }) => {
  // Check for accessToken in localStorage
  const isAuthenticated = !!localStorage.getItem('accessToken'); // true if accessToken is present

  return isAuthenticated ? (
    <Layout userRole={userRole} /> // Pass userRole to the Layout
  ) : (
    <Navigate to={userRole==='Client'?"/login":userRole==='Admin'?"/adminlogin":"/operatorlogin"} />
  );
};

export default PrivateRoute;
