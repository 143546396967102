import React from "react";

export default function ITINAssistance() {
  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-4 text-left">ITIN Assistance</h2>
      <p className="text-justify">
        HTTaxSolutions is approved by the IRS as a Certifying Acceptance Agent, helping individuals with the Individual Taxpayer Identification Number (ITIN) application process. We provide guidance and support to ensure a smooth and efficient application experience.
      </p>
    </div>
  );
}
