// Sidebar.tsx
import ThemeSwitcher from "components/ThemeSwitcher";
import React, { useEffect, useState, useRef } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import items from "./SideBarItems"; // Adjust the import path as necessary
import { useTranslation } from "react-i18next";
import DropdownMenuItem from "./SubMenuItems";
import { UserRole } from "../../types/role"; // Import the UserRole enum

// Define props for the Sidebar component
interface SidebarProps {
  role: UserRole; // Accept role prop
}

const Sidebar: React.FC<SidebarProps> = ({ role }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [userDetails,setUserDetails]=useState<any>()

  // Close dropdown if clicked outside
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(()=>{
    let userData=JSON.parse(localStorage.getItem('userdetails')||"");
    console.log('userdetails ',userData.user)
    setUserDetails(userData.user)
  },[])
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  // Filter sidebar items based on user role
  const filteredItems = items.filter(item => {
    if (!item.roles) return true; // Show if no roles specified
    return item.roles.includes(role);
  }).map(item=>{
    return {...item,url:role==='Admin'?`/admin/${item.url}`:`/operators/${item.url}`}
  });

  return (
    <>
      <nav className="fixed top-0 z-50 w-full  bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zm0-5.5a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <Link to={role==='Admin'?"/admin":'/operator'} className="flex ms-2 md:me-24">
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                  {t("header")}
                </span>
              </Link>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ms-3">
                <div>
                  <button
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                    data-dropdown-toggle="dropdown-user"
                    aria-expanded={isDropdownOpen}
                    onClick={() => {
                      setIsDropdownOpen(!isDropdownOpen);
                    }}
                  >
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="w-8 h-8 rounded-full"
                      src="https://admin-asset-management.s3.amazonaws.com/Vijay_a19461a2779545389938e6588c4e8a2c_52_image"
                      alt="user photo"
                    />
                  </button>
                </div>
                {isDropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className="z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                    id="dropdown-user"
                    style={{ position: 'absolute', top: '35px', right: '5px' }}
                  >
                    <div className="px-4 py-3 text-center bg-blue-800" role="none">
                   
                      <p className="text-md text-shadow font-medium flex items-center flex-col text-white dark:text-white" role="none">
                      <img
                      className="w-16 h-16 rounded-full shadow"
                      src="https://admin-asset-management.s3.amazonaws.com/Vijay_a19461a2779545389938e6588c4e8a2c_52_image"
                      alt="user photo"
                    />
                        {role} {/* Display the user role here */}
                      </p>
                      <p className="text-sm font-medium text-white truncate dark:text-gray-300" role="none">
                      {`${userDetails?.username}`}
                      </p>
                      <p
                      className="text-sm font-medium text-white truncate dark:text-gray-300"
                      role="none"
                    >
                      {userDetails?.email}
                    </p>
                    </div>
                    <ul className="py-1" role="none">
                      <li>
                        <a
                          href="#"
                          className="block px-4 py-2   font-medium text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                          role="menuitem"
                          onClick={() => {
                            window.localStorage.removeItem("accessToken");
                            navigate("/"); // Redirect to home on sign out
                          }}
                        >
                          Sign out
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className="fixed top-0 left-0 z-40 w-64  pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <ul className="space-y-2 font-medium">
            {filteredItems.map((res) => (
              <li key={res.label}>
                {res.submenu ? (
                  <DropdownMenuItem
                    icon={res.icon}
                    text={t("sidebar.label", { label: res.label })}
                    submenuItems={res.submenu}
                  />
                ) : (
                  <Link
                    to={`${res.url}`}
                    className="flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700"
                  >
                    <res.icon
                      className="w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                      aria-hidden="true"
                    />
                    <span className="ms-3 ml-3">{res.label}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </aside>

      <div className="p-1 bg-gray-50 sm:ml-64">
        <div className="bg-white border-2 border-gray-200 rounded-lg dark:border-gray-700 mt-0 h-screen">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
