import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import appConfig from 'services/apiConfig';

const ResetPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      const response = await fetch(`${appConfig.getBaseUrl()}/registrations/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        setMessage('Password updated successfully. Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000); // Redirect after 3 seconds
      } else {
        const errorData = await response.json();
        setMessage(`Error updating password: ${errorData.message || 'Please try again.'}`);
      }
    } catch (error) {
      setMessage('Error updating password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 flex items-center justify-center h-[100%]">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-md w-full mt-20">
        <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">Reset Your Password</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="password" className="block text-gray-600 font-medium mb-1">New Password</label>
            <input
              type="password"
              id="password"
              className="w-full border rounded-md p-2 focus:ring focus:ring-blue-500 focus:outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="confirmPassword" className="block text-gray-600 font-medium mb-1">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              className="w-full border rounded-md p-2 focus:ring focus:ring-blue-500 focus:outline-none"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className={`w-full bg-blue-500 text-white py-2 px-4 rounded-md font-semibold hover:bg-blue-600 transition ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
        {message && (
          <p className={`text-center mt-4 ${message.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}>
            {message}
          </p>
        )}
      </div>
    </section>
  );
};

export default ResetPassword;
