import React, { useEffect, useState } from 'react';
import axios from 'axios';
import appConfig from 'services/apiConfig';

interface NotificationListProps {
    userId: any;  // userId should be a number
    role: 'Client' | 'Operator';  // role can either be 'Client' or 'Operator'
}

const NotificationList: React.FC<NotificationListProps> = ({ userId, role }) => {
    const [notifications, setNotifications] = useState<any[]>([]);  // You can define a better type for notifications if needed
    
    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`${appConfig.getBaseUrl()}/notification`, {
                    params: { userId, role }, 
                });
                setNotifications(response.data.notifications);
            } catch (error) {
                console.error('Failed to fetch notifications:', error);
            }
        };
        console.log('Fetching notifications');
        fetchNotifications();
    }, [userId, role]);

    return (
        <div className="chat-container mt-10"  style={{ height: '100%' }}>
            <h2>Notifications</h2>
            <div className="messages">
                {notifications.map((notification) => (
                    <div
                        key={notification.id}
                        className={`message-box ${notification.sent_by === 0 ? 'sent' : 'received'}`}
                    >
                        <div className="message-header">
                            <span className="sender-name">{notification.sender_name}</span>
                            <span className="message-date ml-2">
                            {new Date(notification.sent_date * 1000).toLocaleDateString('en-US')}
                            </span>                        </div>
                        <div className="message-content">
                            {notification.send_notification}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NotificationList;
