import React from "react";

export default function AmendmentFiling() {
  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-4 text-left">Amendment Filing</h2>
      <p className="text-justify">
        If you discover an error after filing your return, we can assist you in filing an amended return. The IRS allows individuals to amend their returns within 3 years of the filing due date.
      </p>
    </div>
  );
}
