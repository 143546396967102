import React, { useEffect, useState } from 'react';
import DataTable, { Row } from "components/DataTable";
import Modal from 'components/Modal';
import apiService from 'services/apiService';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { PricingForm } from './PricingForm';
interface Pricing {
    id: number;
    features: string;
    individual: number;
    business: number;
    priority: number;
    status: number;
    add_date: number;
  }

const PricingManagement = () => {
    const [pricingData, setPricingData] = useState<Row[]>([]);
    const [currentPricing, setCurrentPricing] = useState<Pricing | null>(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const fetchPricingData = async () => {
    setLoading(true);
    try {
      const response = await apiService.get('pricing');
      setPricingData(response.data);
    } catch (error) {
      console.error('Error fetching pricing data:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {


    fetchPricingData();
  }, []);

  const handleAdd = () => {
    fetchPricingData();
    setCurrentPricing(null);
    setIsEditing(false);
    setModalOpen(true);
  };

  const handleEdit = (pricing:any) => {
    fetchPricingData();
    setCurrentPricing(pricing);
    setIsEditing(true);
    setModalOpen(true);
  };

  const handleDelete = async (id:any) => {
    if (window.confirm('Are you sure you want to delete this pricing?')) {
      try {
        await apiService.delete(`pricing/${id}`);
        setPricingData(pricingData.filter((item) => item.id !== id));
      } catch (error) {
        console.error('Error deleting pricing:', error);
      }
    }
  };

  const tableColumns = [
    { key: 'id', label: 'ID', bold: true },
    { key: 'features', label: 'Features' },
    { key: 'individual', label: 'Individual Price' },
    { key: 'business', label: 'Business Price' },
    { key: 'priority', label: 'Priority' },
    // {
    //   key: 'status',
    //   label: 'Status',
    //   render: (row:any) => (row.status === 0 ? 'Active' : 'Deactive'),
    // },
    // {
    //   key: 'add_date',
    //   label: 'Added Date',
    //   render: (row:any) => new Date(row.add_date * 1000).toLocaleDateString('en-US'),
    // },
    {
      key: 'actions',
      label: 'Actions',
      actions: (row: Row) => (
        <div className="flex justify-start items-center h-full">
        <span
            onClick={() => handleEdit(row)}
            className="btn-primary px-2 py-2 mr-2 ml-3 rounded-lg cursor-pointer"
          >
            <FaEdit className="text-white" />
          </span>
          <span
            onClick={() => handleDelete(row)}
            className="btn-primary px-2 py-2 mr-2 rounded-lg cursor-pointer"
          >
            <FaTrash className="text-white" />
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className="p-4">
      <div className="header">
        <h1>Pricing Management</h1>
       
      </div>
      <DataTable data={pricingData} columns={tableColumns} loading={loading}          onAddBtnClick={handleAdd}
      />
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          header={isEditing ? 'Edit Pricing' : 'Add Pricing'}
        >
          <PricingForm
            pricing={currentPricing}
            onSave={(savedPricing: { id: any; }) => {
              if (isEditing) {
                setPricingData(pricingData.map((item) => (item.id === savedPricing.id ? savedPricing : item)));
              } else {
                setPricingData([...pricingData, savedPricing]);
              }
              setModalOpen(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default PricingManagement;
