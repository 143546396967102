import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns-tz";
import apiService from "services/apiService";



interface UserProfile {
  personalInfo: any[];
  dependents: any[];
  residencyInfo: any[];
  bankDetails: any[];
}

interface DisplayTaxProfileProps {
  regId: number| null;
  onClose: () => void;
}

const DisplayTaxProfile: React.FC<DisplayTaxProfileProps> = ({ regId, onClose }) => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  function formatDateToMMDDYYYY(dateString: string): string {
    const date = new Date(dateString);
  
    // Use UTC methods to avoid time zone conversion
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const year = date.getUTCFullYear();
  
    // return `${month}/${day}/${year}`;
    return dateString;
  }
  useEffect(() => {
    const fetchUserProfile = async () => {
      setIsLoading(true);
      try {
        const response = await apiService.get(`/tax-profiles/${regId}`);
        setUserProfile(response.data);
        console.log(response.data)
        console.log('Personal DOB : ',formatDateToMMDDYYYY(response.data.personalInfo[0].date_of_birth),' Actual Value : ',response.data.personalInfo[0].date_of_birth);
        console.log('spouse dob : ',formatDateToMMDDYYYY(response.data.personalInfo[0].spouse_dob),' Actual Value : ',response.data.personalInfo[0].spouse_dob);

      } catch (error) {
        console.error("Error fetching user profile:", error);
        toast.error("Failed to fetch user profile.");
      } finally {
        setIsLoading(false);
      }
    };
if(regId)
    fetchUserProfile();
  }, [regId]);

  const maskSSNOrItin = (value: string) => {
    // return value ? `${"*".repeat(value.length - 4)}${value.slice(-4)}` :null;
    return value;
  };


  if (isLoading) {
    return <p>Loading user profile...</p>;
  }

  if (!userProfile) {
    return    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div
      className="bg-white w-3/4 min-h-[26%] rounded-lg shadow-lg p-6 overflow-y-auto relative"
      style={{ maxHeight: "80%" }}
    >
      <button
        className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 text-2xl"
        onClick={onClose}
        aria-label="Close Modal"
      >
        &times;
      </button>
      <section className="bg-gray-100 p-4 rounded-lg shadow mt-8">
    <h3 className="text-lg font-semibold mb-2">Tax profile not found!</h3>
    </section>
      </div>
      </div>;
  }

  const { personalInfo, dependents, residencyInfo, bankDetails } = userProfile;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
  <div
    className="bg-white w-3/4 min-h-[60%] rounded-lg shadow-lg p-6 overflow-y-auto relative"
    style={{ maxHeight: "80%" }}
  >
    <button
      className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 text-2xl"
      onClick={onClose}
      aria-label="Close Modal"
    >
      &times;
    </button>
{regId?<>    <h2 className="text-2xl font-bold mb-6 text-center">
      User Profile for {personalInfo[0]?.first_name} {personalInfo[0]?.last_name}
    </h2>

    <div className="space-y-6">
      {/* Personal Information */}
      <section className="bg-gray-100 p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Personal Information</h3>
        {personalInfo.map((info) => (
          <div key={info.id} className="space-y-1">
            <p className="ml-10"><strong>Name:</strong> {info.first_name} {info.middle_name?<span>
               <strong className="ml-10">Middle Name:</strong> {info.middle_name}
            </span>:''}<strong className="ml-10">Last Name:</strong>  {info.last_name}</p>
            <p className="ml-10"><strong>Address:</strong> {`${info.street_address}, Apt ${info.apartment_number}, ${info.city}, ${info.state}, ${info.zip}`}</p>
           <div className="flex items-center justify-left">
           <p className="ml-10"><strong>SSN/ITIN:</strong> {maskSSNOrItin(info.ssn_or_itin) || "Apply For ITIN"}</p>
            <p className="ml-10"><strong>Date of Birth:</strong>
            {/* {format(new Date(info.date_of_birth), "MM/dd/yyyy", { timeZone: "Asia/Kolkata" })} */}
            {formatDateToMMDDYYYY(info.date_of_birth)}

            </p>
            <p className="ml-10"><strong>Filing Status:</strong> {info.filing_status}</p>
            </div>
            <div className="flex items-center justify-left">
            <p  className="ml-10"><strong>US Visa Type:</strong> {info.us_visa_type}</p>
            <p  className="ml-10"><strong>Date of first US Arrival:</strong> {info.us_arrival==="1970-12-31T18:30:00.000Z"?"Not FIll": formatDateToMMDDYYYY(info.us_arrival)}</p>
           </div>
            {info.spouse_first_name && (
          <>
            <h3 className="mt-4 font-medium">Spouse Information</h3>
            <p className="ml-10">
            <strong>First Name:</strong>
              {info.spouse_first_name}  {info.spouse_middle_name?<span>
                <strong className="ml-10">Middle Name:</strong> {info.spouse_middle_name}
              </span>:''}<strong className="ml-10">Last Name:</strong>  {info.spouse_last_name}
            </p>
            <div className="flex items-center justify-left">
            <p className="ml-10"><strong>Date of Birth:</strong>  {formatDateToMMDDYYYY(info.spouse_dob)}
            </p>
          <p className="ml-10"><strong>SSN/ITIN: </strong>{maskSSNOrItin(info.spouse_ssn_or_itin) || "Apply For ITIN"}</p>
          </div>
          <div className="flex items-center justify-left">
          <p  className="ml-10"><strong>US Visa Type:</strong> {info.spouse_us_visa_type}</p>
          <p  className="ml-10"><strong>Date of first US Arrival:</strong> {info.spouse_us_arrival==="1970-12-31T18:30:00.000Z"?"Not FIll":formatDateToMMDDYYYY(info.spouse_us_arrival)}</p>
          </div>
          </>
        )}
          </div>
        ))}
      </section>

      {/* Dependents */}
      <section className="bg-gray-100 p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Dependents</h3>
        {dependents.map((dependent,index) => (
          <div key={dependent.id} className="space-y-1 mt-3">
           <div className="flex justify-left">
           <p className="ml-10"><strong className="rounded-xl bg-yellow-200 py-1 px-3">{index+1}</strong><strong className="ml-10">Name:</strong> {dependent.first_name}  {dependent.middle_name?`<strong className="ml-10">Middle Name : </strong> ${dependent.middle_name}`:''}  <strong className="ml-10">Last Name:</strong> {dependent.last_name}</p>
           <p className="ml-10"><strong>Relationship:</strong> {dependent.relationship}</p>
           </div>
           <div className="flex justify-left"> <p className="ml-10"><strong>Date of Birth:</strong> 
           {formatDateToMMDDYYYY(dependent.date_of_birth)}
           {/* {format(new Date(dependent.date_of_birth), "MM/dd/yyyy", { timeZone: "Asia/Kolkata" })} */}
           </p>
           <p className="ml-10"><strong>SSN/ITIN:</strong> {maskSSNOrItin(dependent.ssn_or_itin) || "Apply For ITIN"}</p></div>
          </div>
        ))}
      </section>

      {/* Residency Information */}
      <section className="bg-gray-100 p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">State Residency Information</h3>
        {residencyInfo.map((residency,index) => (
          <div key={residency.id} className="space-y-1 mt-3 flex justify-left items-center" >
            <p className="ml-10"><strong className="rounded-xl bg-yellow-200 py-1 px-3">{index+1}</strong> <strong className="ml-10">State Residency Information of :</strong> {residency.residency_info_for}<strong className="ml-10">State:</strong> {residency.state}</p>
            <p className="ml-10">
              <strong>Residency Dates:</strong>{" "}
              {`${formatDateToMMDDYYYY(residency.residency_begin_date)} - ${formatDateToMMDDYYYY(residency.residency_end_date)}`}
            </p>
            {/* <p className="ml-10">
              <strong>Residency Info For : </strong>
              {residency.residency_info_for}
            </p> */}
          </div>
        ))}
      </section>

      {/* Bank Details */}
      <section className="bg-gray-100 p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Bank Details</h3>
        {bankDetails.map((bank) => (
          <div key={bank.id} className="space-y-1">
            <div className="flex items-center justify-left">
              <p className="ml-10"><strong>Bank Name:</strong> {bank.bank_name}</p>
              <p className="ml-10"><strong>Account Type:</strong> {bank.account_type}</p>
            </div>
            <div className="flex items-center justify-left">
              <p className="ml-10"><strong>Routing Number:</strong> {bank.routing_number}</p>
              <p className="ml-10"><strong>Account Number:</strong> {bank.account_number}</p>
            </div>
          </div>
        ))}
      </section>
      {
        personalInfo[0].remark?
        <section className="bg-gray-100 p-4 rounded-lg shadow">
          <h2 className="text-xl font-semibold">Remarks</h2>
          <p>{personalInfo[0].remark}</p>
         
        </section>:<></>
      }
    </div></>:<> <section className="bg-gray-100 p-4 rounded-lg shadow">
    <h3 className="text-lg font-semibold mb-2">Tax profile not found!</h3>
    </section></>}

  </div>
</div>

  );
};

export default DisplayTaxProfile;
