import React, { useState, useEffect } from "react";

interface DatePickerProps {
  value: string;
  onChange: (value: string) => void;
}

const DateInput: React.FC<DatePickerProps> = ({ value, onChange }) => {
  const [month, setMonth] = useState<string>("");
  const [day, setDay] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (value) {
      let formattedValue = value;
      if (value.includes("-")) {
        const [yyyy, mm, dd] = value.split("-");
        formattedValue = `${mm}/${dd}/${yyyy}`;
      }

      const [mm, dd, yyyy] = formattedValue.split("/");
      setMonth(mm || "");
      setDay(dd || "");
      setYear(yyyy || "");
    }
  }, [value]);

  const validateAndFormatDate = () => {
    const mm = parseInt(month, 10);
    const dd = parseInt(day, 10);
    const yyyy = parseInt(year, 10);

    if (
      !mm ||
      mm < 1 ||
      mm > 12 ||
      !dd ||
      dd < 1 ||
      dd > 31 ||
      !yyyy ||
      yyyy < 1000 ||
      yyyy > 9999
    ) {
      setError("Invalid date format");
      return;
    }

    const isValidDate = new Date(yyyy, mm - 1, dd).getDate() === dd;
    if (!isValidDate) {
      setError("Invalid day for the given month");
      return;
    }

    const inputDate = new Date(yyyy, mm - 1, dd);
    const today = new Date();
    if (inputDate > today) {
      setError("Date cannot be in the future");
      return;
    }

    setError("");
    onChange(`${mm.toString().padStart(2, "0")}/${dd.toString().padStart(2, "0")}/${yyyy}`);
  };

  const handleBlur = () => {
    if (month && day && year) {
      validateAndFormatDate();
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select(); // Automatically select the input content when focused
  };

  return (
    <div>
      <div
        id="dateInput"
        className="flex items-center border p-2 rounded w-48 bg-white border-black"
        style={{ gap: "0.25rem" }}
      >
       <input
          type="text"
          value={month}
          onChange={(e) => {
            const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers

            if (inputValue === "") {
              setMonth("");
            } else {
              const numericValue = parseInt(inputValue, 10);
              if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 12) {
                setMonth(inputValue.slice(0, 2)); // Restrict to 2 digits
              }
            }
          }}
          
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder="MM"
          style={{
            appearance: "none", // Remove default browser styles
            MozAppearance: "textfield", // Remove spinners in Firefox
            border: "none", // No border
            background: "transparent", // Transparent background
            outline: "none", // No focus outline
            padding: 0, // No padding
            textAlign: "center", // Center text alignment
            fontSize: "1rem", // Adjust font size
            width: "2rem", // Fixed width for input
          }}
        />

        <span>/</span>
        <input
          type="text"
          value={day}
          onChange={(e) => {
            const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers

            if (inputValue === "") {
              setDay("");
            } else {
              const numericValue = parseInt(inputValue, 10);
              if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 31) {
                setDay(inputValue.slice(0, 2)); // Restrict to 2 digits
              }
            }
          }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder="DD"
          maxLength={2}
          style={{
            appearance: "none",
            border: "none",
            background: "transparent",
            outline: "none",
            padding: 0,
            textAlign: "center",
            fontSize: "1rem",
            width: "2rem",
          }}
        />
        <span>/</span>
        <input
          type="text"
          value={year}
          onChange={(e) => setYear(e.target.value.replace(/[^0-9]/g, "").slice(0, 4))}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder="YYYY"
          maxLength={4}
          style={{
            appearance: "none",
            border: "none",
            background: "transparent",
            outline: "none",
            padding: 0,
            textAlign: "center",
            fontSize: "1rem",
            width: "3rem",
          }}
        />
      </div>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default DateInput;

