// src/pages/EmailVerification.tsx
import React, { useEffect, useState } from "react";
import apiService from "services/apiService"; // Assume apiService is an axios instance
import { useParams, useNavigate } from "react-router-dom";

const VerificationPage = () => {

  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate("/login");
  };
  useEffect(()=>{
    console.log('Verified');
    
  },[])

  return (
    <div className="flex flex-col items-center justify-center"  style={{ height: 'calc(100vh - 166px)' }}>
      <h2 className="text-xl  text-green-500">
        {'Thank you, your email verification has been done successfully! Please log in.'}</h2>
    
        <button
          onClick={handleLoginRedirect}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
        >
          Login
        </button>
     
    </div>
  );
};

export default VerificationPage;
