import React from "react";

export default function TaxServices() {
  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-4 text-left">Tax Services</h2>
      <p className="mb-6 text-justify">
        HTTaxSolutions is a dedicated & registered Electronic Return Originator (ERO) with the IRS and governed by Circular 230 of IRS Rules. We assure 100% accuracy in the tax preparation as all the tax returns are prepared only by qualified professionals. All tax returns are filed after conducting multiple quality reviews and client review and confirmation. HTTaxSolutions provides Individuals, Small Business & Corporate Tax Services, and year-round tax suggestions. An experienced Tax Analyst is available to help or suggest guidance to taxpayers on the following queries:
      </p>
      <ul className="list-disc list-inside mb-6 text-left space-y-2">
        <li>Applying ITIN</li>
        <li>Estimated Tax Filing</li>
        <li>W-4 Assistance</li>
        <li>Accounting Service</li>
        <li>Services offered with respect to Tax Filing</li>
        <li>Tax planning to reduce the tax liability</li>
        <li>Preparation of Federal and State Tax Returns</li>
        <li>E-filing / Paper Filing</li>
        <li>Filing of Amendment Tax Returns / Claim for refund</li>
        <li>Passport Certification Services</li>
        <li>Extension of Tax Returns</li>
        <li>Individual Retirement Arrangement (IRA)</li>
        <li>Auditing previous year tax return</li>
        <li>Amending the Tax Returns</li>
        <li>Filing FBAR (Foreign Bank Account Reporting)</li>
        <li>Filing FATCA (Foreign Account Tax Compliance Act)</li>
      </ul>
      <p className="text-justify">
        HTTaxSolutions provides comprehensive tax support for individuals, businesses, and corporations, ensuring clients receive accurate guidance and service throughout the tax process.
      </p>
    </div>
  );
}
