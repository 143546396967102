import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/Button";
import apiService from "services/apiService";
import Input from "components/Input";

interface FormData {
  name: string;
  email: string;
  phone_no: string;
  message: string;
}

const validationSchema = yup.object({
  name: yup.string().required("Full Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phone_no: yup.string().required("Contact Number is required"),
  message: yup.string().required("Message is required"),
});

export default function ContactUs() {
  const [submissionStatus, setSubmissionStatus] = useState<string | null>(null);

  const methods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: FormData) => {
    setSubmissionStatus(null);
    apiService
      .post("contact/submit", data)
      .then((res: any) => {
        console.log("Form submission response: ", res);
        setSubmissionStatus("Your message has been successfully sent!");
      })
      .catch((err) => {
        console.error("Error submitting form: ", err);
        setSubmissionStatus("There was an error sending your message.");
      });
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900" style={{ height: "calc(100vh - 166px)" }}>
      <div className="flex max-w-xl flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
        <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        Contact HTTax Solutions
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
           
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Input
                  name="name"
                  label="Full Name"
                  placeholder="Your First & Last Name"
                  type="text"
                />
                <Input
                  name="email"
                  label="Email Address"
                  placeholder="example@domain.com"
                  type="email"
                />
                <Input
                  name="phone_no"
                  label="Contact Number"
                  placeholder="Your Phone Number"
                  type="text"
                />
                 <Input
                  name="message"
                  label="Message"
                  placeholder="Enter Your Message"
                  type="textarea"
                />
               

                <Button type="submit">Submit</Button>
              </form>
            </FormProvider>
            {submissionStatus && (
              <div className="mt-4 text-center text-sm">
                <p className="font-medium">{submissionStatus}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
