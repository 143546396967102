import React from "react";
import "./App.css";
import "flowbite/dist/flowbite.css";
import Routers from "./routes/Routers";
import { AppContext } from "./contexts/AppContext";

function App() {
  return (
    <>
      <AppContext>
        <Routers />
      </AppContext>
    </>
  );
}

export default App;
