import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../components/Input";
import Button from "components/Button";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import appConfig from "services/apiConfig";
import { storageService } from "services/storageService";
import apiService from "services/apiService";
import { UserType } from "util/Util";

interface FormData {
  password: string;
  email: string;
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default function AdminLogin() {
  const navigate = useNavigate();
  const methods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });
  const { search } = useLocation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data: FormData) => {
    setLoading(true);
    console.log("Login Data", data);
    apiService
      .post("admin/login", data)
      .then((res:any) => {
        console.log("admin/login", res);
        navigate("/admin");
        storageService.setItem("accessToken", res?.data?.token);
        storageService.setItem('userdetails',JSON.stringify(res?.data))
        setErrorMessage(null); // Clear any previous error
      })
      .catch((err) => {
        console.log(`Login error: ${err}`);
        setErrorMessage("Invalid login credentials. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkUserLoggedIn = () => {
    const token = storageService.getItem("accessToken");
    if (token) {
      apiService
      .get("auth/validate-token")
      .then((res:any) => {
        console.log("check Admin LoggedIn res :>> ", res);
        if(res?.user_type===UserType.Admin){
          navigate("/admin");
        } else {
          storageService.removeItem("accessToken");
          storageService.removeItem("userdetails");   
          navigate("/adminlogin");
        }
      })
      .catch((err) => {
        // console.log("err", err.response.status);
        if(err?.response?.status===401){
          navigate("/adminlogin");
        }
      })
     
    } 
  };

  useEffect(() => {
    checkUserLoggedIn();
  }, [search]);

  return (
    <section className="bg-gray-50 dark:bg-gray-900 max-h-full">
      <div className="flex max-w-xl flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          HTTax Solutions Admin
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  placeholder="admin@httaxsolutions.com"
                />
                <Input
                  name="password"
                  label="Password"
                  placeholder="******"
                  type="password"
                />
                {errorMessage && (
                  <p className="text-red-600 text-sm mt-2">{errorMessage}</p>
                )}
                <Button type="submit" disabled={loading}>
                  {loading ? "Logging in..." : "Submit"}
                </Button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </section>
  );
}
