import React from "react";
import { formatTimeStamp } from "util/Util";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EmployeeDetails = ({ data }: any) => {
  console.log('EmployeeDetails:', data);



  return (
    <div className="flex flex-col space-y-4 bg-white rounded-lg shadow-md px-6 py-8">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-gray-800">{data.displayName}</h2>
        <img
          src={data.imageUrl}
          alt="Employee profile picture"
          className="w-20 h-20 rounded-full border border-gray-200 object-cover"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Email:</p>
          <p className="text-lg text-gray-700">{data.email}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">First Name:</p>
          <p className="text-lg text-gray-700">{data.first_name}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Last Name:</p>
          <p className="text-lg text-gray-700">{data.last_name}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Document Status:</p>
          <p className="text-lg text-gray-700">{data.document_status}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Last Login Time:</p>
          <p className="text-lg text-gray-700">{formatTimeStamp(data.last_login_time)}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Number of Children:</p>
          <p className="text-lg text-gray-700">{data.no_of_children}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Operator ID:</p>
          <p className="text-lg text-gray-700">{data.operator_id}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Wife's Name:</p>
          <p className="text-lg text-gray-700">{data.wife_name}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Phone Number:</p>
          <p className="text-lg text-gray-700">{data.phone_no}</p>
        </div>
      </div>
      
    </div>
  );
};

export default EmployeeDetails;
