import React, { useState } from "react";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

interface ItemsProps {
  icon?: IconType;
  label: string;
  url?: string;
}
interface SubItemsProps {
  icon?: IconType;
  text: string;
  submenuItems?: ItemsProps[];
}
function DropdownMenuItem({ icon, text, submenuItems }: SubItemsProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <li>
        <button
          type="button"
          className="flex items-center w-full p-2 text-base text-gray-600 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
          aria-controls="dropdown-example"
          data-collapse-toggle="dropdown-example"
          onClick={handleToggle}
        >
          <>
            {icon &&
              React.createElement(icon, {
                className:
                  "w-5 h-5 text-gray-600 transition duration-75 dark:text-gray-400 group-hover:text-gray-600 dark:group-hover:text-white",
                "aria-hidden": "true",
              })}
            <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
              {text}
            </span>
            {submenuItems && (
              <span className="ml-auto">
                {isOpen ? (
                  <FaAngleUp className="w-4 h-4" />
                ) : (
                  <FaAngleDown className="w-4 h-4" />
                )}
              </span>
            )}
          </>
        </button>
        <ul
          id="dropdown-example"
          className={`pl-3 space-y-3 mt-2 ${isOpen ? "" : "hidden"}`}
        >
          {submenuItems &&
            submenuItems.map((submenuItem: ItemsProps, index: number) => (
              <li key={index}>
                <Link
                  to={`/admin/${submenuItem.url}`}
                  className="flex items-center p-1 text-gray-600  rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 group"
                >
                  {submenuItem.icon?.({
                    className:
                      "w-5 h-5 text-gray-600 transition duration-75 dark:text-gray-400 group-hover:text-gray-600 dark:group-hover:text-white",
                    "aria-hidden": "true",
                  })}
                  <span className="ms-3">{submenuItem.label}</span>
                </Link>
              </li>
            ))}
        </ul>
      </li>
    </>
  );
}

export default DropdownMenuItem;
