import React, { useEffect, useState } from 'react';
import NotificationList from './NotificationList';
import SendNotification from './SendNotification';
import { storageService } from 'services/storageService';


interface NotificationsPageProps {
 
  role: 'Client' | 'Operator';  // role can either be 'User' or 'Operator'
}

const NotificationsPage: React.FC<NotificationsPageProps> = ({  role }) => {
const [userId,setUserId]=useState<any>();
useEffect(()=>{
let userdetails:any=storageService.getItem('userdetails')
console.log('userdetails ',JSON.parse(userdetails).user,JSON.parse(userdetails).user.id)
setUserId(role==='Operator'?JSON.parse(userdetails).user.id:JSON.parse(userdetails).user.reg_id)
},[userId])
  return (
<div className='pt-8 flex flex-col' style={{ height: 'calc(100vh - 50px)',marginTop:'10px' }}>
    <div className='flex-1'  style={{ height: '70%' }}>
        <NotificationList userId={userId} role={role} />
    </div>
    <div className='flex-none' style={{ height: '30%' }}>
        <SendNotification userId={userId} role={role} />
    </div>
</div>


    );
};

export default NotificationsPage;
