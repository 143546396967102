// import React, { useEffect } from "react";
// import { useForm } from "react-hook-form";
// import apiService from "services/apiService"; // Adjust the path according to your project structure

// interface AddProps {
//   onClose: () => void;
//   data: any;
// }

// const EditOperator = ({ onClose, data }: AddProps) => {
//   const {
//     register,
//     handleSubmit,
//     setValue,
//     formState: { errors },
//   } = useForm();

//   useEffect(() => {
//     if (data) {
//       setValue("operator_name", data.operator_name);
//       setValue("operator_email", data.operator_email);
//       setValue("status", data.status);
//       console.log("Operator Data : ", data);
//       // Add other fields as necessary
//     }
//   }, [data, setValue]);

//   const onSubmit = async (formData: any) => {
//     try {
//       await apiService.put(`operators/${data.operator_id}`, formData);
//       onClose();
//     } catch (error) {
//       console.error("Error updating operator:", error);
//     }
//   };

//   return (
//     <div className="p-4">
//       <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
//         <div>
//           <label className="block text-sm font-medium text-gray-700">
//             Name
//           </label>
//           <input
//             type="text"
//             {...register("operator_name", { required: "Name is required" })}
//             className={`mt-1 block w-full border rounded-md p-2 ${errors.operator_name ? "border-red-500" : "border-gray-300"}`}
//           />
//         </div>
//         <div>
//           <label className="block text-sm font-medium text-gray-700">
//             Email
//           </label>
//           <input
//             type="email"
//             {...register("operator_email", { required: "Email is required" })}
//             className={`mt-1 block w-full border rounded-md p-2 ${errors.operator_email ? "border-red-500" : "border-gray-300"}`}
//           />
//         </div>
//         <div>
//           <label className="block text-sm font-medium text-gray-700">
//             Status
//           </label>
//           <select
//             {...register("status")}
//             className="mt-1 block w-full border rounded-md p-2"
//           >
//             <option value="1">Inactive</option>
//             <option value="0">Active</option>
//           </select>
//         </div>
//         <div className="flex justify-end mt-4">
//           <button
//             type="button"
//             onClick={onClose}
//             className="mr-2 px-4 py-2 bg-gray-200 rounded-md"
//           >
//             Cancel
//           </button>
//           <button
//             type="submit"
//             className="px-4 py-2 bg-blue-600 text-white rounded-md"
//           >
//             Save
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default EditOperator;
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import apiService from "services/apiService"; // Adjust the path according to your project structure
import "react-toastify/dist/ReactToastify.css";

interface AddProps {
  onClose: () => void;
  data: any;
}

const EditOperator = ({ onClose, data }: AddProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (data) {
      setValue("operator_name", data.operator_name);
      setValue("operator_email", data.operator_email);
      setValue("status", data.status);
      setValue("password", data.password_show);
      console.log("Operator Data : ", data);
      // Add other fields as necessary
    }
  }, [data, setValue]);

  const onSubmit = async (formData: any) => {
    console.log(formData)
    try {
      await apiService.put(`operators/${data.operator_id}`, formData);
      toast.success("Operator information update successfully!");
      onClose();
    } catch (error) {
      console.error("Error updating operator:", error);
    }
  };

  return (
    <div className="p-4">
        <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            {...register("operator_name", { required: "Name is required" })}
            className={`mt-1 block w-full border rounded-md p-2 ${
              errors.operator_name ? "border-red-500" : "border-gray-300"
            }`}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            {...register("operator_email", { required: "Email is required" })}
            className={`mt-1 block w-full border rounded-md p-2 ${
              errors.operator_email ? "border-red-500" : "border-gray-300"
            }`}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              {...register("password")}
              className="mt-1 block w-full border rounded-md p-2 pr-10 border-gray-300"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500"
            >
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>
          {errors.password?.message && (
            <p className="text-red-500 text-sm mt-1">{String(errors.password.message)}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <select
            {...register("status")}
            className="mt-1 block w-full border rounded-md p-2"
          >
            <option value="1">Inactive</option>
            <option value="0">Active</option>
          </select>
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="button"
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-200 rounded-md"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-md"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditOperator;
