import React, { ReactNode } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "components/Input";
import Select from "react-select";
import apiService from "services/apiService";

const schema = yup.object().shape({
  gender: yup.string().required(),
  designation: yup.string().required(),
  displayName: yup.string().required(),
  doj: yup.string().required(),
  email: yup.string().required(),
  mobileNumber: yup.string().required(),
});

interface AddProps {
  onClose: () => void;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}
interface GenderOption {
  value: string;
  label: string;
}

export default function EditEmployee({ onClose, data }: AddProps) {
  const methods = useForm({ resolver: yupResolver(schema), defaultValues: data });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit: SubmitHandler<any> = (data: any) => {
    console.log(data);
    apiService
      .put("api/user", data)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      })
      .finally(() => {
        onClose();
      });
  };
  const genderOptions: GenderOption[] = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];
  const options = [
    { value: "Web Developer", label: "Web Developer" },
    { value: "Java Developer", label: "Java Developer" },
    { value: "Developer", label: "Developer" },
    { value: "Windows developer", label: "Windows developer" },
    { value: "Programmer", label: "Programmer" },
    { value: "Systems Analyst", label: "Systems Analyst" },
    { value: "Software engineer", label: "Software engineer" },
    { value: "Fullstack Developer", label: "Fullstack Developer" },
    { value: "HR manager", label: "HR manager" },
    { value: "Android Developer", label: "Android Developer" },
    { value: "IOS Developer", label: "iOS Developer" },
    { value: "QA Engineer", label: "QA Engineer" },
    { value: "Test Manager", label: "Test Manager" },
    { value: "Test Engineer", label: "Test Engineer" },
    { value: "Test Analyst", label: "Test Analyst" },
    { value: "Test Automation Engineer", label: "Test Automation Engineer" },
  ];
  const statusOptions: GenderOption[] = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
  ];
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid gap-4 grid-cols-2">
            <Input name="displayName" label="Full Name" placeholder={`Enter Full Name`} />
            <Input name="email" label="Email Address" placeholder={`Enter Email Address`} />
            <Input name="doj" label="Date Of Joining" type="date" />
            <Input name="mobileNumber" label="Mobile Number" placeholder={`Enter Mobile Number`} type="number" />
            <div className="mb-4">
              <label> Select Gender</label>
              <Select
                {...methods.register("gender", { required: true })}
                options={genderOptions}
                placeholder="Select Gender"
                className="w-full  rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                onChange={(selectedOption) => {
                  selectedOption && methods.setValue("gender", selectedOption.value);
                }}
                defaultValue={genderOptions.find((option) => option.value === data.gender)}
              />
              {methods.formState.errors.gender && methods.formState.errors.gender.message && (
                <span className="text-red-500 text-sm mt-1">
                  {methods.formState.errors.gender.message as ReactNode}
                </span>
              )}
            </div>

            <div className="mb-4">
              <label> Select Designation</label>
              <Select
                {...methods.register("designation", { required: true })}
                options={options}
                placeholder="Select Designation"
                className="w-full  rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                onChange={(selectedOption) => {
                  selectedOption && methods.setValue("designation", selectedOption.value);
                }}
                defaultValue={options.find((option) => option.value === data.designation)}
              />
              {methods.formState.errors.designation && methods.formState.errors.designation.message && (
                <span className="text-red-500 text-sm mt-1">
                  {methods.formState.errors.designation.message as ReactNode}
                </span>
              )}
            </div>
            <div className="mb-4">
              <label> Select Status</label>
              <Select
                {...methods.register("userStatus", { required: true })}
                options={statusOptions}
                placeholder="Select Status"
                className="w-full  rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                onChange={(selectedOption) => {
                  selectedOption && methods.setValue("userStatus", selectedOption.value);
                }}
                defaultValue={statusOptions.find((option) => option.value === data.userStatus)}
              />
              {methods.formState.errors.userStatus && methods.formState.errors.userStatus.message && (
                <span className="text-red-500 text-sm mt-1">
                  {methods.formState.errors.userStatus.message as ReactNode}
                </span>
              )}
            </div>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-500 text-white px-8 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
