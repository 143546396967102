import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../components/Input";
import Button from "components/Button";
import apiService from "services/apiService";

interface FormData {
  email: string;
}

const validationSchema = yup.object({
  email: yup.string().email("Invalid email address").required("Email is required"),
});

export default function ForgotPassword() {
  const methods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false); // Added loading state

  const onSubmit = (data: FormData) => {
    setLoading(true);
    apiService
      .post("/registrations/forgot-password", data)
      .then(() => {
        setSuccessMessage(
          "The activation link has been sent to your email address. Please verify your account within 24 hours. If not verified, your inactive account will be removed."
        );
      })
      .catch((err) => {
        console.log(`Error sending reset link: ${err}`);
      }).finally(() => {
        setLoading(false); // Set loading to false after the API call finishes
      });
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900" style={{ height: "calc(100vh - 166px)" }}>
      <div className="flex max-w-xl flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            {successMessage ? (
              <div className="text-center">
                <h2 className="flex flex-col items-center justify-center text-xl leading-tight tracking-tight text-green-600 md:text-2xl dark:text-green-400">
                  <span className="font-bold text-green-500">Password Reset Email Sent!</span>
                  <span className="text-lg text-left my-4 text-green-600">
                    {successMessage}
                  </span>
                  <span className="font-bold text-green-500">Thank you.</span>
                </h2>
              </div>
            ) : (
              <>
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Reset your password
                </h1>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Input
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="Enter your registered email"
                    />
                     <Button type="submit" disabled={loading}>
                      {loading ? "Sending..." : "Send Reset Link"}
                    </Button>
                  </form>
                </FormProvider>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
