import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OperatorDetails = ({ data }: any) => {
  return (
    <div className="flex flex-col space-y-4 bg-white rounded-lg shadow-md px-6 py-8">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-gray-800">
          {data.operator_name} {/* Display full name */}
        </h2>
        <img
          src={data.image}
          alt="Operator profile picture"
          className="w-20 h-20 rounded-full border border-gray-200 object-cover"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Email:</p>
          <p className="text-lg text-gray-700">{data.operator_email}</p>{" "}
          {/* Display email */}
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">ID:</p>
          <p className="text-lg text-gray-700">{data.operator_id}</p>{" "}
          {/* Display operator ID */}
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Username:</p>
          <p className="text-lg text-gray-700">{data.username}</p>{" "}
          {/* Display username */}
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Status:</p>
          <p className="text-lg text-gray-700">{data.status}</p>{" "}
          {/* Display status */}
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Join Date:</p>
          <p className="text-lg text-gray-700">
            {data.add_date?.slice(0, 10)}
          </p>{" "}
          {/* Display join date */}
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-medium text-gray-600">Profile Image:</p>
          <img
            src={data.image}
            alt="Operator Profile"
            className="w-32 h-32 rounded border border-gray-200 object-cover"
          />{" "}
          {/* Display image */}
        </div>
      </div>
    </div>
  );
};

export default OperatorDetails;
