import React, { useState } from "react";
import { BreadCrumb } from "components/BreadCrumb";

interface User {
  name: string;
  email: string;
  password: string;
  image: string;
}

interface ProfileDetailsProps {
  user: User;
  onUpdate: (updatedUser: User) => void;
  onImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedImage: File | null;
  isUpdating: boolean; // Add isUpdating prop
}

export default function ProfileDetails({
  user,
  onUpdate,
  onImageChange,
  isUpdating,
}: ProfileDetailsProps) {
  const [formData, setFormData] = useState<User>({
    name: user.name,
    email: user.email,
    password: user.password,
    image: user.image,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onUpdate(formData);
  };

  const BreadCrumbList = [
    { name: "profile", link: "#" },
    { name: "edit", link: "#" },
  ];

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <div className="container mx-auto py-8">
        <div className="bg-white rounded-lg overflow-hidden shadow-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Edit Profile</h2>
          <form onSubmit={handleSubmit}>
            <div className="flex items-center mb-4">
              <img
                src={formData.image}
                alt="User Image"
                className="w-20 h-20 rounded-full border-4 border-gray-300 mr-4"
              />
              <input type="file" accept="image/*" onChange={onImageChange} />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <button
              type="submit"
              className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
                isUpdating ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isUpdating} // Disable button while updating
            >
              {isUpdating ? "Updating..." : "Update"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
