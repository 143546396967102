import React, { useEffect, useState } from "react";
import DataTable, { Row } from "components/DataTable";
import apiService from "services/apiService";
import { FaEdit, FaEye } from "react-icons/fa";
import Modal from "components/Modal";

import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import ManagerDetails from "./ManagerDetails";
import AddManager from "./AddManager";
import EditManager from "./EditManager";

export default function ManagerList() {
  const [data, setData] = useState<Row[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [viewData, setViewData] = useState();
  const [addManager, setAddManager] = useState(false);
  const [editManager, setEditManager] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setLoading(true);
    apiService
      .get("managers")
      .then((res) => {
        console.log("registrations res :>> ", res);
        setData(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const tableColumns = [
    { key: "id", label: `Id`, bold: true },
    { key: "first_name", label: `${t("Managers.name")}`, bold: true },
    { key: "email", label: `${t("Managers.email")}` },
    {
      key: "phone_no",
      label: `Contact`,
      bold: true,
    },
    {
      key: "status",
      label: `User Status`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any) => {
        if (row === 1) {
          return (
            <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
              Inactive
            </span>
          );
        } else {
          return (
            <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
              Active
            </span>
          );
        }
      },
    },
    {
      key: "userStatus",
      label: `Assigned Operator`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (row: any) => {
        if (row === "ACTIVE") {
          return (
            <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
              {row}
            </span>
          );
        } else {
          return (
            <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
              {row}
            </span>
          );
        }
      },
    },
    {
      key: "phone_no",
      label: `Last Login`,
      bold: true,
    },
    {
      key: "actions",
      label: `${t("Managers.actions")}`,
      excludeExport: true,
      actions: (row: object) => (
        <div className="flex flex-start">
          <span
            onClick={() => handleEdit(row)}
            className="btn-primary px-2 py-2 mr-2 rounded-lg"
          >
            <FaEdit className="text-white" />
          </span>
          <span
            onClick={() => handleView(row)}
            className="btn-primary px-2 py-2 mr-2 rounded-lg"
          >
            <FaEye className="text-white" />
          </span>
        </div>
      ),
    },
  ];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleView = (row: any): void => {
    console.log("row", row);
    setViewData(row);
    setOpenModal(true);
  };
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleEdit = (row: any): void => {
    setViewData(row);
    setEditManager(true);
  };
  const handleAddfunction = () => {
    setAddManager(!addManager);
  };

  const BreadCrumbList = [
    { name: "Users", link: "#" },
    { name: "list", link: "#" },
  ];
  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <DataTable
        data={data}
        columns={tableColumns}
        loading={loading}
        onAddBtnClick={handleAddfunction}
      />
      {openModal && (
        <Modal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          header="View Manager Details"
        >
          {viewData && <ManagerDetails data={viewData} />}
        </Modal>
      )}
      {addManager && (
        <Modal
          isOpen={addManager}
          onClose={() => setAddManager(false)}
          header="Add Manager"
        >
          <AddManager onClose={() => setAddManager(false)} />
        </Modal>
      )}
      {editManager && (
        <Modal
          isOpen={editManager}
          onClose={() => setEditManager(false)}
          header="Edit Manager"
        >
          <EditManager onClose={() => setEditManager(false)} data={viewData} />
        </Modal>
      )}
    </>
  );
}
