import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { storageService } from 'services/storageService';
import { UserType } from 'util/Util';

const SessionExpired = () => {
  const navigate = useNavigate();
  const [loginLink,setLoginLink]=useState<any>('login')

  useEffect(() => {
    let userData=JSON.parse(storageService.getItem('userdetails')||"");
    if(userData){
      setLoginLink(parseInt(userData.user.user_type)===UserType.Admin?"adminlogin":parseInt(userData.user.user_type)===UserType.Operator?"operatorlogin":"login")
    }
     const timer = setTimeout(() => {
      navigate(`/${loginLink}`);
    }, 5000); // Redirect after 5 seconds

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Your Session has Expired
        </h2>
        <p className="text-gray-600 mb-6">
          Please log in again to continue.
        </p>
        <Link
          to={`/${loginLink}`} 
          className="text-blue-600 hover:underline font-medium"
        >
          Go to Login
        </Link>
      </div>
    </div>
  );
};

export default SessionExpired;
