import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../components/Input";
import Button from "components/Button";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { storageService } from "services/storageService";
import apiService from "services/apiService";
import { UserType } from "util/Util";

interface FormData {
  password: string;
  email: string;
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().required("password is required"),
});

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const methods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });
  const { search } = useLocation();

  const onSubmit = (data: FormData) => {
    setLoading(true);
    setErrorMessage(null);
    apiService
      .post("auth/login", data)
      .then((res:any) => {       
        console.log('Login Responce : ',res)
        storageService.setItem("accessToken", res?.data?.token);
        storageService.setItem('userdetails',JSON.stringify(res?.data))
        navigate("/user");
        setLoading(false);
      })
      .catch((err) => {
        console.log(`profile err: ${err}`);
        setLoading(false);
        setErrorMessage(
          err?.response?.data?.error 
            ? `${err.response.data.error} Please try again.` 
            : 'Invalid login details. Please try again.'
        );
      });
  };

  const checkUserLoggedIn = () => {
    const token = storageService.getItem("accessToken");
    if (token) {
      apiService
      .get("auth/validate-token")
      .then((res:any) => {
        console.log("check User LoggedIn res :>> ", res);
        if(res?.user_type===UserType.Client){
          navigate("/user");
        } else {
          storageService.removeItem("accessToken");
          storageService.removeItem("userdetails");   
          navigate("/login");
        }
      })
      .catch((err) => {
        // console.log("err", err.response.status);
        if(err?.response?.status===401){
          navigate("/login");
        }
      })
     
    } 
  };

  useEffect(() => {
    checkUserLoggedIn();
  }, [search]);

  return (
    <section className="bg-gray-50 dark:bg-gray-900 " style={{ height: 'calc(100vh - 166px)' }}>
      <div className="flex max-w-xl flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          HTTax Solutions
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  placeholder="admin@httaxsolutions.com"
                />
                <Input
                  name="password"
                  label="Password"
                  placeholder="******"
                  type="password"
                />
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="remember"
                        className="text-gray-500 dark:text-gray-300"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <a
                    href="/forgot-password"
                    className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                  >
                    Forgot password?
                  </a>
                </div>

                <Button type="submit" disabled={loading}>
                  {loading ? "Checking..." : "Submit"}
                </Button>              
                {errorMessage && (
                    <p className="text-red-500 text-sm">{errorMessage}</p>
                  )}     
                  </form>
            </FormProvider>
            <div className="mt-4">
              <button
                type="button"
                onClick={() => {
                  navigate("/signup");
                }}
                className="w-full bg-white border-2 border-solid border-opacity-0 border-l-primary-900 text-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Don&apos;t have an account?
                <span> Register Here</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
