import React from "react";

export default function OtherServices() {
  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-4 text-left">Other Services</h2>
      <p className="text-lg font-semibold mb-2">Payroll Services</p>
      <p className="text-justify">
        HTTaxSolutions also offers comprehensive payroll services to help individuals and businesses manage payroll efficiently. Our payroll services ensure timely processing, compliance with tax regulations, and accuracy in financial reporting.
      </p>
    </div>
  );
}
