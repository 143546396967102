// src/pages/operators/CreateTaxProfile.tsx

import React, { useState } from "react";
import { stateOptions } from "./Util";
import { useNavigate } from "react-router-dom";
import apiService from "../../services/apiService"; // Ensure correct path
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaTrash } from "react-icons/fa";
import DatePicker from 'react-date-picker';
import DateInput from "components/DateInput";

const nameRegex = /^[a-zA-Z\s'-]{1,35}$/;
const addressRegex = /^[a-zA-Z0-9\s,-]{1,100}$/;
const apartmentNumber = /^[a-zA-Z0-9\s,-]{1,15}$/;
const zipRegex = /^\d{5}$/;
const ssnRegex = /^\d{9}$/;
const phoneRegex = /^(\+?\d{1,4}[-\s]?)?\d{10}$/;

const validateField = (value: string | undefined, rules: string[]): string => {
  if (value === undefined || value === null) {
    return "This field is required."; // Or any default error message you prefer
  }

  for (let rule of rules) {
    switch (rule) {
      case "required":
        if (!value.trim()) return "This field is required.";
        break;
      case "name":
        if (!nameRegex.test(value))
          return "Name must only contain letters, spaces, apostrophes, or hyphens and be between 1 and 35 characters long.";
        break;
      case "address":
        if (!addressRegex.test(value))
          return "Address must be between 1 and 100 characters and can contain letters, numbers, spaces, commas, and hyphens.";
        break;
        case "apartment":
          if (!apartmentNumber.test(value))
            return "Apartment must be between 1 and 15 characters and can contain letters, numbers, spaces, commas, and hyphens.";
          break;
      case "zip":
        if (!zipRegex.test(value))
          return "Invalid zip code format. Must be exactly 5 digits.";
        break;
      case "ssn":
        if (!ssnRegex.test(value))
          return "Invalid SSN format. Must be exactly 9 digits.";
        break;
      case "routing":
          if (!ssnRegex.test(value))
            return "Invalid Routing number. Must be exactly 9 digits.";
          break;
      case "phone":
        if (!phoneRegex.test(value)) return "Invalid phone number format.";
        break;
      case "email":
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(value)) return "Invalid email format.";
        break;
      case "date":
        if (isNaN(Date.parse(value))) return "Invalid date format.";
        break;
      case "number":
        const numValue = Number(value);
        if (isNaN(numValue) || value.trim() === "")
          return "This field must be a valid number.";
        break;
      default:
        break;
    }
  }

  return ""; // Return an empty string when no validation error
};

const validateTaxProfile = (taxProfile: any) => {
  const errors: { [key: string]: string } = {};

  // Validate personalInfo
  errors.firstName = validateField(taxProfile.personalInfo.firstName, [
    "required",
    "name",
  ]);
  errors.middleName = taxProfile.personalInfo.middleName
    ? validateField(taxProfile.personalInfo.middleName, ["name"])
    : ""; // Optional
  errors.lastName = validateField(taxProfile.personalInfo.lastName, [
    "required",
    "name",
  ]);
  const shouldValidateSSN = !taxProfile.personalInfo.applyForITIN;

  if (shouldValidateSSN) {
    errors.ssnOrItin = validateField(taxProfile.personalInfo.ssnOrItin, [
      "required",
      "ssn",
    ]);
  }

  errors.dateOfBirth = validateField(taxProfile.personalInfo.dateOfBirth, [
    "required",
    "date",
  ]);
  errors.filingStatus = validateField(taxProfile.personalInfo.filingStatus, [
    "required",
  ]);

  // Validate spouseInfo only when filingStatus is "Married" or "Widower"
  if (
    taxProfile.personalInfo.filingStatus === "Married" ||
    taxProfile.personalInfo.filingStatus === "Widower"
  ) {
    errors.spouseFirstName = validateField(
      taxProfile.personalInfo.spouseInfo.firstName,
      ["required", "name"]
    );
    errors.spouseMiddleName = taxProfile.personalInfo.spouseInfo.middleName
      ? validateField(taxProfile.personalInfo.spouseInfo.middleName, ["name"])
      : ""; // Optional
    errors.spouseLastName = validateField(
      taxProfile.personalInfo.spouseInfo.lastName,
      ["required", "name"]
    );
    errors.spouseDateOfBirth = validateField(
      taxProfile.personalInfo.spouseInfo.dateOfBirth,
      ["required", "date"]
    );
    const spouseInfoSsnOrItin=!taxProfile.personalInfo?.spouseInfo?.applyForITIN
    if(spouseInfoSsnOrItin)
    errors.spouseSsnOrItin = validateField(
      taxProfile.personalInfo.spouseInfo.ssnOrItin,
      ["required", "ssn"]
    );
  }

  // Validate address
  errors.streetAddress = validateField(
    taxProfile.personalInfo.address.streetAddress,
    ["required"]
  );
  // errors.apartmentNumber = taxProfile.personalInfo.address.apartmentNumber
  //   ? validateField(taxProfile.personalInfo.address.apartmentNumber, ["number"])
  //   : ""; // Optional
  errors.city = validateField(taxProfile.personalInfo.address.city, [
    "required",
  ]);
  errors.state = validateField(taxProfile.personalInfo.address.state, [
    "required",
  ]);
  errors.zip = validateField(taxProfile.personalInfo.address.zip, [
    "required",
    "zip",
  ]);

  // Validate dependents
  taxProfile.dependents.forEach((dependent: any, index: number) => {
    errors[`dependent_${index}_firstName`] = validateField(
      dependent.firstName,
      ["required", "name"]
    );
    errors[`dependent_${index}_lastName`] = validateField(dependent.lastName, [
      "required",
      "name",
    ]);
    errors[`dependent_${index}_dateOfBirth`] = validateField(
      dependent.dateOfBirth,
      ["required", "date"]
    );
    errors[`dependent_${index}_relationship`] = validateField(
      dependent.relationship,
      ["required"]
    );
    const dependentSsnOrItin=!dependent.applyForITIN;
    if(dependentSsnOrItin)
    errors[`dependent_${index}_ssnOrItin`] = validateField(
      dependent.ssnOrItin,
      ["required", "ssn"]
    );
  });
  errors.residency=taxProfile.residencyInfo.length>0?"":"At least one residency information entry is mandatory"
  // Validate residencyInfo
  taxProfile.residencyInfo.forEach((residency: any, index: number) => {
    errors[`residency_${index}_state`] = validateField(residency.state, [
      "required",
    ]);
    errors[`residency_${index}_startDate`] = validateField(
      residency.residencyBeginDate,
      ["required", "date"]
    );
    errors[`residency_${index}_endDate`] = validateField(
      residency.residencyEndDate,
      ["required", "date"]
    );

    // If residencyBeginDate is after residencyEndDate, it's an invalid range
    if (residency.residencyBeginDate && residency.residencyEndDate) {
      const residencyBeginDate = new Date(residency.residencyBeginDate);
      const residencyEndDate = new Date(residency.residencyEndDate);
      if (residencyBeginDate > residencyEndDate) {
        errors[`residency_${index}_dateRange`] =
          "Start date cannot be after end date.";
      }
    }
  });

  // Validate bankDetails
  errors.bankName = taxProfile.bankDetails.bankName?validateField(taxProfile.bankDetails.bankName, [
    "required","name"
  ]):"";
  errors.routingNumber = taxProfile.bankDetails.routingNumber?validateField(taxProfile.bankDetails.routingNumber, [
    "required","routing","number"
  ]):"";
  errors.accountNumber = taxProfile.bankDetails.accountNumber?validateField(taxProfile.bankDetails.accountNumber, [
    "required","number"
  ]):"";
  errors.accountType = taxProfile.bankDetails.accountType?validateField(taxProfile.bankDetails.accountType, [
    "required",
  ]):"";

  Object.keys(errors).forEach((key) => {
    if (!errors[key]) {
      delete errors[key];
    }
  });

  return errors;
};

interface Dependent {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  ssnOrItin: string;
  relationship: string;
  applyForITIN: boolean;
}

interface ResidencyInfo {
  state: string;
  residencyBeginDate: string;
  residencyEndDate: string;
  residencyInfoFor:string;
}

interface BankDetails {
  bankName: string;
  accountType: string;
  routingNumber: string;
  accountNumber: string;
}

interface TaxProfile {
  personalInfo: {
    regID: string;
    firstName: string;
    middleName: string;
    lastName: string;
    address: {
      streetAddress: string;
      apartmentNumber: string;
      city: string;
      state: string;
      zip: string;
    };
    ssnOrItin: string;
    applyForITIN: boolean;
    dateOfBirth: string;
    filingStatus: string;
    usVisaType?:string;
    usArrival?:string;
    remark?:string;
    spouseInfo?: {
      firstName: string;
      middleName: string;
      lastName: string;
      dateOfBirth: string;
      ssnOrItin: string;
      applyForITIN: boolean;
      usVisaType?:string;
      usArrival?:string;
      dateOfDeath?: string;      
    };
  };
  dependents: Dependent[];
  residencyInfo: ResidencyInfo[];
  bankDetails: BankDetails;
}

const CreateTaxProfile: React.FC = () => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [taxProfile, setTaxProfile] = useState<TaxProfile>({
    personalInfo: {
      regID: "",
      firstName: "",
      middleName: "",
      lastName: "",
      address: {
        streetAddress: "",
        apartmentNumber: "",
        city: "",
        state: "",
        zip: "",
      },
      ssnOrItin: "",
      applyForITIN: false,
      dateOfBirth: "",
      filingStatus: "",
      usVisaType:"",
      usArrival:"",  
      remark:"",
      spouseInfo: {
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        ssnOrItin: "",
        usVisaType:"",
        usArrival:"",  
        applyForITIN: false,
      },
    },
    dependents: [],
    residencyInfo: [],
    bankDetails: {
      bankName: "",
      accountType: "Checking",
      routingNumber: "",
      accountNumber: "",
    },
  });

  const navigate = useNavigate(); // Hook from React Router to handle navigation

  const [dependent, setDependent] = useState<Dependent>({
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    ssnOrItin: "",
    relationship: "",
    applyForITIN: false,
  });

  const [stateResidency, setStateResidency] = useState<ResidencyInfo>({
    state: "",
    residencyBeginDate: "",
    residencyEndDate: "",
    residencyInfoFor:"",
  });

  const handleAddDependent = () => {
    setTaxProfile({
      ...taxProfile,
      dependents: [...taxProfile.dependents, dependent],
    });
    setDependent({
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      ssnOrItin: "",
      relationship: "",
      applyForITIN: false,
    });
  };

  const handleAddResidencyInfo = () => {
    setTaxProfile({
      ...taxProfile,
      residencyInfo: [...taxProfile.residencyInfo, stateResidency],
    });
    setStateResidency({
      state: "",
      residencyBeginDate: "",
      residencyEndDate: "",
      residencyInfoFor:""
    });
  };
  const formatDate = (date: string) => {
    const [month, day, year] = date.split("/");
    return `${year}-${month}-${day}`;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = validateTaxProfile(taxProfile);
    setErrors(validationErrors);
    setIsSubmitting(true);
    // If no errors, submit the form
    if (Object.keys(validationErrors).length === 0) {
      try {
        let userDetails: any = window.localStorage.getItem("userdetails");
        let userRegId = JSON.parse(userDetails)?.user?.reg_id;
        taxProfile.personalInfo.regID = userRegId;
    
        const payload: TaxProfile = {
          ...taxProfile,
          personalInfo: {
            ...taxProfile.personalInfo,
            dateOfBirth: (taxProfile.personalInfo.dateOfBirth),          
            usArrival:(taxProfile.personalInfo.usArrival||""),
            spouseInfo: {
              ...taxProfile.personalInfo.spouseInfo,
              firstName: taxProfile.personalInfo.spouseInfo?.firstName || "",
              middleName: taxProfile.personalInfo.spouseInfo?.middleName || "",
              lastName: taxProfile.personalInfo.spouseInfo?.lastName || "",
              ssnOrItin: taxProfile.personalInfo.spouseInfo?.ssnOrItin || "",
              applyForITIN: taxProfile.personalInfo.spouseInfo?.applyForITIN ?? false,
              dateOfBirth: (taxProfile.personalInfo.spouseInfo?.dateOfBirth || ""),
              usArrival:(taxProfile.personalInfo.spouseInfo?.usArrival||""),
            },
          },
          dependents: taxProfile.dependents.map(dependent => ({
            ...dependent,
            dateOfBirth: (dependent.dateOfBirth),
          })),
          residencyInfo: taxProfile.residencyInfo.map(residency => ({
            ...residency,
            residencyBeginDate: (residency.residencyBeginDate),
            residencyEndDate: (residency.residencyEndDate),
            residencyInfoFor:residency.residencyInfoFor,
          })),
        };

      console.log("User Profile Data (Formatted):", taxProfile,payload);


        const response = await apiService.post("/tax-profiles", payload);
        toast.success("Tax profile created successfully!");
        setTimeout(()=>{         
          setIsSubmitting(false);
          navigate("/user");
        },5000)
       
      } catch (error: any) {
        setIsSubmitting(false);
        console.error("Error creating tax profile:", error);
        if (error.response?.status === 401) {
          navigate("/session-expired");
        } else
          toast.error(
            error.response?.data?.message || "Failed to create tax profile."
          );
      }
      console.log("Tax profile is valid, submitting form...");
    }
    setIsSubmitting(false);
    console.log(
      "validationErrors ",
      validationErrors,
      Object.keys(validationErrors),
      Object.values(validationErrors)
    );
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 p-2 max-w-4xl mx-auto">
      {/* Personal Information Section */}
      <div className="space-y-4">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="text-xl font-bold mb-4 mt-12 bg-blue-500 w-64 center text-white py-2">
            Create Tax Profile
          </h2>
        </div>
        <div className="flex space-x-4 flex-col bg-gray-100 p-2">
        <h2 className="text-xl font-bold">Personal Information</h2>
        <div className="flex space-x-4">
          <div className="flex flex-col flex-1">
            <label htmlFor="firstName" className="font-medium">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              value={taxProfile.personalInfo.firstName}
              onChange={(e) =>
                setTaxProfile({
                  ...taxProfile,
                  personalInfo: {
                    ...taxProfile.personalInfo,
                    firstName: e.target.value,
                  },
                })
              }
              className="border p-2 rounded"
            />
            {errors.firstName && (
              <div style={{ color: "red" }}>{errors.firstName}</div>
            )}
          </div>
          <div className="flex flex-col flex-1">
            <label htmlFor="middleName" className="font-medium">
              Middle Name
            </label>
            <input
              type="text"
              id="middleName"
              value={taxProfile.personalInfo.middleName}
              onChange={(e) =>
                setTaxProfile({
                  ...taxProfile,
                  personalInfo: {
                    ...taxProfile.personalInfo,
                    middleName: e.target.value,
                  },
                })
              }
              className="border p-2 rounded"
            />
            {errors.middleName && (
              <div style={{ color: "red" }}>{errors.middleName}</div>
            )}
          </div>
          <div className="flex flex-col flex-1">
            <label htmlFor="lastName" className="font-medium">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              value={taxProfile.personalInfo.lastName}
              onChange={(e) =>
                setTaxProfile({
                  ...taxProfile,
                  personalInfo: {
                    ...taxProfile.personalInfo,
                    lastName: e.target.value,
                  },
                })
              }
              className="border p-2 rounded"
            />
            {errors.lastName && (
              <div style={{ color: "red" }}>{errors.lastName}</div>
            )}
          </div>
        </div>
       
        {/* Address Fields */}
        <div className="space-y-4">
          <div className="flex space-x-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="dateOfBirth" className="font-medium">
                Date of Birth (MM/DD/YYYY)
              </label>
              
              <DateInput
                value={taxProfile.personalInfo.dateOfBirth}
                onChange={(date) =>
                  setTaxProfile({
                    ...taxProfile,
                    personalInfo: {
                      ...taxProfile.personalInfo,
                      dateOfBirth: date,
                    },
                  })
                }
              />
              {errors.dateOfBirth && (
                <div style={{ color: "red" }}>{errors.dateOfBirth}</div>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="ssnOrItin" className="font-medium">
                SSN/ITIN
              </label>
              <input
                type="text"
                id="ssnOrItin"
                disabled={taxProfile.personalInfo.applyForITIN}
                value={taxProfile.personalInfo.ssnOrItin}
                onChange={(e) =>
                  setTaxProfile({
                    ...taxProfile,
                    personalInfo: {
                      ...taxProfile.personalInfo,
                      ssnOrItin: e.target.value,
                    },
                  })
                }
                className={`border p-2 rounded ${
                  taxProfile.personalInfo.applyForITIN
                    ? "bg-gray-200 text-gray-600 cursor-not-allowed opacity-70"
                    : "bg-white text-black"
                }`}
              />
              {errors.ssnOrItin && (
                <div style={{ color: "red" }}>{errors.ssnOrItin}</div>
              )}
            </div>
            <div className="flex flex-col flex-1 mb-2">
              <label htmlFor={`personalApplyForITIN`} className="font-medium">
                Apply for ITIN
              </label>
              <input
                type="checkbox"
                id={`personalApplyForITIN`}
                checked={taxProfile.personalInfo.applyForITIN}
                onChange={(e) => {
                  setTaxProfile({
                    ...taxProfile,
                    personalInfo: {
                      ...taxProfile.personalInfo,
                      applyForITIN: e.target.checked,
                    },
                  });
                }}
                className="form-checkbox ml-2 mt-2"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="filingStatus" className="font-medium">
              Marital Status on December 31
            </label>
            <select
              id="filingStatus"
              value={taxProfile.personalInfo.filingStatus}
              onChange={(e) =>
                setTaxProfile({
                  ...taxProfile,
                  personalInfo: {
                    ...taxProfile.personalInfo,
                    filingStatus: e.target.value,
                  },
                })
              }
              className="border p-2 rounded"
            >
              <option value="">Select Status</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Widower">Widower</option>
            </select>
            {errors.filingStatus && (
              <div style={{ color: "red" }}>{errors.filingStatus}</div>
            )}
          </div>
          {taxProfile.personalInfo.filingStatus === "Married" ? (
            <div className="space-y-4 bg-gray-200 p-4">
              <h3 className="text-xl font-semibold">Spouse Information</h3>
              <div className="flex space-x-4">
                <div className="flex flex-col flex-1">
                  <label htmlFor="spouseFirstName" className="font-medium">
                    Spouse First Name
                  </label>
                  <input
                    type="text"
                    id="spouseFirstName"
                    value={taxProfile.personalInfo.spouseInfo?.firstName || ""}
                    onChange={(e) =>
                      setTaxProfile({
                        ...taxProfile,
                        personalInfo: {
                          ...taxProfile.personalInfo,
                          spouseInfo: {
                            ...taxProfile.personalInfo.spouseInfo!,
                            firstName: e.target.value,
                          },
                        },
                      })
                    }
                    className="border p-2 rounded"
                  />
                </div>
                <div className="flex flex-col flex-1">
                  <label htmlFor="spouseMiddleName" className="font-medium">
                    Spouse Middle Name
                  </label>
                  <input
                    type="text"
                    id="spouseMiddleName"
                    value={taxProfile.personalInfo.spouseInfo?.middleName || ""}
                    onChange={(e) =>
                      setTaxProfile({
                        ...taxProfile,
                        personalInfo: {
                          ...taxProfile.personalInfo,
                          spouseInfo: {
                            ...taxProfile.personalInfo.spouseInfo!,
                            middleName: e.target.value,
                          },
                        },
                      })
                    }
                    className="border p-2 rounded"
                  />
                </div>
                <div className="flex flex-col flex-1">
                  <label htmlFor="spouseLastName" className="font-medium">
                    Spouse Last Name
                  </label>
                  <input
                    type="text"
                    id="spouseLastName"
                    value={taxProfile.personalInfo.spouseInfo?.lastName || ""}
                    onChange={(e) =>
                      setTaxProfile({
                        ...taxProfile,
                        personalInfo: {
                          ...taxProfile.personalInfo,
                          spouseInfo: {
                            ...taxProfile.personalInfo.spouseInfo!,
                            lastName: e.target.value,
                          },
                        },
                      })
                    }
                    className="border p-2 rounded"
                  />
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="flex flex-col flex-1">
                  <label htmlFor="spouseDateOfBirth" className="font-medium">
                    Spouse Date of Birth (MM/DD/YYYY)
                  </label>
                  <DateInput
                    value={
                      taxProfile.personalInfo.spouseInfo?.dateOfBirth || ""
                    }
                    onChange={(e) =>
                      setTaxProfile({
                        ...taxProfile,
                        personalInfo: {
                          ...taxProfile.personalInfo,
                          spouseInfo: {
                            ...taxProfile.personalInfo.spouseInfo!,
                            dateOfBirth: e,
                          },
                        },
                      })
                    }
                  />
                </div>
                <div className="flex flex-col flex-1">
                  <label htmlFor="spouseSSN" className="font-medium">
                    Spouse SSN/ITIN
                  </label>
                  <input
                    type="text"
                    id="spouseSSN"
                    value={taxProfile.personalInfo.spouseInfo?.ssnOrItin || ""}
                    disabled={taxProfile.personalInfo.spouseInfo?.applyForITIN}
                    onChange={(e) =>
                      setTaxProfile({
                        ...taxProfile,
                        personalInfo: {
                          ...taxProfile.personalInfo,
                          spouseInfo: {
                            ...taxProfile.personalInfo.spouseInfo!,
                            ssnOrItin: e.target.value,
                          },
                        },
                      })
                    }
                    className={`border p-2 rounded ${
                      taxProfile.personalInfo.spouseInfo?.applyForITIN
                        ? "bg-gray-200 text-gray-600 cursor-not-allowed opacity-70"
                        : "bg-white text-black"
                    }`}
                  />
                   {errors.spouseSsnOrItin && (
                <div style={{ color: "red" }}>{errors.spouseSsnOrItin}</div>
              )}
                </div>
                <div className="flex flex-col flex-1">
                  <label htmlFor={`spouseApplyForITIN`} className="font-medium">
                    Apply for ITIN
                  </label>
                  <input
                    type="checkbox"
                    id={`spouseApplyForITIN`}
                    checked={taxProfile.personalInfo.spouseInfo?.applyForITIN}
                    onChange={(e) => {
                      setTaxProfile({
                        ...taxProfile,
                        personalInfo: {
                          ...taxProfile.personalInfo,
                          spouseInfo: {
                            ...taxProfile.personalInfo.spouseInfo!,
                            applyForITIN: e.target.checked,
                          },
                        },
                      });
                    }}
                    className="form-checkbox ml-2 mt-2"
                  />
                </div>
              </div>
              <div className="flex space-x-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="apartmentNumber" className="font-medium">
              Spouse US Visa Type (If applicable)
              </label>
              <input
                type="text"
                id="usVisaType"
                value={taxProfile.personalInfo.spouseInfo?.usVisaType}
                onChange={(e) =>
                  setTaxProfile({
                    ...taxProfile,
                    personalInfo: {
                      ...taxProfile.personalInfo,
                      spouseInfo: {
                        ...taxProfile.personalInfo.spouseInfo!,
                        usVisaType: e.target.value
                      },
                     
                    },
                  })
                }
                className="border p-2 rounded"
              />            
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="city" className="font-medium">
              Spouse Date of first US Arrival (Optional) (MM/DD/YYYY)
              </label>
              <DateInput
                    value={
                      taxProfile.personalInfo.spouseInfo?.usArrival || ""
                    }
                    onChange={(e) =>
                      setTaxProfile({
                        ...taxProfile,
                        personalInfo: {
                          ...taxProfile.personalInfo,
                          spouseInfo: {
                            ...taxProfile.personalInfo.spouseInfo!,
                            usArrival:e
                          },                         
                        },
                      })
                    }
                  />
             
            </div>
          </div>
            </div>
          ) : (
            taxProfile.personalInfo.filingStatus === "Widower" && (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Spouse Information</h3>
                <div className="flex space-x-4">
                  <div className="flex flex-col flex-1">
                    <label htmlFor="spouseFirstName" className="font-medium">
                      Spouse First Name
                    </label>
                    <input
                      type="text"
                      id="spouseFirstName"
                      value={
                        taxProfile.personalInfo.spouseInfo?.firstName || ""
                      }
                      onChange={(e) =>
                        setTaxProfile({
                          ...taxProfile,
                          personalInfo: {
                            ...taxProfile.personalInfo,
                            spouseInfo: {
                              ...taxProfile.personalInfo.spouseInfo!,
                              firstName: e.target.value,
                            },
                          },
                        })
                      }
                      className="border p-2 rounded"
                    />
                  </div>
                  <div className="flex flex-col flex-1">
                    <label htmlFor="spouseMiddleName" className="font-medium">
                      Spouse Middle Name
                    </label>
                    <input
                      type="text"
                      id="spouseMiddleName"
                      value={
                        taxProfile.personalInfo.spouseInfo?.middleName || ""
                      }
                      onChange={(e) =>
                        setTaxProfile({
                          ...taxProfile,
                          personalInfo: {
                            ...taxProfile.personalInfo,
                            spouseInfo: {
                              ...taxProfile.personalInfo.spouseInfo!,
                              middleName: e.target.value,
                            },
                          },
                        })
                      }
                      className="border p-2 rounded"
                    />
                  </div>
                  <div className="flex flex-col flex-1">
                    <label htmlFor="spouseLastName" className="font-medium">
                      Spouse Last Name
                    </label>
                    <input
                      type="text"
                      id="spouseLastName"
                      value={taxProfile.personalInfo.spouseInfo?.lastName || ""}
                      onChange={(e) =>
                        setTaxProfile({
                          ...taxProfile,
                          personalInfo: {
                            ...taxProfile.personalInfo,
                            spouseInfo: {
                              ...taxProfile.personalInfo.spouseInfo!,
                              lastName: e.target.value,
                            },
                          },
                        })
                      }
                      className="border p-2 rounded"
                    />
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="flex flex-col flex-1">
                    <label htmlFor="spouseDateOfBirth" className="font-medium">
                      Spouse Date of Birth (MM/DD/YYYY)
                    </label>
                    <DateInput
                      value={
                        taxProfile.personalInfo.spouseInfo?.dateOfBirth || ""
                      }
                      onChange={(e) =>
                        setTaxProfile({
                          ...taxProfile,
                          personalInfo: {
                            ...taxProfile.personalInfo,
                            spouseInfo: {
                              ...taxProfile.personalInfo.spouseInfo!,
                              dateOfBirth: e,
                            },
                          },
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-col flex-1">
                    <label htmlFor="spouseSSN" className="font-medium">
                      Spouse SSN/ITIN
                    </label>
                    <input
                      type="text"
                      id="spouseSSN"
                      value={
                        taxProfile.personalInfo.spouseInfo?.ssnOrItin || ""
                      }
                      disabled={
                        taxProfile.personalInfo.spouseInfo?.applyForITIN
                      }
                      onChange={(e) =>
                        setTaxProfile({
                          ...taxProfile,
                          personalInfo: {
                            ...taxProfile.personalInfo,
                            spouseInfo: {
                              ...taxProfile.personalInfo.spouseInfo!,
                              ssnOrItin: e.target.value,
                            },
                          },
                        })
                      }
                      className={`border p-2 rounded ${
                        taxProfile.personalInfo.spouseInfo?.applyForITIN
                          ? "bg-gray-200 text-gray-600 cursor-not-allowed opacity-70"
                          : "bg-white text-black"
                      }`}
                    />
                  </div>
                  <div className="flex flex-col flex-1">
                    <label
                      htmlFor={`spouseApplyForITIN`}
                      className="font-medium"
                    >
                      Apply for ITIN
                    </label>
                    <input
                      type="checkbox"
                      id={`spouseApplyForITIN`}
                      checked={taxProfile.personalInfo.spouseInfo?.applyForITIN}
                      onChange={(e) => {
                        setTaxProfile({
                          ...taxProfile,
                          personalInfo: {
                            ...taxProfile.personalInfo,
                            spouseInfo: {
                              ...taxProfile.personalInfo.spouseInfo!,
                              applyForITIN: e.target.checked,
                            },
                          },
                        });
                      }}
                      className="form-checkbox ml-2 mt-2"
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <label htmlFor="spouseDateOfDeath" className="font-medium">
                    Spouse Date of Death
                  </label>
                  <DateInput
                    value={
                      taxProfile.personalInfo.spouseInfo?.dateOfDeath || ""
                    }
                    onChange={(e) =>
                      setTaxProfile({
                        ...taxProfile,
                        personalInfo: {
                          ...taxProfile.personalInfo,
                          spouseInfo: {
                            ...taxProfile.personalInfo.spouseInfo!,
                            dateOfDeath: e,
                          },
                        },
                      })
                    }
                  />
                </div>
              </div>
            )
          )}
          <div className="flex flex-col">
            <label htmlFor="streetAddress" className="font-medium">
              Street Address
            </label>
            <input
              type="text"
              id="streetAddress"
              value={taxProfile.personalInfo.address.streetAddress}
              onChange={(e) =>
                setTaxProfile({
                  ...taxProfile,
                  personalInfo: {
                    ...taxProfile.personalInfo,
                    address: {
                      ...taxProfile.personalInfo.address,
                      streetAddress: e.target.value,
                    },
                  },
                })
              }
              className="border p-2 rounded"
            />
            {errors.streetAddress && (
              <div style={{ color: "red" }}>{errors.streetAddress}</div>
            )}
          </div>
          <div className="flex space-x-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="apartmentNumber" className="font-medium">
                Apartment Number
              </label>
              <input
                type="text"
                id="apartmentNumber"
                value={taxProfile.personalInfo.address.apartmentNumber}
                onChange={(e) =>
                  setTaxProfile({
                    ...taxProfile,
                    personalInfo: {
                      ...taxProfile.personalInfo,
                      address: {
                        ...taxProfile.personalInfo.address,
                        apartmentNumber: e.target.value,
                      },
                    },
                  })
                }
                className="border p-2 rounded"
              />
              {/* {errors.apartmentNumber && (
                <div style={{ color: "red" }}>{errors.apartmentNumber}</div>
              )} */}
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="city" className="font-medium">
                City
              </label>
              <input
                type="text"
                id="city"
                value={taxProfile.personalInfo.address.city}
                onChange={(e) =>
                  setTaxProfile({
                    ...taxProfile,
                    personalInfo: {
                      ...taxProfile.personalInfo,
                      address: {
                        ...taxProfile.personalInfo.address,
                        city: e.target.value,
                      },
                    },
                  })
                }
                className="border p-2 rounded"
              />
              {errors.city && <div style={{ color: "red" }}>{errors.city}</div>}
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="state" className="font-medium">
                State
              </label>
              <select
                id="state"
                value={taxProfile.personalInfo.address.state}
                onChange={(e) =>
                  setTaxProfile({
                    ...taxProfile,
                    personalInfo: {
                      ...taxProfile.personalInfo,
                      address: {
                        ...taxProfile.personalInfo.address,
                        state: e.target.value,
                      },
                    },
                  })
                }
                className="border p-2 rounded"
              >
                <option value="">Select State</option>
                {stateOptions.map((state) => (
                  <option key={state.name} value={state.code}>
                    {state.name}
                  </option>
                ))}
              </select>
              {errors.state && (
                <div style={{ color: "red" }}>{errors.state}</div>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="zip" className="font-medium">
                Zip Code
              </label>
              <input
                type="text"
                id="zip"
                value={taxProfile.personalInfo.address.zip}
                onChange={(e) =>
                  setTaxProfile({
                    ...taxProfile,
                    personalInfo: {
                      ...taxProfile.personalInfo,
                      address: {
                        ...taxProfile.personalInfo.address,
                        zip: e.target.value,
                      },
                    },
                  })
                }
                className="border p-2 rounded"
              />
              {errors.zip && <div style={{ color: "red" }}>{errors.zip}</div>}
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="apartmentNumber" className="font-medium">
              US Visa Type  (If applicable)
              </label>
              <input
                type="text"
                id="usVisaType"
                value={taxProfile.personalInfo.usVisaType}
                onChange={(e) =>
                  setTaxProfile({
                    ...taxProfile,
                    personalInfo: {
                      ...taxProfile.personalInfo,
                      usVisaType: e.target.value
                    },
                  })
                }
                className="border p-2 rounded"
              />            
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="city" className="font-medium">
              Date of first US Arrival (Optional) (MM/DD/YYYY)
              </label>
              <DateInput
                    value={
                      taxProfile.personalInfo.usArrival || ""
                    }
                    onChange={(e) =>
                      setTaxProfile({
                        ...taxProfile,
                        personalInfo: {
                          ...taxProfile.personalInfo,
                          usArrival:e
                        },
                      })
                    }
                  />
             
            </div>
          </div>
        </div>
      </div>
      </div>
      {/* Add Dependents Section */}
      <div className="space-y-4">
        <h2 className="text-xl font-bold">Add Dependents</h2>
        {taxProfile.dependents.map((dep, index) => (
          <div key={index} className="border p-4 rounded bg-gray-100">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">Dependent {index + 1}</h3>
              <span
                onClick={() => {
                  const updatedDependents = taxProfile.dependents.filter(
                    (_, i) => i !== index
                  );
                  setTaxProfile({
                    ...taxProfile,
                    dependents: updatedDependents,
                  });
                }}
                className="btn-primary px-2 py-2 rounded-lg cursor-pointer hover:bg-red-600"
              >
                <FaTrash className="text-white" />
              </span>
            </div>
            <div className="flex space-x-4">
              {/* First Name Field */}
              <div className="flex flex-col flex-1">
                <label htmlFor={`depFirstName${index}`} className="font-medium">
                  First Name
                </label>
                <input
                  type="text"
                  id={`depFirstName${index}`}
                  value={dep.firstName}
                  onChange={(e) => {
                    const updatedDependents = [...taxProfile.dependents];
                    updatedDependents[index] = {
                      ...updatedDependents[index],
                      firstName: e.target.value,
                    };
                    setTaxProfile({
                      ...taxProfile,
                      dependents: updatedDependents,
                    });
                  }}
                  className="border p-2 rounded"
                />
                {/* First Name Error Message */}
                {errors[`dependent_${index}_firstName`] && (
                  <span className="text-red-500 text-sm">
                    {errors[`dependent_${index}_firstName`]}
                  </span>
                )}
              </div>

              {/* Middle Name Field */}
              <div className="flex flex-col flex-1">
                <label
                  htmlFor={`depMiddleName${index}`}
                  className="font-medium"
                >
                  Middle Name
                </label>
                <input
                  type="text"
                  id={`depMiddleName${index}`}
                  value={dep.middleName}
                  onChange={(e) => {
                    const updatedDependents = [...taxProfile.dependents];
                    updatedDependents[index] = {
                      ...updatedDependents[index],
                      middleName: e.target.value,
                    };
                    setTaxProfile({
                      ...taxProfile,
                      dependents: updatedDependents,
                    });
                  }}
                  className="border p-2 rounded"
                />
              </div>

              {/* Last Name Field */}
              <div className="flex flex-col flex-1">
                <label htmlFor={`depLastName${index}`} className="font-medium">
                  Last Name
                </label>
                <input
                  type="text"
                  id={`depLastName${index}`}
                  value={dep.lastName}
                  onChange={(e) => {
                    const updatedDependents = [...taxProfile.dependents];
                    updatedDependents[index] = {
                      ...updatedDependents[index],
                      lastName: e.target.value,
                    };
                    setTaxProfile({
                      ...taxProfile,
                      dependents: updatedDependents,
                    });
                  }}
                  className="border p-2 rounded"
                />
                {/* Last Name Error Message */}
                {errors[`dependent_${index}_lastName`] && (
                  <span className="text-red-500 text-sm">
                    {errors[`dependent_${index}_lastName`]}
                  </span>
                )}
              </div>
            </div>

            {/* Date of Birth Field */}
            <div className="flex space-x-4">
              <div className="flex flex-col flex-1">
                <label
                  htmlFor={`depDateOfBirth${index}`}
                  className="font-medium"
                >
                  Date of Birth (MM/DD/YYYY)
                </label>
                <DateInput
                 
                  value={dep.dateOfBirth}
                  onChange={(e) => {
                    const updatedDependents = [...taxProfile.dependents];
                    updatedDependents[index] = {
                      ...updatedDependents[index],
                      dateOfBirth: e,
                    };
                    setTaxProfile({
                      ...taxProfile,
                      dependents: updatedDependents,
                    });
                  }}
                />
                {/* Date of Birth Error Message */}
                {errors[`dependent_${index}_dateOfBirth`] && (
                  <span className="text-red-500 text-sm">
                    {errors[`dependent_${index}_dateOfBirth`]}
                  </span>
                )}
              </div>

              {/* Relationship Field */}
              <div className="flex flex-col flex-1">
                <label
                  htmlFor={`depRelationship${index}`}
                  className="font-medium"
                >
                  Relationship
                </label>
                <select
                  id={`depRelationship${index}`}
                  value={dep.relationship}
                  onChange={(e) => {
                    const updatedDependents = [...taxProfile.dependents];
                    updatedDependents[index] = {
                      ...updatedDependents[index],
                      relationship: e.target.value,
                    };
                    setTaxProfile({
                      ...taxProfile,
                      dependents: updatedDependents,
                    });
                  }}
                  className="border p-2 rounded"
                >
                  <option value="">Select Relationship</option>
                  <option value="Son">Son</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Brother">Brother</option>
                  <option value="Sister">Sister</option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>                
                  <option value="Other">Other</option>
                </select>
                {/* Relationship Error Message */}
                {errors[`dependent_${index}_relationship`] && (
                  <span className="text-red-500 text-sm">
                    {errors[`dependent_${index}_relationship`]}
                  </span>
                )}
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="flex flex-col flex-1">
                <label htmlFor={`depSSN${index}`} className="font-medium">
                  SSN/ITIN
                </label>
                <input
                  type="text"
                  id={`depSSN${index}`}
                  value={dep.ssnOrItin}
                  disabled={dep.applyForITIN}
                  onChange={(e) => {
                    const updatedDependents = [...taxProfile.dependents];
                    updatedDependents[index] = {
                      ...updatedDependents[index],
                      ssnOrItin: e.target.value,
                    };
                    setTaxProfile({
                      ...taxProfile,
                      dependents: updatedDependents,
                    });
                  }}
                  className={`border p-2 rounded ${
                    dep.applyForITIN
                      ? "bg-gray-200 text-gray-600 cursor-not-allowed opacity-70"
                      : "bg-white text-black"
                  }`}
                />
                {errors[`dependent_${index}_ssnOrItin`] && (
                  <span className="text-red-500 text-sm">
                    {errors[`dependent_${index}_ssnOrItin`]}
                  </span>
                )}
              </div>

              <div className="flex flex-col flex-1">
                <label
                  htmlFor={`depApplyForITIN${index}`}
                  className="font-medium"
                >
                  Apply for ITIN
                </label>
                <input
                  type="checkbox"
                  id={`depApplyForITIN${index}`}
                  checked={dep.applyForITIN}
                  onChange={(e) => {
                    const updatedDependents = [...taxProfile.dependents];
                    updatedDependents[index] = {
                      ...updatedDependents[index],
                      applyForITIN: e.target.checked,
                    };
                    setTaxProfile({
                      ...taxProfile,
                      dependents: updatedDependents,
                    });
                  }}
                  className="form-checkbox ml-2 mt-2"
                />
              </div>
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddDependent}
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Add Dependent
        </button>
        {dependent && <></>}
      </div>

      {/* Add State Residency Information Section */}
      <div className="space-y-4">
        <h2 className="text-xl font-bold">State Residency Information (till December 31st of Tax Year)</h2>
        {taxProfile.residencyInfo.map((residency, index) => (
          <div key={index} className="border p-4 rounded bg-gray-100">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">Residency {index + 1}</h3>
              <span
                onClick={() => {
                  const updatedResidencyInfo = taxProfile.residencyInfo.filter(
                    (_, i) => i !== index
                  );
                  setTaxProfile({
                    ...taxProfile,
                    residencyInfo: updatedResidencyInfo,
                  });
                }}
                className="btn-primary px-2 py-2 rounded-lg cursor-pointer"
              >
                <FaTrash className="text-white" />
              </span>
            </div>
            <div className="flex space-x-4">
              <div className="flex flex-col flex-1">
                {" "}
                <label htmlFor={`state${index}`} className="font-medium">
                  State
                </label>
                <select
                  id={`state${index}`}
                  value={residency.state}
                  onChange={(e) => {
                    const updatedResidencies = [...taxProfile.residencyInfo];
                    updatedResidencies[index] = {
                      ...updatedResidencies[index],
                      state: e.target.value,
                    };
                    setTaxProfile({
                      ...taxProfile,
                      residencyInfo: updatedResidencies,
                    });
                  }}
                  className="border p-2 rounded"
                >
                  <option value="">Select State</option>
                  {stateOptions.map((state) => (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors[`residency_${index}_state`] && (
                  <p className="text-red-500 text-sm">
                    {errors[`residency_${index}_state`]}
                  </p>
                )}
              </div>
              <div className="flex flex-col flex-1">
                {" "}
                <label
                  htmlFor={`residencyBeginDate${index}`}
                  className="font-medium"
                >
                  Residency Begin Date
                </label>
                <DateInput
                  value={residency.residencyBeginDate}
                  onChange={(e) => {
                    const updatedResidencies = [...taxProfile.residencyInfo];
                    updatedResidencies[index] = {
                      ...updatedResidencies[index],
                      residencyBeginDate: e,
                    };
                    setTaxProfile({
                      ...taxProfile,
                      residencyInfo: updatedResidencies,
                    });
                  }}
                />
                {errors[`residency_${index}_startDate`] && (
                  <p className="text-red-500 text-sm">
                    {errors[`residency_${index}_startDate`]}
                  </p>
                )}
                {errors[`residency_${index}_dateRange`] && (
                  <p className="text-red-500 text-sm">
                    {errors[`residency_${index}_dateRange`]}
                  </p>
                )}
              </div>
              <div className="flex flex-col flex-1">
                {" "}
                <label
                  htmlFor={`residencyEndDate${index}`}
                  className="font-medium"
                >
                  Residency End Date
                </label>
                <DateInput
                  value={residency.residencyEndDate}
                  onChange={(e) => {
                    const updatedResidencies = [...taxProfile.residencyInfo];
                    updatedResidencies[index] = {
                      ...updatedResidencies[index],
                      residencyEndDate: e,
                    };
                    setTaxProfile({
                      ...taxProfile,
                      residencyInfo: updatedResidencies,
                    });
                  }}
                />
                {errors[`residency_${index}_endDate`] && (
                  <p className="text-red-500 text-sm">
                    {errors[`residency_${index}_endDate`]}
                  </p>
                )}
                {errors[`residency_${index}_dateRange`] && (
                  <p className="text-red-500 text-sm">
                    {errors[`residency_${index}_dateRange`]}
                  </p>
                )}
              </div>
              <div className="flex flex-col flex-1">
                {" "}
                <label htmlFor={`residencyInfoFor${index}`} className="font-medium">
                Residency Info For
                </label>
                <select
                  id={`residencyInfoFor${index}`}
                  value={residency.residencyInfoFor}
                  onChange={(e) => {
                    const updatedResidencies = [...taxProfile.residencyInfo];
                    updatedResidencies[index] = {
                      ...updatedResidencies[index],
                      residencyInfoFor: e.target.value,
                    };
                    setTaxProfile({
                      ...taxProfile,
                      residencyInfo: updatedResidencies,
                    });
                  }}
                  className="border p-2 rounded"
                >
                  <option value="Taxpayer">Taxpayer</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Family">Family</option>
                </select>                
              </div>
            </div>
          </div>
        ))}
          {errors.residency && (
            <div style={{ color: "red" }}>{errors.residency}</div>
          )}
        <button
          type="button"
          onClick={handleAddResidencyInfo}
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Add Residency Information
        </button>
        {stateResidency && <></>}
      </div>

      {/* Add Bank Details Section */}
      <div className="space-y-4 bg-gray-100 p-4">
        <h2 className="text-xl font-bold">Bank Details</h2>
        <div className="flex flex-col mb-2">
          <label htmlFor="bankName" className="font-medium">
            Bank Name
          </label>
          <input
            type="text"
            id="bankName"
            value={taxProfile.bankDetails.bankName}
            onChange={(e) =>
              setTaxProfile({
                ...taxProfile,
                bankDetails: {
                  ...taxProfile.bankDetails,
                  bankName: e.target.value,
                },
              })
            }
            className="border p-2 rounded"
          />
          {errors.bankName && (
            <div style={{ color: "red" }}>{errors.bankName}</div>
          )}
        </div>
        <div className="flex flex-col mb-2">
          <label htmlFor="accountNumber" className="font-medium">
            Account Number
          </label>
          <input
            type="text"
            id="accountNumber"
            value={taxProfile.bankDetails.accountNumber}
            onChange={(e) =>
              setTaxProfile({
                ...taxProfile,
                bankDetails: {
                  ...taxProfile.bankDetails,
                  accountNumber: e.target.value,
                },
              })
            }
            className="border p-2 rounded"
          />
          {errors.accountNumber && (
            <div style={{ color: "red" }}>{errors.accountNumber}</div>
          )}
        </div>
        <div className="flex flex-col mb-2">
          <label htmlFor="routingNumber" className="font-medium">
            Routing Number
          </label>
          <input
            type="text"
            id="routingNumber"
            value={taxProfile.bankDetails.routingNumber}
            onChange={(e) =>
              setTaxProfile({
                ...taxProfile,
                bankDetails: {
                  ...taxProfile.bankDetails,
                  routingNumber: e.target.value,
                },
              })
            }
            className="border p-2 rounded"
          />
          {errors.routingNumber && (
            <div style={{ color: "red" }}>{errors.routingNumber}</div>
          )}
        </div>
        <div className="flex flex-col mb-2">
          <label htmlFor="accountType" className="font-medium">
            Account Type
          </label>
          <select
            id="accountType"
            value={taxProfile.bankDetails.accountType}
            onChange={(e) =>
              setTaxProfile({
                ...taxProfile,
                bankDetails: {
                  ...taxProfile.bankDetails,
                  accountType: e.target.value,
                },
              })
            }
            className="border p-2 rounded"
          >
            <option value="">Select Account Type</option>
            <option value="Checking">Checking</option>
            <option value="Savings">Savings</option>
          </select>
          {errors.accountType && (
            <div style={{ color: "red" }}>{errors.accountType}</div>
          )}
        </div>


        
      <div className="flex flex-col mb-2">
        <label htmlFor="routingNumber" className="font-medium">
          <strong>Remarks : </strong>Any Additional Information For Tax Preparer (Such as Rent Paid, VISA/Residency Status changes, Any other Tax Situation etc)
        </label>
        <input
          type="text"
          id="remark"
          value={taxProfile.personalInfo.remark}
          onChange={(e) =>
            setTaxProfile({
              ...taxProfile,
              personalInfo: {
                ...taxProfile.personalInfo,
                remark: e.target.value,
              },
            })
          }
          className="border p-2 rounded"
        />
      </div>
      {Object.keys(validateTaxProfile(taxProfile)).length>0?
        <div style={{ color: "red" }}> <strong>Note : </strong>Please fill out the form with accurate values.</div>
       :""}
      </div>

      {/* Submit Button */}
      <div className="flex justify-end mt-4">
        <button
          type="submit"
          className="bg-green-500 text-white py-2 px-4 rounded"
          disabled={isSubmitting}>
          {isSubmitting ? "Sending..." : "Submit"}
        </button>
      </div>
      <ToastContainer />
    </form>
  );
};

export default CreateTaxProfile;
