import React from "react";

export default function TaxRepresentation() {
  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-4 text-left">Tax Representation</h2>
      <p className="text-justify">
        We guarantee the accuracy of all tax returns prepared by us and offer FREE audit and IRS/State revenue representation to our clients. If you receive any audit letter, notice, or inquiry regarding your tax return, HTTaxSolutions can represent you before the IRS or State Revenue Department, regardless of who prepared your tax return. 
      </p>
      <p className="text-justify mt-4">
        If we prepared your return, our audit support service is free of charge. For clients whose returns were not prepared by us, we can still provide representation services for a nominal fee.
      </p>
    </div>
  );
}
