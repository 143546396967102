import React, { useEffect, useState } from "react";
import DataTable, { Row } from "components/DataTable";
import apiService from "services/apiService";
import { toast, ToastContainer } from "react-toastify";
import { BreadCrumb } from "components/BreadCrumb";

const UserStatus: any = {
  0: "Pending",
  1: "SubmittedForReview",
  2: "ManagerReview",
  3: "Completed",
  4: "PaymentComplete",
};

export default function UserListForMail() {
  const [data, setData] = useState<Row[]>([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState<Row[]>([]);
  const [searchedData,setSearchedData]= useState<Row[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [message, setMessage] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [recipient, setRecipient] = useState(""); // For "Custom" mail
  const [mailType,setMailType]=useState<string>("bulk")
  useEffect(() => {
    setLoading(true);
    apiService
      .get("registrations")
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      })
      .catch((err) => console.error("Failed to fetch users", err))
      .finally(() => setLoading(false));
  }, []);

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newFilter = event.target.value;
    setFilter(newFilter);

    if (newFilter) {
      setFilteredData(data.filter((user) => user.user_status === parseInt(newFilter)));
    } else {
      setFilteredData(data);
    }
  };

  const handleSetEmail=()=>{
    // setRecipient(recipient.)
    console.log('searchedData : ',searchedData.map((user) => user.email));
    setRecipient(searchedData.map((user) => user.email).join(","))
  }
  const handleSendEmail = async () => {
    const selectedEmails = mailType === "custom"?recipient.split(","):filteredData.map((user) => user.email);
  
    if (selectedEmails.length === 0 || !message) {
      toast.error("Please ensure there are users to send to and a message.");
      return;
    }
  
    try {
      await apiService.post("/registrations/send-emails", {
        emails: selectedEmails,
        message: message,
        title:title,
      });
      toast.success("Emails sent successfully.");
    } catch (error) {
      console.error("Error sending emails:", error);
      toast.error("Failed to send emails.");
    }
  };
  
  const tableColumns = [
    {
      key: "id",
      label: `ID`,
      bold: true,
      style: { width: "80px" },
    },
    {
      key: "fullName",
      label: `Full Name`,
      bold: true,
      render: (row: any, data: any) =>
        `${data.first_name} ${data.last_name}`.replace(/\b\w/g, (c) => c.toUpperCase()),
    },
    {
      key: "user_status",
      label: `Status`,
      render: (row: any, data: any) => UserStatus[data.user_status],
    },
    {
        key: "email",
        label: `Email ID`,
        render: (row: any, data: any) =>data.email,
      },
    // {
    //   key: "actions",
    //   label: `Actions`,
    //   render: (row: any, data: any) => (
    //     <input
    //       type="checkbox"
    //       checked={selectedRows.has(data.reg_id)}
    //       onChange={() => handleRowSelect(data.reg_id)}
    //     />
    //   ),
    // },
  ];

  const handleRowSelect = (id: number) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(id)) {
      newSelectedRows.delete(id);
    } else {
      newSelectedRows.add(id);
    }
    setSelectedRows(newSelectedRows);
  };

  const BreadCrumbList = [
    { name: "Users", link: "#" },
    { name: "List", link: "#" },
  ];

  return (
    <>
      <ToastContainer autoClose={1000} />
      <BreadCrumb BreadCrumbList={BreadCrumbList} />

     <div className="p-4 bg-gray-100">
        {/* Top Header */}
        <h2 className="text-2xl font-semibold text-center mb-6 pt-4">Send Mail To Users</h2>
        {/* Radio Buttons for Mail Type */}
        <div className="flex justify-center items-center mb-4">
          <label className="mr-4">
            <input
              type="radio"
              name="mailType"
              value="bulk"
              checked={mailType === "bulk"}
              onChange={(e) => setMailType(e.target.value)}
              className="mr-2"
            />
            Bulk Mail
          </label>
          <label>
            <input
              type="radio"
              name="mailType"
              value="custom"
              checked={mailType === "custom"}
              onChange={(e) => setMailType(e.target.value)}
              className="mr-2"
            />
            Custom Mail
          </label>
        </div>
          {/* Conditional "To" Field */}
      {mailType === "custom" && (
        <div className="mb-2 mx-4">
          <label className="block mb-2 text-lg font-medium">To:</label>
          <input
            type="text"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            className="w-full p-2 border rounded-md"
            placeholder="Enter recipient email"
          />
        </div>
      )}

        {/* Message Textarea */}
        <div className="mb-2 mx-4">
          <label className="block mb-2 text-lg font-medium">Subject:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border rounded-md"
            placeholder="Type your subject here"
          />
        </div>
        <div className="mb-1 mx-4">
          <label className="block mb-2 text-lg font-medium">Message:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full h-32 p-2 border rounded-md"
            placeholder="Type your message here..."
          />
        </div>
     </div>

      {/* Filter dropdown and Email button in one row */}
      <div className="flex items-center justify-between p-4 bg-gray-200">
        {/* Filter dropdown */}
        <div className="flex items-center">
          <label className="mr-3 font-bold">Filter by Status:</label>
          <select
            value={filter}
            onChange={handleFilterChange}
            className="border rounded px-2 py-1"
          >
            <option value="">All</option>
            <option value="0">Pending</option>
            <option value="1">SubmittedForReview</option>
            <option value="2">ManagerReview</option>
            <option value="3">Completed</option>
            <option value="4">PaymentComplete</option>
          </select>
        </div>

        {/* Email button */}
        <button
          onClick={handleSetEmail}
          className="text-white mr-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          disabled={recipient||searchedData.length>0?false:true}
        >
          Set Emails
        </button>
        <button
          onClick={handleSendEmail}
          className="text-white mr-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          disabled={filteredData.length === 0}
        >
          Send Email to {mailType === "custom"?recipient?recipient.split(",").length:'0':filteredData.length} Users
        </button>

      </div>

      {/* User table */}
      <DataTable
        data={filteredData}
        columns={tableColumns}
        loading={loading}
        onAddBtnClick={() => console.log("Add User")}
        addbtn={false}
        getFilterData={(data=>setSearchedData(data))}
      />
    </>
  );
}
