import {
  FaCalendarTimes,
  FaCity,
  FaTasks,
  FaAddressCard,
  FaCalendarAlt,
  FaFileInvoiceDollar,
  FaBusinessTime,
  FaCloudUploadAlt,
  FaUsers,
  FaBook,
} from "react-icons/fa";
import { UserRole } from "../../types/role"; // Importing the UserRole enum
import { IconType } from "react-icons";

interface SidebarItem {
  label: string;
  url: string;
  icon: IconType; // Change from React.ComponentType to IconType
  roles?: UserRole[]; // Optional property to define roles that can access this item
  submenu?: SidebarItem[]; // For nested items
}

const items: SidebarItem[] = [
  {
    label: "User Accounts",
    url: "users",
    icon: FaUsers,
    roles: [UserRole.Admin, UserRole.Manager],
  },
  // { label: "Managers", url: "managers", icon: FaUsers },
  {
    label: "Operators",
    url: "operators",
    icon: FaUsers,
    roles: [UserRole.Admin],
  },
  {
    label: "Client Listing",
    url: "assigned-users",
    icon: FaUsers,
    roles: [UserRole.Operator],
  },
  {
    label: "Tax Pricing",
    url: "pricing",
    icon: FaAddressCard,
    roles: [UserRole.Admin],
  },
  
  // {
  //   label: "Assign Operator to Users",
  //   url: "assign-operator",
  //   icon: FaAddressCard,
  //   roles: [UserRole.Admin],
  //   submenu: [
  //     {
  //       label: "New Users",
  //       url: "new-users",
  //       icon: FaUsers,
  //       roles: [UserRole.Admin],
  //     },
  //     {
  //       label: "Assigned Users",
  //       url: "assigned-users",
  //       icon: FaUsers,
  //       roles: [UserRole.Admin],
  //     },
  //   ],
  // },
  {
    label: "Contact-us Enquiries",
    url: "enquiries",
    icon: FaUsers,
    roles: [UserRole.Admin, UserRole.Manager],
  },
  {
    label: "Testimonials",
    url: "testimonials",
    icon: FaCloudUploadAlt,
    roles: [UserRole.Admin, UserRole.Manager],
  },
  {
    label: "Notifications",
    url: "notifications",
    icon: FaAddressCard,
    roles: [UserRole.Client, UserRole.Operator],
  },
  // {
  //   label: "CMS",
  //   url: "CMS",
  //   icon: FaCloudUploadAlt,
  //   roles: [UserRole.Admin],
  // },
  {
    label: "Email Templates",
    url: "email_emplates",
    icon: FaAddressCard,
    roles: [UserRole.Admin],
  },
  {
    label: "Send E-mails",
    url: "sendemails",
    icon: FaUsers,
    roles: [UserRole.Admin],
  },
  // {
  //   label: "System Setting",
  //   url: "system-setting",
  //   icon: FaCalendarAlt,
  //   roles: [UserRole.Admin],
  // },
];


export default items;
