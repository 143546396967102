import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast,ToastContainer } from "react-toastify";
import apiService from "services/apiService";
import { storageService } from "services/storageService";
import Modal from "components/Modal";
import { OtpPopup } from "./OtpPopup";
interface UserProfileData {
  reg_id: string;
  profile_status: number;
  tax_profile_id: string;
}

const UserProfile: React.FC = () => {
  const [hasTaxProfile, setHasTaxProfile] = useState<boolean | null>(null);
  const [taxProfileId, setTaxProfileId] = useState<string | null>(null);
  const [documents, setDocuments] = useState([]);
  const [documentsData, setDocumentsData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);
  const [deletingDocId, setDeletingDocId] = useState<number | null>(null);
  const [otpPopupOpen,setOtpPopupOpen]=useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userInfo = storageService.getItem("userdetails");
        const parsedUserInfo = userInfo ? JSON.parse(userInfo) : null;
        const reg_id = parsedUserInfo?.user?.reg_id;

        if (!reg_id) {
          toast.error("User registration ID not found.");
          return;
        }

        const res = await apiService.get(`/registrations/${reg_id}`);
        const userProfile: UserProfileData = res.data;

        if (userProfile.profile_status === 1) {
          setHasTaxProfile(true);
          setTaxProfileId(userProfile.tax_profile_id);
          fetchDocuments(reg_id);
        } else {
          setHasTaxProfile(false);
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          storageService.removeItem("accessToken");
          storageService.removeItem("userdetails");         
          navigate("/adminlogin");
        } else {
          toast.error("Failed to fetch user profile.");
        }
      }
    };

    const fetchDocuments = async (reg_id: string) => {
      setLoading(true);
      try {
        const res = await apiService.get(`/tax-documents/document/${reg_id}`);
        console.log('Documents : ',res.data);
        setDocuments(res.data?.documents|| []);
        setDocumentsData(res.data);
        setIsSubmitEnabled(res.data?.documents.length > 0);
      } catch (error) {
        console.error("Failed to fetch documents.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [navigate]);

  const handleUploadDocuments = () => {
    navigate("/user/uploaddocument");
  };

  const handleCreateTaxProfile = () => {
    setHasTaxProfile(true);
    navigate("/user/profile");
  };


  const handleSubmitForReview = async () => {
    setIsSubmitEnabled(false);
    const userInfo = storageService.getItem("userdetails");
    const parsedUserInfo = userInfo ? JSON.parse(userInfo) : null;
    const reg_id = parsedUserInfo?.user?.reg_id;
    await apiService.put(
      `/registrations/update-status/${reg_id}/1`
    );
    setIsSubmitEnabled(true);
    toast.success("Your profile and documents have been submitted for review!");

  };
  const handleDeleteAllDocument = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this document?");
    if (!confirmDelete) return;
    const userInfo = storageService.getItem("userdetails");
    const parsedUserInfo = userInfo ? JSON.parse(userInfo) : null;
    const regId = parsedUserInfo?.user?.reg_id;
    setDeletingDocId(regId); 
    try {
      await apiService.delete(`/operators/upload-documents/user/${regId}`);
      setDocuments([]);
      toast.success("Document deleted successfully.");
      setIsSubmitEnabled(documents.length > 1); 
    } catch (error) {
      console.error("Error deleting document:", error);
      toast.error("Failed to delete document.");
    } finally {
      setDeletingDocId(null);
    }
  };
  const handleDeleteDocument = async (docId: number) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this document?");
    if (!confirmDelete) return;

    setDeletingDocId(docId); 
    try {
      await apiService.delete(`/operators/upload-documents/${docId}`);
      setDocuments((prevDocs) => prevDocs.filter((doc: any) => doc.id !== docId));
      toast.success("Document deleted successfully.");
      setIsSubmitEnabled(documents.length > 1); 
    } catch (error) {
      console.error("Error deleting document:", error);
      toast.error("Failed to delete document.");
    } finally {
      setDeletingDocId(null);
    }
  };
  const verifyOtp = async ( otpValid: boolean) => {
    if(otpValid){
      navigate(`/user/updateprofile/${taxProfileId}`);
    }else {
        // Handle invalid OTP response
        toast.error("Invalid OTP. Please try again.");
    }    
  };
  const initiateProfileUpdate = async (regId:any) => {
    try {
      await apiService.post('/tax-profiles/generate-otp', { reg_id: regId });
      setOtpPopupOpen(true); // Open OTP popup
    } catch (error) {
      console.error('Error generating OTP:', error);
      alert('Error sending OTP.');
    }
  };
  
  const handleUpdateTaxProfile = () => {
    // setIsVerifying(true);
    // initiateProfileUpdate(taxProfileId);
    navigate(`/user/updateprofile/${taxProfileId}`);
    
  };
  if (hasTaxProfile === null) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-gray-700">Loading...</p>
      </div>
    );
  }

  return (
    <div className="full-page-container bg-white-100 flex-col justify-center items-center min-h-screen bg-gray-100 p-4">
      <ToastContainer/>
      {hasTaxProfile ? (
        <>
          <div className="flex w-[75%] space-x-2 mb-6 justify-between">
            <div className="flex flex-row flex-1 space-x-4">
            <button
              onClick={handleUpdateTaxProfile}
              className="bg-blue-500  text-white font-bold py-2 px-4 rounded hover:bg-blue-700 shadow-md"
              disabled={isVerifying}             
            >
              {isVerifying ? 'Verifying...' : 'Update TaxProfile'}
            </button>
            <button
              onClick={handleUploadDocuments}
              className="bg-blue-500 text-white  font-bold py-2 px-4 rounded hover:bg-blue-700 shadow-md"
            >
              Upload Document
            </button>
            </div>
            {documentsData?.status===4?<></>:
            <div className="flex  flex-1 justify-end">           
            <button
              onClick={handleSubmitForReview}
              disabled={!isSubmitEnabled}
              className={`${
                isSubmitEnabled ? "bg-green-500 hover:bg-green-700" : "bg-gray-400 cursor-not-allowed"
              } text-white font-bold py-2 px-4 rounded w-[250px] shadow-md`}
            >
              Submit for Review
            </button>
          </div>}
          </div>
<div className="flex w-[75%] p-4 bg-red-700  mb-3 shadow-xl ">
<p className="text-white">
<strong>You must press the <span className="bg-yellow-100 shadow-xl px-3 rounded-sm pb-1 text-red-600">Submit for Review</span> button only after you create/update your tax profile and finish uploading all of your tax documents to notify us to start working on your taxes.</strong>
            </p>
</div>
          <div className={`w-full max-w-4xl  shadow-md rounded-lg p-5 ${documentsData?.status===4?"bg-gray-800":"bg-white"}`}>
            {documentsData?.status===4?"" :<div className="flex flex-row justify-between"><h1 className="text-xl font-semibold mb-6">Uploaded Documents</h1>
              <div>
              <button
                            onClick={() => handleDeleteAllDocument()}
                            className="text-gray-100 hover:text-red-800 bg-blue-600 px-4 py-2 hover:bg-yellow-200 shadow-md"
                            disabled={deletingDocId!==null }
                          >
                            {deletingDocId!==null ? "Deleting..." : "Delete All"}
                          </button>
              </div>
            </div>
}
           
            {loading ? (
              <p className="text-gray-700">Loading documents...</p>
            ) : documents?.length > 0 ? (
              <div className="overflow-x-auto max-h-[300px]">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Sr. No
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider  w-[130px]">
                        Document Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Title
                      </th>
                      {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Download
                      </th> */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {documents.map((doc: any, index) => (
                      <tr key={doc.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                          {doc.document_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                          {doc.title}
                        </td>
                        {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                          <button
                            className="bg-green-500 text-white px-2 py-1 rounded mr-2 hover:bg-green-600"
                            onClick={() => window.open(doc.download_link, "_blank")}
                          >
                            Download
                          </button>
                        </td> */}
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                          <button
                            onClick={() => handleDeleteDocument(doc.id)}
                            className="text-red-600 hover:text-red-800"
                            disabled={deletingDocId === doc.id}
                          >
                            {deletingDocId === doc.id ? "Deleting..." : "Delete"}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : documentsData?.status===4?(
              <p className="text-center font-bold text-gray-100">{documentsData.message}</p>
            ):(
              <p className="text-gray-700">No documents uploaded yet.</p>
            )}
          </div>

      
          
        </>
      ) : (
        <button
          onClick={handleCreateTaxProfile}
          className="bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-700"
        >
          Create Tax Profile
        </button>
      )}
            <Modal
          isOpen={otpPopupOpen}
          onClose={() => {setOtpPopupOpen(false);}}
          header="Update Profile OTP"
        >
          <OtpPopup  regId={taxProfileId} onClose={() => setOtpPopupOpen(false)} verifyOtp={verifyOtp}></OtpPopup>
          </Modal>
    </div>
  );
};

export default UserProfile;
