// // src/components/UploadDocuments.tsx
// import React, { useCallback, useState } from "react";
// import { useDropzone } from "react-dropzone";
// import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import apiService from "services/apiService";
// import { storageService } from "services/storageService";

// interface FileWithEditableName {
//   file: File;
//   editableName: string; // Adding an editable name property to each file
// }

// const UploadDocuments: React.FC = () => {
//   const [files, setFiles] = useState<FileWithEditableName[]>([]);
//   const [isUploading, setIsUploading] = useState(false);
//   const navigate = useNavigate();

//   const onDrop = useCallback((acceptedFiles: File[]) => {
//     // Extend files with an editableName property for display and editing
//     const filesWithNames: FileWithEditableName[] = acceptedFiles.map((file) => ({
//       file,
//       editableName: file.name,
//     }));
//     setFiles((prevFiles) => [...prevFiles, ...filesWithNames]);
//   }, []);

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//     onDrop,
//     accept: {
//       "application/pdf": [], // Accept PDF files
//       "application/msword": [], // Accept .doc files
//       "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // Accept .docx files
//       "application/vnd.ms-excel": [], // Accept .xls files
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // Accept .xlsx files
//       "text/plain": [], // Accept .txt files
//       "text/csv": [], // Accept .csv files
//     },
//   });


//   const handleUpload = async () => {
//     let userdetails=storageService.getItem('userdetails')
//     let regId=JSON.parse(userdetails||"")?.user?.reg_id;
//     console.log('Admin userdetails : ',regId)
//     if (!regId) {
//       toast.error("User ID is missing.");
//       return;
//     }

//     const formData = new FormData();
//     formData.append('reg_id', regId); // Append reg_id to form data

//     // Append files and their titles to formData
//     files.forEach((fileWrapper, index) => {
//       console.log('Files : ',fileWrapper);
//       formData.append('documents', fileWrapper.file);
//       formData.append('titles', fileWrapper.editableName); // Attach titles corresponding to each file
//     });
//     setIsUploading(true);
//     try {
//       const response = await apiService.post(`/operators/upload-documents/${regId}`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       if (response.status === 200) {
//         toast.success("Files uploaded successfully!");
//         // Reset files after successful upload
//         setFiles([]);
//         // Optionally navigate or perform other actions
//       } else {
//         toast.error("Failed to upload files.");
//       }
//     } catch (error: any) {
//       console.error("Error uploading files:", error);
//       const message = error.response?.data?.message || "Failed to upload files.";
//       if(error.response?.status===401){
//         navigate("/session-expired");
//       }else{
//         toast.error(message);
//       }
//     } finally {
//       setIsUploading(false); // Re-enable the button after upload
//     }
//   };
  

//   const handleBack = () => {
//     navigate(-1); // Navigates back to the previous page
//   };

//   const handleNameChange = (index: number, newName: string) => {
//     const updatedFiles = [...files];
//     updatedFiles[index].editableName = newName;
//     setFiles(updatedFiles);
//   };

//   return (
//     <div className="flex flex-col items-center justify-center h-screen p-4">
//       <div
//         {...getRootProps()}
//         className={`w-full max-w-xl border-4 border-dashed rounded-lg p-10 cursor-pointer transition-colors ${
//           isDragActive ? "border-green-500 bg-green-100" : "border-gray-300"
//         }`}
//       >
//         <input {...getInputProps()} />
//         {isDragActive ? (
//           <p className="text-center text-lg text-green-600">
//             Drop the files here ...
//           </p>
//         ) : (
//           <p className="text-center text-lg text-gray-600">
//             Drag and drop some files here, or click to select files
//           </p>
//         )}
//       </div>

//       {files.length > 0 && (
//         <div className="mt-4 w-full max-w-xl overflow-x-auto max-h-[300px]">
//           <h2 className="text-xl font-bold mb-2">Uploaded Files</h2>
//           <ul className="bg-white p-4 rounded shadow">
//             {files.map((fileWrapper, index) => (
//               <li
//                 key={index}
//                 className="flex items-center justify-between mb-2"
//               >
//                 <input
//                   type="text"
//                   value={fileWrapper.editableName}
//                   onChange={(e) => handleNameChange(index, e.target.value)}
//                   className="border p-2 rounded w-full mr-2"
//                 />
//                 <span className="text-gray-800" style={{width:'120px',fontSize:'13px',fontWeight:500}}>
//                   ({(fileWrapper.file.size / 1024).toFixed(2)} KB)
//                 </span>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}

//       <div className="mt-4 flex space-x-4">
//         <button
//           onClick={handleUpload}
//           title={
//             files.length === 0
//               ? "Please select files to upload"
//               : isUploading
//               ? "Uploading in progress"
//               : "Click to upload files"
//           }     
//           className={`bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 
//             ${files.length === 0 || isUploading ? "bg-gray-400 cursor-not-allowed" : ""}`}
//           disabled={files.length === 0 || isUploading}
//         >
//           {isUploading ? "Uploading..." : "Upload"}
//         </button>
//         <button
//           onClick={handleBack}
//           className="bg-gray-500 text-white font-bold py-2 px-4 rounded hover:bg-gray-700"
//         >
//           Back
//         </button>
//       </div>

//         {/* Note Section */}
//     <div className="mt-6 w-full max-w-5xl bg-gray-100 p-4 rounded-lg shadow">
//       <h3 className="text-lg font-semibold mb-2">Please upload the following documents:</h3>
//       <ul className="list-disc pl-5 text-gray-800">
//         <li>
//           <strong>Tax Documents:</strong> All W2, All 1099 forms received (if applicable), 1098s, 1095s, and any other Income or tax-related documents.
//         </li>
//         <li>
//           <strong>ITIN Application Documents:</strong> Passport first page, last page, VISA stamp page, and I-94 of ITIN Applicant.
//         </li>
//       </ul>
//       <h3 className="text-lg font-semibold mt-4">Acceptable Document Types:</h3>
//       <ul className="list-disc pl-5 text-gray-800">
//         <li>PDF, DOC/DOCX, Excel Sheets, and Pictures only.</li>
//         <li>Zip and other types will not be uploaded.</li>
//         <li>Maximum File Size: 5 MB.</li>
//       </ul>
//     </div>

//       <ToastContainer />
//     </div>
//   );
// };

// export default UploadDocuments;
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import apiService from "services/apiService";
import { storageService } from "services/storageService";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

interface FileWithStatus {
  file: File;
  editableName: string;
  progress: number; // Upload progress in percentage
  status: "pending" | "uploading" | "success" | "error"; // Upload status
}

const UploadDocuments: React.FC = () => {
  const [files, setFiles] = useState<FileWithStatus[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      editableName: file.name,
      progress: 0,
      status: "pending",
    }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles] as FileWithStatus[]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
      "application/vnd.ms-excel": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "text/plain": [],
      "text/csv": [],
      "image/jpeg": [], // JPG & JPEG
      "image/png": [], // PNG
    },
  });

  const handleUpload = async () => {
    let userdetails = storageService.getItem("userdetails");
    let regId = JSON.parse(userdetails || "{}")?.user?.reg_id;

    if (!regId) {
      toast.error("User ID is missing.");
      return;
    }

    setIsUploading(true);
    let successCount = 0;
    let failedCount = 0;

    for (let i = 0; i < files.length; i++) {
      const fileWrapper = files[i];

      // Update status to "uploading"
      setFiles((prevFiles) =>
        prevFiles.map((f, index) =>
          index === i ? { ...f, status: "uploading", progress: 0 } : f
        )
      );

      const formData = new FormData();
      formData.append("reg_id", regId);
      formData.append("documents", fileWrapper.file);
      formData.append("titles", fileWrapper.editableName);

      try {
        const response = await apiService.post(
          `/operators/upload-documents/${regId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent: ProgressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setFiles((prevFiles) =>
                prevFiles.map((f, index) =>
                  index === i ? { ...f, progress } : f
                )
              );
            },
          }
        );

        if (response.status === 200) {
          successCount++;
          setFiles((prevFiles) =>
            prevFiles.map((f, index) =>
              index === i ? { ...f, status: "success", progress: 100 } : f
            )
          );
        } else {
          failedCount++;
          setFiles((prevFiles) =>
            prevFiles.map((f, index) =>
              index === i ? { ...f, status: "error" } : f
            )
          );
        }
      } catch (error: any) {
        failedCount++;
        setFiles((prevFiles) =>
          prevFiles.map((f, index) =>
            index === i ? { ...f, status: "error" } : f
          )
        );
        if (error.response?.status === 401) {
          navigate("/session-expired");
        } else {
          toast.error("Failed to upload: " + fileWrapper.editableName);
        }
      }
    }
    setTimeout(()=>{
      navigate("/user");
    },3000);
    toast.success(`${successCount} files uploaded, ${failedCount} failed.`);
   
    setIsUploading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <div
        {...getRootProps()}
        className={`w-full max-w-xl border-4 border-dashed rounded-lg p-10 cursor-pointer transition-colors ${
          isDragActive ? "border-green-500 bg-green-100" : "border-gray-300"
        }`}
      >
        <input {...getInputProps()} />
        <p className="text-center text-lg text-gray-600">
          {isDragActive
            ? "Drop the files here ..."
            : "Drag and drop some files here, or click to select files"}
        </p>
      </div>
<p className="w-2/3 text-red-500 p-3">DO NOT UPLOAD FILE WITH SAME NAME AS OF AN ALREADY UPLOAD FILENAME. PLEASE EITHER DELETE PREVIOUSLY UPLOADED FILE OR UPLOAD FILE WITH A DIFFERENT NAME</p>
      {files.length > 0 && (
        <div className="mt-4 w-full max-w-xl overflow-x-auto max-h-[300px]">
          <h2 className="text-xl font-bold mb-2">Uploaded Files</h2>
          <ul className="bg-white p-4 rounded shadow">
            {files.map((fileWrapper, index) => (
              <li key={index} className="flex items-center justify-between mb-2">
                <input
                  type="text"
                  value={fileWrapper.editableName}
                  onChange={(e) =>
                    setFiles((prevFiles) =>
                      prevFiles.map((f, i) =>
                        i === index ? { ...f, editableName: e.target.value } : f
                      )
                    )
                  }
                  className="border p-2 rounded w-full mr-2"
                />
                <div className="w-24">
                  <div className="bg-gray-200 h-2 rounded overflow-hidden">
                    <div
                      className={`h-full ${
                        fileWrapper.status === "success"
                          ? "bg-green-500"
                          : fileWrapper.status === "error"
                          ? "bg-red-500"
                          : "bg-blue-500"
                      }`}
                      style={{ width: `${fileWrapper.progress}%` }}
                    ></div>
                  </div>
                </div>
                <span className="ml-2 text-gray-800 w-16 text-sm font-medium">
                  {fileWrapper.progress}%
                </span>
                {fileWrapper.status === "success" ? (
                  <FaCheckCircle className="text-green-500 text-xl" />
                ) : fileWrapper.status === "error" ? (
                  <FaTimesCircle className="text-red-500 text-xl" />
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="mt-4 flex space-x-4">
        <button
          onClick={handleUpload}
          disabled={files.length === 0 || isUploading}
          className={`bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 
            ${files.length === 0 || isUploading ? "bg-gray-400 cursor-not-allowed" : ""}`}
        >
          {isUploading ? "Uploading..." : "Upload"}
        </button>
        <button
          onClick={() => navigate(-1)}
          className="bg-gray-500 text-white font-bold py-2 px-4 rounded hover:bg-gray-700"
        >
          Back
        </button>
      </div>

      <ToastContainer />
    </div>
  );
};

export default UploadDocuments;
