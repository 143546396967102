import React from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../components/Input";
import Button from "components/Button";

// Validation Schema
const validationSchema = yup.object({
  personalDetails: yup.object({
    primary: yup.object({
      firstName: yup.string().required("First Name is required"),
      lastName: yup.string().required("Last Name is required"),
      dateOfBirth: yup.date().required("Date of Birth is required"),
      relationship: yup.string().required("Relationship is required"),
      maritalStatus: yup
        .string()
        .oneOf(
          ["Single", "Married", "Divorced", "Widowed"],
          "Invalid Marital Status"
        )
        .required("Marital Status is required"),
      usVisaResidenceType: yup
        .string()
        .required("US Visa/Residence Type is required"),
      dateOfFirstArrivalInUSA: yup
        .date()
        .nullable()
        .required("Date of First Arrival in USA is required"),
      dateOfDivorce: yup.date().nullable(),
      dateOfDeath: yup.date().nullable(),
    }),
    secondary: yup.object({
      firstName: yup.string().required("First Name is required"),
      lastName: yup.string().required("Last Name is required"),
      dateOfBirth: yup.date().required("Date of Birth is required"),
      relationship: yup.string().required("Relationship is required"),
      maritalStatus: yup
        .string()
        .oneOf(
          ["Single", "Married", "Divorced", "Widowed"],
          "Invalid Marital Status"
        )
        .required("Marital Status is required"),
      usVisaResidenceType: yup
        .string()
        .required("US Visa/Residence Type is required"),
      dateOfFirstArrivalInUSA: yup
        .date()
        .nullable()
        .required("Date of First Arrival in USA is required"),
      dateOfDivorce: yup.date().nullable(),
      dateOfDeath: yup.date().nullable(),
    }),
    dependents: yup.array().of(
      yup.object({
        firstName: yup.string().required("First Name is required"),
        middleName: yup.string().required("Middle Name is required"),
        lastName: yup.string().required("Last Name is required"),
        dateOfBirth: yup.date().required("Date of Birth is required"),
        relationship: yup.string().required("Relationship is required"),
        maritalStatus: yup
          .string()
          .oneOf(
            ["Single", "Married", "Divorced", "Widowed"],
            "Invalid Marital Status"
          )
          .required("Marital Status is required"),
        usVisaResidenceType: yup
          .string()
          .required("US Visa/Residence Type is required"),
        dateOfFirstArrivalInUSA: yup
          .date()
          .nullable()
          .required("Date of First Arrival in USA is required"),
        dateOfDivorce: yup.date().nullable(),
        dateOfDeath: yup.date().nullable(),
        currentAddress: yup.string().required("Current Address is required"),
        taxId: yup.string().required("TaxId is required"),
        email: yup.string().required("Email is required"),
        phoneNumber: yup.string().required("Phone Number is required"),
      })
    ),
  }),
  stimulusPayments: yup.object({
    thirdStimulusPayment: yup.boolean().required("This field is required"),
    childTaxCreditPayments: yup.boolean().required("This field is required"),
  }),
  stateResidency: yup.object({
    taxYear: yup.string().required("Tax Year is required"),
    statesResided: yup.array().of(
      yup.object({
        state: yup.string().required("State is required"),
        periodOfStay: yup.string().required("Period of Stay is required"),
      })
    ),
  }),
  bankDetails: yup.object({
    bankName: yup.string().required("Bank Name is required"),
    routingNumber: yup.string().required("Routing Number is required"),
    accountNumber: yup.string().required("Account Number is required"),
  }),
});
type MaritalStatus = "" | "Single" | "Married" | "Divorced" | "Widowed";

export default function TaxProfileForm() {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { fields: dependents, append } = useFieldArray({
    control: methods.control,
    name: "personalDetails.dependents",
  });

  const onSubmit = (data: any) => {
    console.log("Form Data", data);
    // Handle form submission
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            className="bg-blue-500 w-64 center text-white my-2 py-1"
            style={{ fontWeight: "bold", fontSize: "20px" }}
          >
            Tax Profile Details
          </h1>
        </div>
        <h3 style={{ fontWeight: "bold" }}>Primary</h3>
        <hr
          style={{ margin: "15px 0px", boxShadow: "0px 1px 1px 0px #4d4b4b" }}
        />

        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.firstName"
              label="First Name"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.middleName"
              label="Middle Name"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input name="personalDetails.primary.lastName" label="Last Name" />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.dateOfBirth"
              label="Date of Birth"
              type="date"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.relationship"
              label="Relationship"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.maritalStatus"
              label="Marital Status"
              type="select"
              options={[
                { value: "", label: "Select" },
                { value: "Single", label: "Single" },
                { value: "Married", label: "Married" },
                { value: "Divorced", label: "Divorced" },
                { value: "Widowed", label: "Widowed" },
              ]}
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.usVisaResidenceType"
              label="US Visa/Residence Type"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.dateOfFirstArrivalInUSA"
              label="Date of First Arrival in USA"
              type="date"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.dateOfDivorce"
              label="Date of Divorce"
              type="date"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.dateOfDeath"
              label="Date of Death"
              type="date"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.currentAddress"
              label="Current Address"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.taxId"
              label="Tax ID (SSN, ITIN)"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.email"
              label="Email"
              type="email"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.primary.phoneNumber"
              label="Phone Number"
              type="tel"
            />
          </div>
        </div>

        <h3 style={{ fontWeight: "bold" }}>Secondary (Spouse)</h3>
        <hr
          style={{ margin: "15px 0px", boxShadow: "0px 1px 1px 0px #4d4b4b" }}
        />

        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.firstName"
              label="First Name"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.middleName"
              label="Middle Name"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.lastName"
              label="Last Name"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.dateOfBirth"
              label="Date of Birth"
              type="date"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.relationship"
              label="Relationship"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.maritalStatus"
              label="Marital Status"
              type="select"
              options={[
                { value: "", label: "Select" },
                { value: "Single", label: "Single" },
                { value: "Married", label: "Married" },
                { value: "Divorced", label: "Divorced" },
                { value: "Widowed", label: "Widowed" },
              ]}
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.usVisaResidenceType"
              label="US Visa/Residence Type"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.dateOfFirstArrivalInUSA"
              label="Date of First Arrival in USA"
              type="date"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.dateOfDivorce"
              label="Date of Divorce"
              type="date"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.dateOfDeath"
              label="Date of Death"
              type="date"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.currentAddress"
              label="Current Address"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.taxId"
              label="Tax ID (SSN, ITIN)"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.email"
              label="Email"
              type="email"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="personalDetails.secondary.phoneNumber"
              label="Phone Number"
              type="tel"
            />
          </div>
        </div>

        <h3 style={{ fontWeight: "bold" }}>Dependents</h3>
        <hr
          style={{ margin: "15px 0px", boxShadow: "0px 1px 1px 0px #4d4b4b" }}
        />

        {dependents.map((dependent, index) => (
          <>
            <h1
              className="bg-blue-500 w-32 center text-white my-2 py-1"
              style={{ fontWeight: "bold" }}
            >
              Dependent {index + 1}
            </h1>
            <div
              key={dependent.id}
              style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
            >
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].firstName`}
                  label="First Name"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].middleName`}
                  label="Middle Name"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].lastName`}
                  label="Last Name"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].dateOfBirth`}
                  label="Date of Birth"
                  type="date"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].relationship`}
                  label="Relationship"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].maritalStatus`}
                  label="Marital Status"
                  type="select"
                  options={[
                    { value: "", label: "Select" },
                    { value: "Single", label: "Single" },
                    { value: "Married", label: "Married" },
                    { value: "Widowed", label: "Widowed" },
                  ]}
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].usVisaResidenceType`}
                  label="US Visa/Residence Type"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].dateOfFirstArrivalInUSA`}
                  label="Date of First Arrival in USA"
                  type="date"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].dateOfDivorce`}
                  label="Date of Divorce"
                  type="date"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].dateOfDeath`}
                  label="Date of Death"
                  type="date"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].currentAddress`}
                  label="Current Address"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].taxId`}
                  label="Tax ID (SSN, ITIN)"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].email`}
                  label="Email"
                  type="email"
                />
              </div>
              <div style={{ flex: "1", minWidth: "200px" }}>
                <Input
                  name={`personalDetails.dependents[${index}].phoneNumber`}
                  label="Phone Number"
                  type="tel"
                />
              </div>
            </div>
            <hr
              style={{
                margin: "15px 0px",
                boxShadow: "0px 1px 1px 0px #4d4b4b",
              }}
            />
          </>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "200px" }}>
            <Button
              type="button"
              onClick={() =>
                append({
                  firstName: "",
                  middleName: "",
                  lastName: "",
                  dateOfBirth: new Date(),
                  relationship: "",
                  maritalStatus: "Single",
                  usVisaResidenceType: "",
                  dateOfFirstArrivalInUSA: new Date(),
                  dateOfDivorce: null,
                  dateOfDeath: null,
                  currentAddress: "",
                  taxId: "",
                  email: "",
                  phoneNumber: "",
                })
              }
            >
              Add Dependent
            </Button>
          </div>
        </div>
        <h2 style={{ fontWeight: "bold" }}>
          Stimulus and Child Tax Credit Payment Information
        </h2>
        <hr
          style={{
            margin: "15px 0px",
            boxShadow: "0px 1px 1px 0px #4d4b4b",
          }}
        />
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <label htmlFor="stimulusPayments">
              Have you received 3rd Stimulus Payment?
            </label>
            <input
              id="stimulusPayments"
              name="stimulusPayments.thirdStimulusPayment"
              type="checkbox"
              style={{ marginLeft: "10px" }}
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <label htmlFor="childTaxCreditPayments">
              Have you received Child Tax Credit payments?
            </label>
            <input
              name="stimulusPayments.childTaxCreditPayments"
              id="childTaxCreditPayments"
              type="checkbox"
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>

        <h2 style={{ fontWeight: "bold" }}>
          State Residency Information for Tax Year
        </h2>
        <hr
          style={{
            margin: "15px 0px",
            boxShadow: "0px 1px 1px 0px #4d4b4b",
          }}
        />
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input name="stateResidency.taxYear" label="Tax Year" />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="stateResidency.taxpayerStatesResided"
              label="Taxpayer States Resided"
            />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input
              name="stateResidency.spouseStatesResided"
              label="Spouse States Resided"
            />
          </div>
        </div>

        <h2 style={{ fontWeight: "bold" }}>
          Bank Account Details for Direct Deposit/Withdrawal or Refund/Taxes Due
        </h2>
        <hr
          style={{
            margin: "15px 0px",
            boxShadow: "0px 1px 1px 0px #4d4b4b",
          }}
        />
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input name="bankDetails.bankName" label="Bank Name" />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input name="bankDetails.routingNumber" label="Routing Number" />
          </div>
          <div style={{ flex: "1", minWidth: "200px" }}>
            <Input name="bankDetails.accountNumber" label="Account Number" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "200px" }}>
            <Button type="submit">Submit</Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
