import React from "react";

export default function AccountingServices() {
  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-4 text-left">Accounting Services</h2>
      <p className="text-lg text-justify mb-4">
        Providing financial information to our clients in a timely and accurate manner is a commitment that we feel cannot be compromised. Meaningful, well-organized financial records ensure that your business operations will run more efficiently on a daily basis. Our firm provides a full range of cost-effective accounting services including the following.
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>General ledger & financial statement preparation</li>
        <li>Bookkeeping (Monthly/Quarterly/Annual)</li>
        <li>Accounting system setup for new businesses</li>
        <li>Personal financial statements</li>
        <li>Computerized payroll services</li>
        <li>Business tax return preparation (Sales & Use/Business Property)</li>
      </ul>
    </div>
  );
}
