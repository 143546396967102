// src/components/EmailTemplateDetails.tsx
import React from 'react';
export interface EmailTemplate {
    id: number;
    purpose: string;
    subject: string;
    message: string;
    message1: string;
    status: number;
  }
  
interface EmailTemplateDetailsProps {
  data: EmailTemplate;
}

const EmailTemplateDetails: React.FC<EmailTemplateDetailsProps> = ({ data }) => {
  return (
    <div className="p-4 space-y-2">
      <div>
        <strong>Purpose:</strong> {data.purpose}
      </div>
      <div>
        <strong>Subject:</strong> {data.subject}
      </div>
      <div>
        <strong>Message:</strong>
        <p className="mt-1 whitespace-pre-wrap">{data.message}</p>
      </div>
      <div>
        <strong>Additional Message:</strong>
        <p className="mt-1 whitespace-pre-wrap">{data.message1}</p>
      </div>
      <div>
        <strong>Status:</strong> {data.status ? 'Active' : 'Inactive'}
      </div>
    </div>
  );
};

export default EmailTemplateDetails;
