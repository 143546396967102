import React, { useEffect, useState } from "react";
import apiService from "services/apiService";


export default function Pricing() {
  const [pricingData, setPricingData] = useState([]);

  useEffect(() => {
    apiService
      .get("pricing")
      .then((res) => {
        console.log("Pricing data response: ", res);
        setPricingData(res.data); // Assuming res.data contains an array of pricing objects
      })
      .catch((err) => {
        console.error("Error fetching pricing data", err);
      });
  }, []);

  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-4 text-left">Pricing</h2>
      <p className="text-lg text-justify mb-4">
        Below is the pricing information for individual and business tax services. Our packages include various tax return options, schedules, and additional services. Please note that FREE Audit Defense is included with all prepared returns.
      </p>
      <table className="table-auto w-full text-left border-collapse mb-4">
        <thead>
          <tr className="bg-gray-100">
            <th className="border px-4 py-2">Invoice Particulars</th>
            <th className="border px-4 py-2">Individual</th>
            <th className="border px-4 py-2">Business</th>
          </tr>
        </thead>
        <tbody>
          {pricingData.map((item:any, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{item.features}</td>
              <td className="border px-4 py-2">{`$${item.individual}` || "N/A"}</td>
              <td className="border px-4 py-2">{`$${item.business}` || "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="text-sm text-gray-600">
        *Includes Federal Tax Return, One State Tax Return with Standard or Itemized Deduction. No additional fee for multiple W2's, 1099's
      </p>
      <p className="text-sm text-gray-600 mt-2">
        * FREE Audit Defense is included with all prepared returns
      </p>
    </div>
  );
}
