import React from "react";

export default function CorporateReturns() {
  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-4 text-left">Corporate Returns – 1120 & 1120 S</h2>
      <p className="text-lg font-semibold mb-2">Sole Proprietorship</p>
      <p className="text-justify mb-4">
        A sole proprietorship, also known as a sole trader, is owned by one person and operates for their benefit. A sole proprietor has unlimited liability for all obligations incurred by the business, whether from operating costs or judgments against the business. All assets of the business belong to a sole proprietor, including, for example, computer infrastructure, any inventory, manufacturing equipment and/or retail fixtures, as well as any real property owned by the business.
      </p>
      <p className="text-lg font-semibold mb-2">Partnership</p>
      <p className="text-justify mb-4">
        A partnership is a business owned by two or more people. In most forms of partnerships, each partner has unlimited liability for the debts incurred by the business. The three most prevalent types of for-profit partnerships are general partnerships, limited partnerships, and limited liability partnerships.
      </p>
      <p className="text-lg font-semibold mb-2">Corporation</p>
      <p className="text-justify mb-4">
        The owners of a corporation have limited liability and the business has a separate legal personality from its owners. A privately owned, for-profit corporation is owned by its shareholders, who elect a board of directors to direct the corporation and hire its managerial staff. A privately owned, for-profit corporation can be either privately held by a small group of individuals, or publicly held, with publicly traded shares listed on a stock exchange.
      </p>
      <p className="text-lg font-semibold mb-2">Cooperative</p>
      <p className="text-justify">
        Often referred to as a "co-op", a cooperative is a limited liability business that can organize for-profit or not-for-profit. A cooperative differs from a corporation in that it has members, not shareholders, and they share decision-making authority. Cooperatives are typically classified as either consumer cooperatives or worker cooperatives.
      </p>
    </div>
  );
}
