import React from "react";

export default function LLCService() {
  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-4 text-left">Limited Liability Company (LLC)</h2>
      <p className="text-lg font-semibold mb-2">Limited Liability Company (LLC)</p>
      <p className="text-justify">
        A Limited Liability Company (LLC) is a hybrid business entity having certain characteristics of both a corporation and a partnership or sole proprietorship (depending on how many owners there are). An LLC, although a business entity, is a type of unincorporated association and is not a corporation. The primary characteristic an LLC shares with a corporation is limited liability, and the primary characteristic it shares with a partnership is the availability of pass-through income taxation. It is often more flexible than a corporation, and it is well-suited for companies with a single owner. Their liability for the operations of the company is determined by their level of investment.
      </p>
    </div>
  );
}
