import React, { useState } from "react";
import axios from "axios";
import apiService from "services/apiService";
import { toast,ToastContainer } from "react-toastify";


type OtpPopupProps = {
  regId: any; // Adjust type as per your `reg_id`
  onClose: () => void;
  verifyOtp: (otpValid:boolean) => void;
};

export const OtpPopup: React.FC<OtpPopupProps> = ({ regId, onClose,verifyOtp }) => {
  const [otp, setOtp] = useState("");

  const handleSubmit = async () => {
    try {
      const otpResponse=  await apiService.post("/tax-profiles/verify-otp", { reg_id: regId, otp });
console.log('otpResponse ',otpResponse)
if(otpResponse.status===200){
  verifyOtp(true)
    }else {
      verifyOtp(false);
     
    }

    } catch (error: any) {     
      console.error("Error verifying OTP:", error);
      toast.error( "The OTP you entered is invalid. Please check your email for the correct OTP.");
    } 

  };

  return (
    <div className="otp-popup">
            <ToastContainer/>

        <p className="text-center text-gray-700 mb-4">
          OTP has been sent to your registered email ID. Please enter it below to verify.
        </p>
      <div className="flex justify-center mt-6 items-center">
      
        <h2 className="mr-3">Enter OTP</h2>
        <input
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          placeholder="Enter OTP"
          className="border border-gray-300 rounded px-2 py-1"
        />
      </div>
      <div className="flex justify-center mt-6">
        <button
          className="bg-green-500 text-white py-2 px-4 rounded mr-4"
          onClick={handleSubmit}
        >
          Verify OTP
        </button>
        <button
          className="bg-red-500 text-white py-2 px-4 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
