import React, { useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "components/Input";
import Select from "react-select";
import apiService from "services/apiService";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  operator_name: yup.string().required("Operator name is required"),
  operator_email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
  status: yup.string().required("Status is required"),
});

interface AddProps {
  onClose: () => void;
}

export default function AddOperator({ onClose }: AddProps) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const methods = useForm({ resolver: yupResolver(schema) });
  const navigate = useNavigate(); 


  const onSubmit: SubmitHandler<any> = async (data) => {
    console.log(data);

    try {
      const res = await apiService.post("/operators", data);
      console.log("Operator added:", res);
      onClose(); // Close modal or perform any action after success
    } catch (err:any) {
      if(err.response?.status===401){
        navigate("/session-expired");
      }
      setErrorMessage("Failed to add operator. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid gap-4 grid-cols-2">
          <Input
            name="operator_name"
            label="Operator Name"
            placeholder={`Enter Operator Name`}
          />
          <Input
            name="operator_email"
            label="Operator Email"
            placeholder={`Enter Operator Email`}
          />
          <Input
            name="username"
            label="Username"
            placeholder={`Enter Username`}
          />
          <Input
            name="password"
            label="Password"
            type="password"
            placeholder={`Enter Password`}
          />
          <Input
            name="image"
            label="Image URL"
            placeholder={`Enter Image URL`}
          />
          <div className="mb-4">
            <label>Status</label>
            <Select
              {...methods.register("status", { required: true })}
              options={statusOptions}
              placeholder="Select Status"
              className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
              onChange={(selectedOption) => {
                selectedOption &&
                  methods.setValue("status", selectedOption.value);
              }}
            />
            {methods.formState.errors.status && (
              <span className="text-red-500 text-sm mt-1">
                {methods.formState.errors.status.message}
              </span>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            disabled={loading}
            className="bg-blue-500 text-white px-8 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
          >
            {loading ? "Adding..." : "Submit"}
          </button>
          {errorMessage && <p>{errorMessage}</p>}
        </div>
      </form>
    </FormProvider>
  );
}
